import React, { useEffect, useState } from "react";
import "../assets/css/styles.css";
import Rectangle1564 from '../assets/images/Rectangle 1564.png';
import Rectangle1614 from "../assets/images/Rectangle 1614.png";
import FlowerArrows from "../assets/images/flowerArrows.png";
import TextBg from "../assets/images/textBg.png";
import RashiIcons from "../assets/images/rashiIcons/image.png";
import Image2 from "../assets/images/devotional/image2.png";
import sideGridRightImage from "../assets/images/sideGridRight.png";
import Image3 from "../assets/images/devotional/image3.png";
import Image4 from "../assets/images/devotional/image4.png";
import Image5 from "../assets/images/devotional/image5.png";
import Image6 from "../assets/images/devotional/image6.png";
import Telugu from "../assets/images/telugu 1.png";
import Vrushabha from "../assets/images/rashiIcons/vrushabha.png";
import Midhuna from "../assets/images/rashiIcons/midhuna.png";
import Karkata from "../assets/images/rashiIcons/karkata.png";
import Simha from "../assets/images/rashiIcons/simha.png";
import Kanya from "../assets/images/rashiIcons/kanya.png";
import Tula from "../assets/images/rashiIcons/tula.png";
import Vrushika from "../assets/images/rashiIcons/vrushika.png";
import Dhanushu from "../assets/images/rashiIcons/dhanushu.png";
import Makara from "../assets/images/rashiIcons/makara.png";
import Kumbha from "../assets/images/rashiIcons/kumbha.png";
import Meena from "../assets/images/rashiIcons/meena.png";
import Footer from "./footer";
import Header from "./header";
import Navbar from "./navbar";
import Right from "../assets/images/rightside.png";
import Left from "../assets/images/leftside.png";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RasiPhalaluData } from "../assets/jsons/rasiphalalu";
import Today_panchangam from "../Hooks/Today_panchangam";
import Pandagalu from "../Hooks/Pandagalu";

const getFormattedDate = (monthIndex, year) => {
  const months = [
    "జనవరి",
    "ఫిబ్రవరి",
    "మార్చి",
    "ఏప్రిల్",
    "మే",
    "జూన్",
    "జూలై",
    "ఆగస్టు",
    "సెప్టెంబర్",
    "అక్టోబర్",
    "నవంబర్",
    "డిసెంబర్",
  ];
  return `${months[monthIndex]} ${year}`;
};
const months = [
  "జనవరి",
  "ఫిబ్రవరి",
  "మార్చి",
  "ఏప్రిల్",
  "మే",
  "జూన్",
  "జూలై",
  "ఆగస్టు",
  "సెప్టెంబర్",
  "అక్టోబర్",
  "నవంబర్",
  "డిసెంబర్",
];

const Rasiphalalu = () => {
  const [currentMonthIndex, setCurrentMonthIndex] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(2024);
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(true);
  const [isIncrementDisabled, setIsIncrementDisabled] = useState(false);
  const [selectedRasi, setSelectedRasi] = useState(null);
  const [monthlyData, setMonthlyData] = useState([]);
  const [currentMonthData, setCurrentMonthData] = useState([]);
  const [tab, settab] = useState('');
  const incrementMonth = () => {
    setCurrentMonthIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex > 11) {
        setCurrentYear((prevYear) => prevYear + 1);
        return 0;
      }
      return newIndex;
    });
  };
 const location = useLocation();
 const [searchItem,setSearchItem] = useState(''); 
//  useEffect(()=>{
//   const params = new URLSearchParams(location.search);
//   const sort = params.get('sort');

  
//   if(sort){
//     setSearchItem(sort);
//   }
  

// },[location.search,searchItem]);

  const decrementMonth = () => {
    setCurrentMonthIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex < 0) {
        setCurrentYear((prevYear) => prevYear - 1);
        return 11;
      }
      return newIndex;
    });
  };

  useEffect(() => {
    // Disable or enable navigation buttons based on the current month and year
    setIsDecrementDisabled(currentMonthIndex === 0 && currentYear === 2024);
    setIsIncrementDisabled(currentMonthIndex === 11 && currentYear === 2025);
  }, [currentMonthIndex, currentYear]);

  useEffect(() => {
    if (RasiPhalaluData.RashiUpdated.length > 0) {
      // Get data for the selected month and year
      const monthData = RasiPhalaluData.RashiUpdated[0][currentMonthIndex];
      if (monthData) {
        setCurrentMonthData(monthData);
        const first_object = monthData[Object.keys(monthData)[0]];
        const def_title = first_object.Title;
        const params = new URLSearchParams(location.search);
        const sort = params.get('sort');
        if(sort){
          const matchedObject = Object.values(monthData).find(obj => obj.Title === sort);

          if (matchedObject) {
            settab(sort)
            setSelectedRasi(matchedObject);
          };
        }else{
          settab(def_title);
          setSelectedRasi(first_object);
        }
         

      } else {
        setCurrentMonthData({});
      }
    }
  }, [RasiPhalaluData, currentMonthIndex]);

  const handleClick = (rasi) => {
    setSelectedRasi(rasi);
  };

  const renderNestedObject = (data) => {
    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{renderNestedObject(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof data === "object" && data !== null) {
      return (
        <div>
          {Object.entries(data).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {renderNestedObject(value)}
            </div>
          ))}
        </div>
      );
    } else {
      return <span>{String(data)}</span>;
    }
  };

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: "#ffe6d0" }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: "repeat-y",
              transform: "rotateY(180deg)",
            }}
          ></div>
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3">
            <div className="row">
              <div className="col-lg-3 col-md-2" style={{ position: "relative", textAlign: "center" }}>
                <img
                  src={Left}
                  onClick={!isDecrementDisabled ? decrementMonth : null}
                  className={`img-fluid ${isDecrementDisabled ? "disabled" : ""}`}
                  style={
                    isDecrementDisabled
                      ? { opacity: 0.5, cursor: "not-allowed" }
                      : {}
                  }
                />
                <div
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "60%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Previous
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <h2 style={{ marginLeft: "200px", color: "#6B3300" }}>
                  {months[currentMonthIndex]} {currentYear}
                </h2>
              </div>
              <div className="col-lg-3 col-md-2" style={{ position: "relative", textAlign: "center" }}>
                <img
                  src={Right}
                  onClick={!isIncrementDisabled ? incrementMonth : null}
                  className={`img-fluid ${isIncrementDisabled ? "disabled" : ""
                    }`}
                  style={
                    isIncrementDisabled
                      ? { opacity: 0.5, cursor: "not-allowed" }
                      : {}
                  }
                />
                <div
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Next
                </div>
              </div>
              <div className="row gx-lg-5">

                {Object.keys(currentMonthData).length > 0 ? (

                  <div>
                    <section className="pt-4">
                      <br />

                      <div className="container px-lg-5">
                        <div className="row gx-lg-5">

                          {Object.keys(currentMonthData).map((rasiKey) => {
                            const rasi = currentMonthData[rasiKey];
                            return (
                              <div className="col-lg-2 col-xxl-2 mb-5" key={rasiKey}>
                                <div className="card bg-brown border-0 h-100" onClick={() => { handleClick(rasi); settab(rasi.Title) }}>
                                  <div className="text-center" style={{ backgroundColor: tab == rasi.Title ? "#B35C0C" : "", color: tab == rasi.Title ? "white" : "", borderRadius: tab == rasi.Title ? "8px" : "" }}>
                                    <div className="feature mt-n4">
                                      <img
                                        src={rasi.Path}
                                        alt={rasi.Title}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                      <br />
                                    </div>
                                    <p>{rasi.Title}</p>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                    <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12 p-3 center">
                      <div style={{ border: '2px solid #84470F', borderRadius: '15px 15px 0px 0px' }}>
                        <div style={{ position: 'relative' }} >
                          <img
                            src={Rectangle1614}
                            alt="Responsive image"
                            style={{ width: '100%' }}
                          />
                          <div style={{
                            position: 'absolute',
                            top: '45%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px'
                          }}>
                            <p className="Anek" style={{ fontSize: '50px' }}>{selectedRasi.Title}</p>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-3 center" style={{ marginLeft: "15px" }}>

                          <div style={{ position: 'relative' }} >
                            <img
                              src={Telugu}
                              className="img-fluids"
                              alt="Responsive image"
                            />
                            <div style={{
                              position: 'absolute',
                              top: '50%',
                              left: '30%',
                              transform: 'translate(-50%, -50%)',
                              color: '#FFFFFF',
                              textAlign: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '10px'
                            }}>
                              <img src={selectedRasi.Path} style={{ width: '20vw', height: '40vh' }} />
                            </div>
                          </div>
                        </div>
                        <div className="row gx-lg-5 Panchangam_details">
                          <div className="col-lg-3 col-xxl-2 mb-5">
                            <div className="rectangle" style={{ position: 'relative' }}>
                              <img className="rectangle" src={Rectangle1564} style={{ width: "150%", height: 'auto' }} />
                              <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#FFFFFF',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                                <h5 className="Pandagalu" style={{ fontFamily: "Anek Telugu", fontSize: "24px", color: '#FFFFFF', marginBottom: '3px', marginLeft: '50px' }}>ఆదాయం</h5>
                                <div style={{
                                  borderBottom: '2px solid #FF9E46',
                                  width: '150%',
                                  margin: '0 auto 10px auto',
                                  marginBottom: '10px',
                                }}></div>
                                <small className="Pandagalu" style={{ fontFamily: 'Arbutus', fontSize: '50px', position: 'relative', display: 'block', marginLeft: '50px' }}>{selectedRasi.Aadayama}</small>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-xxl-2 mb-5">
                            <div className="rectangle" style={{ position: 'relative' }}>
                              <img className="rectangle" src={Rectangle1564} style={{ width: "150%", height: 'auto' }} />
                              <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#FFFFFF',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                                <h5 className="Pandagalu" style={{ fontFamily: "Anek Telugu", fontSize: "24px", color: '#FFFFFF', marginBottom: '3px', marginLeft: '50px' }}>వ్యయం</h5>
                                <div style={{
                                  borderBottom: '2px solid #FF9E46',
                                  width: '150%',
                                  margin: '0 auto 10px auto',
                                  marginBottom: '10px',
                                }}></div>
                                <small className="Pandagalu" style={{ fontFamily: 'Arbutus', fontSize: '50px', position: 'relative', display: 'block', marginLeft: '50px' }}>{selectedRasi.Vyayam}</small>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-xxl-2 mb-5">
                            <div className="rectangle" style={{ position: 'relative' }}>
                              <img className="rectangle" src={Rectangle1564} style={{ width: "150%", height: 'auto' }} />
                              <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#FFFFFF',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                                <h5 className="Pandagalu" style={{ fontFamily: "Anek Telugu", fontSize: "24px", color: '#FFFFFF', marginBottom: '3px', marginLeft: '50px' }}>రాజపూజ్యం</h5>
                                <div style={{
                                  borderBottom: '2px solid #FF9E46',
                                  width: '150%',
                                  margin: '0 auto 10px auto',
                                  marginBottom: '10px',

                                }}></div>
                                <small className="Pandagalu" style={{ fontFamily: 'Arbutus', fontSize: '50px', position: 'relative', display: 'block', marginLeft: '50px' }}>{selectedRasi.Rajapujyam}</small>

                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-xxl-2 mb-5">
                            <div className="rectangle" style={{ position: 'relative' }}>
                              <img className="rectangle" src={Rectangle1564} style={{ width: "150%", height: 'auto' }} />
                              <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#FFFFFF',
                                textAlign: 'center',
                                width: '100%',
                              }}>
                                <h5 className="Pandagalu" style={{ fontFamily: "Anek Telugu", fontSize: "24px", color: '#FFFFFF', marginBottom: '3px', marginLeft: '50px' }}>అవమానం</h5>
                                <div style={{
                                  borderBottom: '2px solid #FF9E46',
                                  width: '150%',
                                  margin: '0 auto 10px auto',
                                  marginBottom: '10px',
                                }}></div>
                                <small className="Pandagalu" style={{ fontFamily: 'Arbutus', fontSize: '50px', position: 'relative', display: 'block', marginLeft: '50px' }}>{selectedRasi.Avamanam}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-4 center">
                          <div className="row align-items-center contain">
                            <div className="col-4 labels Anek">సంవత్సరం</div>
                            <div className="col-8 bar Anek" >{selectedRasi.Samvatsaram}</div>
                          </div>
                          <br />
                          <div className="row align-items-center contain">
                            <div className="col-4 labels Anek">రాశి తేదీలు</div>
                            <div className="col-8 bar">{selectedRasi.RasiDates}</div>
                          </div>
                          <br />
                          <div className="row align-items-center contain">
                            <div className="col-4 labels Anek">నక్షత్రాలు</div>
                            <div className="col-8 bar" >{selectedRasi.Nakshatra}</div>
                          </div>
                          <br />
                          <div className="row align-items-center contain">
                            <div className="col-4 labels Anek">మంచి సంఖ్య</div>
                            <div className="col-8 bar" >{selectedRasi.Fanum}</div>
                          </div>
                          <br />
                          <div className="row align-items-center contain">
                            <div className="col-4 labels Anek">మంచి రంగు</div>
                            <div className="col-8 bar" >{selectedRasi.Facolor}</div>
                          </div>
                          <br />
                          {/* <div className="row align-items-center contain">
                            <div className="col-4 labels Anek">రాశి గురించీ</div>
                            <div className="col-8 bar" >{selectedRasi.RasiDates}</div>
                          </div>
                          <br /> */}

                        </div>
                        {selectedRasi && (
                          <div style={{ marginLeft: "30px" }}>
                            <p className="Anek" style={{ fontSize: '22px' }}>{selectedRasi.Para}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>Loading data...</p>
                )}
              </div>
              <Today_panchangam />
              <Pandagalu />
            </div>
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: "repeat-y",
            }}
          ></div>
        </div>
      </div>
      <Footer />

    </div>
  );
};

export default Rasiphalalu;
