const krishnajananam = [
  {
    title: "విష్ణుమూర్తి దశ అవతారాలలో ఎనిమిదవ అవతారము శ్రీకృష్ణ అవతారము."
  },
  {
    title: "శ్రీకృష్ణుడున్ని చిలిపి బాలునిగాను, పశువులకాపరిగాను, రాధా గోపికా మనోహరునిగాను, గోపికల మనసు దొచుకున్నవాడిగాను, యాదవరాజుగాను, భగవద్గీతా ప్రబోధకునిగాను, తత్త్వోపదేశకునిగాను, దేవదేవునిగాను ఇలా బహువిధాలుగా శ్రీకృష్ణుని రూపం, వ్యక్తిత్వం, దైవత్వం చిత్రీకరింపబడినాయి."
  },
  {
    title: "మహాభారతం, హరివంశం, భాగవతం, విష్ణుపురాణం ఈ గ్రంథాలు కృష్ణుని జీవితాన్ని, తత్త్వాన్ని తెలుసుకోవడానికి హిందువులకు ముఖ్యమైన ధార్మిక గ్రంథాలు."
  },
  {
    title: ""
  },
  {
    title: "హిందూమతంలో ప్రత్యేకించి వైష్ణవులలో అతి ముఖ్యమైన పూజ కృష్ణునిపూజ."
  },
  {
    title: "మథురలో బాలకృష్ణునిగా, పూరీలో జగన్నాథునిగా, మహారాష్ట్రలో విఠోబాగా, రాజస్థాన్‌లో శ్రీనాధ్‌జీగా, తిరుమలలో వేంకటేశ్వరునిగా, ఉడిపిలో కృష్ణునిగా, గురువాయూరులో గురువాయూరప్ప గా కృష్ణున్ని పూజిస్తారు."
  },
  {
    title: "విష్ణుమూర్తి ఆలయాలన్ని కృష్ణుని ఆలయాలే అనవచ్చును. ఇందుకు అనుగుణంగా దేశంలో వివిధ ప్రాంతాలలోను, వర్గాలలోను అనేక సంప్రదాయాలు నెలకొన్నాయి."
  },
  {
    title: ""
  },
  {
    title: "శ్రీమహా విష్ణువు తన సృష్టిలోని జీవులకు బాధలు పెరిగినప్పుడు, పాపాలు హద్దు మీరినప్పుడు, దుష్టులను శిక్షించి, శిష్టులను రక్షించడం కోసం జీవుల రూపంలో అవతరించి దుష్ట శిక్షణ, శిష్ట రక్షణ చేస్తూ ఉంటాడు. ఈవిధంగా అవతరించడాన్ని లీలావతారం అంటారు."
  },
  {
    title: "ఇలాంటి లీలావతారాలు భాగవతం ప్రకారం భగవంతునికి ఇరువది రెండు ఉన్నాయి. శ్రీమహావిష్ణువు లీలావతారాలలో ఇరువైవ అవతారం శ్రీకృష్ణావతారం. "
  },
  {
    title: "ఈ లీలావతారాలు ఇరవైరెండింటి లోనూ ముఖ్యమైనవి పది ఉన్నాయి. ఈ పదింటిని దశావతారాలు అంటారు. దశావతారాలలో శ్రీకృష్ణావతారం కొన్నిచోట్ల చెప్పబడుతుంది."
  },
  {
    title: "యుగాలలో రెండవదయిన త్రేతాయుగంలో శ్రీరాముని లోక కళ్యాణ కారకునిగ రావణాది రాక్షస శిక్షకుడిగ కీర్తించబడుతున్నాడు. నారాయణుడు ఆ తర్వాత ద్వాపర యుగంలో శ్రీకృష్ణుడిగా అవతరించాడు. "
  },
  {
    title: "శ్రీకృష్ణుడు నారాయణుడి అవతారాల్లో పరిపూర్ణావతారంగ కొలవబడుతున్నాడు."
  },
  {
    title: "గీతోపదేశం ద్వారా అర్జునుడికి సత్యదర్శనం చేసి, కురుక్షేత్ర మహాసంగ్రామాన్ని ముందుకు నడిపించాడు. ఆ విధంగా భగవద్గీతను లోకానికి ఉపదేశించి శ్రీకృష్ణుడు జగద్గురువు అయ్యాడు."
  },
  {
    title: ""
  },
  {
    title: "@శ్రీకృష్ణుని నామాలు, రూపం :"
  },
  {
    title: "కృష్ణ అనగా నలుపు అని అర్ధం. కృష్ణుడు నల్లని రంగు కలవాడని ఐతిహ్యం. ఇంకా ఈ పేరుకు అనేక వివరణలున్నాయి. మహాభారతం ఉద్యోగపర్వం ప్రకారం 'కృష్' అనగా దున్నుట (నాగలి మొన నల్లగా ఉంటుంది గనుక ఈ పేరు వచ్చింది). భూమిని దున్ని సస్యశ్యామలం చేసేవాడు కృష్ణుడు (వ్యవసాయానికి ప్రాముఖ్యతను తెలిపే పేరు)."
  },
  {
    title: ""
  },
  {
    title: "వల్లభ సాంప్రదాయం బ్రహ్మసంబంధ మంత్రం ప్రకారం పాపాలను నాశనం చేసే మంత్రం కృష్ణ మంత్రం. చైతన్య చరితామృతంలో చెప్పిన అర్థం ప్రకారం మహాభారత వాక్యం ఆకర్షించేవాడు కృష్ణుడు."
  },
  {
    title: "భాగవతం ఆత్మారామ శ్లోకంలో కూడా ఈ భావం చెప్పబడింది. విష్ణుసహస్రనామంలో కృష్ణ అనగా సచ్చిదానంద స్వరూపమును సూచించునది అని ఆదిశంకరాచార్యుడు వివరించాడు. "
  },
  {
    title: "ఇంకా కృష్ణునికి గోవిందుడు, గోపాలుడు, వాసుదేవుడు వంటి అనేక నామాలున్నాయి. జగన్నాథుడు, విఠోబా వంటి పేర్లు కొన్ని ప్రాంతాలలో లేదా సంప్రదాయాలలో ప్రాచుర్యాన్ని కలిగి ఉన్నాయి. "
  },
  {
    title: "విష్ణు సహస్రనామ స్తోత్రంలో కృష్ణ అనే నామం రెండు సార్లు వస్తుంది. అగ్రాహ్యః శాశ్వతః కృష్ణో లోహితాక్షః ప్రతర్దనః, వేధాః స్వాంగో జితః కృష్ణో దృఢః సంకర్షణోచ్యుతః. ఈ రెండు సందర్భాలలోను వివిధ వ్యాఖ్యానకర్తలు వివిధ భావాలను తెలిపారు. అవి క్లుప్తంగా క్రింద చెప్పబడినవి."
  },
  {
    title: ""
  },
  {
    title: "సృష్ట్యాది లీలావిలాసముల ద్వారా సచ్చిదానంద క్రీడలో వినోదించువాడు."
  },
  {
    title: "నల్లని వర్ణము కలవాడు. నీలమేఘ శ్యాముడు."
  },
  {
    title: "తన అనంత కళ్యాణ గుణములతో భక్తులను ఆకర్షించువాడు."
  },
  {
    title: "నాగలివలె భూమిని దున్ని జీవ సమృద్ధి కలిగించువాడు."
  },
  {
    title: "కృష్ణద్వైపాయనుడైన వేదవ్యాసుడు."
  },
  {
    title: "కానరాని, తెలియరాని, అందజాలనివాడు. "
  },
  {
    title: "సంపూర్ణ భక్తికి మాత్రమే లభ్యమయ్యేవాడు."
  },
  {
    title: ""
  },
  {
    title: "కృష్ణాష్టకం అనే ప్రార్థనలో కృష్ణుని వర్ణించిన విధానం దేవకీ వసుదేవుల నందనుడు, కంసచాణూర మర్దనుడు, నల్లని మేనికాంతి కలవాడు, నెమలి పింఛము, వివిధ ఆభరణములు, మందారమాల, పీతాంబరములు, తులసి మాలలు ధరించినవాడు, మెలిదిరిగిన ముంగురులు కలవాడు, రుక్మిణీసత్యభామాది భామలతో విహరించువాడు, గోపికల కుచముల కుంకుమ అంటిన వక్షస్థలము, శ్రీవత్స చిహ్నము కలవాడు, వనమాల, శంఖచక్రములు ధరించినవాడు."
  },
  {
    title: ""
  },
  {
    title: "@పోతన చెప్పిన కొన్ని వర్ణనలు :"
  },
  {
    title: "నల్లనివాడు, పద్మ నయనమ్ములవాడు, నవ్వు రాజిల్లెడు మోమువాడు, మౌళి పరిసర్పిత పింఛమువాడు, సుధారసమ్ము పైజల్లెడువాడు, యదుభూషణుడు, శృంగార రత్నాకరుడు, లోక ద్రోహి నరేంద్ర వంశదాహకుడు, లోకేశ్వరుడు, నిర్వాణ సంధాయకుడు, భక్తవత్సలుడు, బ్రాహ్మణ్యుండు, గోవిందుడు,  పాండవులకు సఖుడు, సారథి, సచివుడు, నెయ్యము, వియ్యము, విభుడు, గురువు, దేవుడు ఇలా లెక్కలేనన్ని వర్ణనలున్నాయి."
  },
  {
    title: ""
  },
  {
    title: "@అన్నమయ్య చెప్పిన కొన్ని వర్ణనలు :"
  },
  {
    title: "ముద్దుగారే యశోద ముంగిట ముత్యము, కాళింది పడగలపైని కప్పిన పుష్యరాగము, రతికేళి రుక్మిణికి రంగుమోవి పగడము"
  },
  {
    title: ""
  },
  {
    title: "@జీవితం :"
  },
  {
    title: "వివిధ గ్రంథాలలో శ్రీకృష్ణుని జీవిత వృత్తాంతం ఉంది. వాటిలో భాగవతంలో ఉన్న కథాక్రమం ప్రజలకు సుపరిచితమైనది. నవమ స్కంధములో వసుదేవుని వంశక్రమం ఉంది. దశమ స్కంధము, ఏకాదశ స్కంధములలో కృష్ణుని జీవిత వృత్తాంతము ఉంది. సంభాషణలో శ్రీకృష్ణుని లాక్షాగృహదహనానంతరం కృష పరవేశం అవుతుంది. అక్కడినుండి కురుక్షేత్ర సంగ్రామం చివరి వరకు కృష్ణుని కథ పాండవుల కథకు సమాంతరంగా నడుస్తుంది. మహాభారతం చివరిలో కృష్ణుని నిర్యాణం ఉంది. వీటిలోనుండి సంగ్రహింపబడిన కృష్ణుని కథ క్రింద ఇవ్వబడింది."
  },
  {
    title: ""
  },
  {
    title: "@శ్రీ కృష్ణుని జననం :"
  },
  {
    title: "లోకంలో అధర్మం ప్రబలినందున భూదేవి, బ్రహ్మదేవుల ప్రార్థన మేరకు భగవంతుడు దేవకీ వసుదేవులకు జన్మింపదలిచాడు."
  },
  {
    title: ""
  },
  {
    title: "మధురా నగరాన్ని యాదవ క్షత్రియ వంశంకి చెందిన శూరసేన మహారాజు పరిపాలిస్తుండేవాడు. ఆయనకు వసుదేవుడు అనే కుమారుడు ఉండేవాడు. వసుదేవునికి ఉగ్రసేన మహారాజు కుమార్తె దేవకిని ఇచ్చి వివాహం చేస్తారు."
  },
  {
    title: ""
  },
  {
    title: "చెల్లెలు అంటే ఎంతో ప్రేమ కల కంసుడు ఆమెను అత్తవారి ఇంటికి రథం మీద సాగనంపుతుంటే అశరీరవాణి దేవకి గర్భంలో పుట్టిన ఎనిమిదో కుమారుడు కంసుడిని సంహరిస్తాడు అని చెబుతుంది. కంసుడు దేవకిని, వసుదేవుడిని, ఆడ్డువచ్చిన తన తండ్రి ఉగ్రసేన మహారాజును కూడా చెరసాలలో పెడతాడు."
  },
  {
    title: ""
  },
  {
    title: "దేవకీ దేవి ఏడవ మారు గర్భం ధరించినప్పుడు విష్ణువు తన మాయతో ఆమె గర్భాన్ని నందనవనంలో నందుడి భార్య రోహిణి గర్భంలో ప్రవేశ పెడతాడు. ఈ గర్భం వల్ల రోహిణికి బలరాముడు జన్మిస్తాడు. చెరసాలలో దేవకికి గర్భ స్రావం అయిందని అనుకొంటారు. "
  },
  {
    title: ""
  },
  {
    title: "కొన్ని రోజులకు దేవకీ దేవి ఎనిమిదో మారు గర్భం ధరిస్తుంది. లక్ష్మీనాథుడు దేవకి గర్భములో ఉండడం చూసి దేవతలు, యక్ష, కిన్నర, కింపురుషులు దేవకీ దేవి ఉన్న చెరసాలకు వచ్చి స్తుతిస్తారు."
  },
  {
    title: ""
  },
  {
    title: "దేవకి గర్భం నుండి శ్రావణ శుద్ధ అష్టమి తిథి నాడు విష్ణువు శ్రీకృష్ణుడై రోహిణీ నక్షత్ర యుక్తమైనప్పుడు జన్మిస్తాడు. కృష్ణుడు జన్మించాక వసుదేవుడు కృష్ణుడిని పొత్తిళ్ళలో పెట్టుకొని చెరసాల బయట నిద్రపోతూ ఉన్న కావలి వాళ్ళను తప్పించుకొని యమునా నది వైపు బయలు దేరుతాడు. "
  },
  {
    title: ""
  },
  {
    title: "యమునానది రెండుగా చీలి పోతుంది. నందనవనంలో తన స్నేహితుడైన నందుని ఇంటికి వెళ్ళి యశోద ప్రక్కన ఉన్న శిశువు ప్రదేశంలో శ్రీకృష్ణుడిని విడిచి ఆ శిశువును తీసుకొని తిరిగి చెరసాలకు వస్తాడు. చెరసాలకు చేరిన వెంటనే ఆ శిశువు ఏడుస్తుంది. "
  },
  {
    title: ""
  },
  {
    title: "కంసుడు ఆ శిశువును తీసుకొని చంపడానికి పైకి విసరగా ఆ శిశువు ఎనిమిది చేతులతో శంఖ చక్ర గదా శారంగాలతో ఆకాశంలోకి లేచి పోయి తాను యోగ మాయ నని కంసుడిని చంపేవాడు వేరే చోట పెరుగుతున్నాడని చెప్పి మాయం అవుతుంది. దేవకీ వసుదేవులకు అష్టమ సంతానంగా కంసుని చెరలో జన్మించిన శ్రీకృష్ణుడు వ్రేపల్లె లోని యశోదాదేవి ఒడిని చేరి అక్కడే పెరిగాడు."
  },
  {
    title: ""
  },
  {
    title: "@వ్రేపల్లెలో :"
  },
  {
    title: "మధురా నగరంలో కంసుని చెరసాలలో జన్మించిన కృష్ణుడు పుట్టగానే తన తండ్రి వసుదేవునిచే వ్రేపల్లెలోని నందుని ఇంట చేరి యశోదాదేవి ముద్దు బిడ్డగా బాల్య జీవితము గడిపాడు. పాలుత్రాగే ప్రాయంలో తనను చంపటానికి కంసునిచే పంపబడిన పూతనను బుడిబుడి నడకల ప్రాయంలో శకటాసురాదులను సంహరించాడు."
  },
  {
    title: ""
  },
  {
    title: "చిరు ప్రాయంలో యశోదకు తన నోటిలో అండ పిండ బ్రహ్మాండాదులను చూపి యశోదను ఆనందాశ్చర్యచకితురాలిని చేశాడు. దోగాడే వయసులో యశోదచే నడుముకి కట్టబడిన రోలుతో రెండు మద్ది చెట్లను కూల్చి మద్దిచెట్ల రూపంలో ఉన్న గంధర్వులకు శాపవిమోచనం గావించాడు."
  },
  {
    title: ""
  },
  {
    title: "అన్న బలరామునితో చేరి స్నేహితులతో గోపాలుడయ్యాడు. వేణుగానంలో అసాధారణ ప్రజ్ఞ చూపించి ఆబాలగోపాలాన్ని మంత్రముగ్ధులను గావించాడు. కాళిందీనదిలో ఉన్న కాళీయుడి తలపై నృత్యముచేసి 'తాండవకృష్ణుడు' అయ్యాడు. "
  },
  {
    title: ""
  },
  {
    title: "కాళీయుని మదమణచి, కాళిందిని విడిచి దూరంగా పంపి వ్రేపల్లె వాసుల మన్ననలను పొందాడు. ప్రళయకాలంలో గోవర్ధన గిరిని తన చిటికెన వేలుతో ఎత్తి వ్రేపల్లె వాసులను ఆ గిరి కిందకు చేర్చి కాపాడి వ్రేపల్లె వాసుల మనసుల్లో భగవంతుడి స్థాయికి ఎదిగాడు. అల్లరి పనులతో అలరించి, ఆపత్కాలంలో ఆదుకుని, ధైర్యసాహసాల ప్రదర్శనతో వ్రేపల్లెను మురిపించాడు."
  },
  {
    title: ""
  },
  {
    title: "కృష్ణుని చంపడానికి కంసుడు ఒక వ్యూహం పన్ని, ఉద్ధవుని దూతగా పంపి, కృష్ణబలరాములను మధురకు రప్పించాడు. బలరామకృష్ణులు చాణూర ముష్టికులనే మల్లులను, తరువాత కంసుని వధించి తమ తాత ఉగ్రసేనుని చెర విడిపించి అతనిని రాజ్యాభిషిక్తుని గావించారు. చెరలో ఉన్న తల్లి, తండ్రులను వారితో పాటుగా విడిపించి ద్వారకకు చేరుకున్నారు."
  },
  {
    title: ""
  },
  {
    title: "దేవకీ వసుదేవుల కోరికపై విద్యాభ్యాసానికి సాందీపని ముని ఆశ్రమము చేరుకొని అక్కడ కుచేలుని చెలిమిని పొందారు. గురుదక్షిణగా అంతకు పూర్వమే మరణించిన గురుపుత్రుని బ్రతికించి తెచ్చి గురువుకి సమర్పించారు. విద్యాధనంతో తన తల్లి తండ్రుల వద్దకు చేరుకున్నారు."
  },
  {
    title: ""
  },
  {
    title: "@శ్రీ కృష్ణుని కుటుంబము :"
  },
  {
    title: "దేవకీ వసుదేవులు కృష్ణుని తల్లిదండ్రులు. అన్న బలరాముడు. చెల్లి సుభద్ర. కాని బాల్యంలో కృష్ణబలరాములు యశోదా నందుల వద్ద వ్రేపల్లెలో పెరిగారు. కృష్ణుని తమ్ముడు సాత్యకి."
  },
  {
    title: "ముఖ్య వ్యాసము: అష్టమహిషులు"
  },
  {
    title: "ముఖ్య వ్యాసము: కృష్ణసంతానం"
  },
  {
    title: "శ్రీకృష్ణుడు అష్టభార్యలను వివాహమాడాడు. విదర్భ రాజైన భీష్మకుని పుత్రిక రుక్మిణి కృష్ణుని ప్రేమించింది. కానీ ఆమె సోదరుడు రుక్మిణి అతడిని ద్వేషించి ఆమెను శిశుపాలునికిచ్చి పెళ్ళి చేయాలని నిశ్చయించాడు. "
  },
  {
    title: "రుక్మిణి పంపిన రహస్య సందేశం గ్రహించి కృష్ణుడు ఆమె అభీష్టం మేరకు రాక్షస పద్ధతిలో అపహరించి వివాహం చేసుకుంటాడు. సత్రాజిత్తు కుమార్తె సత్యభామ. కృష్ణుడు శమంతకమణిని తనకిమ్మని కోరగా అతడు అంగీకరించలేదు. ఒకసారి సత్రాజిత్తు తమ్ముడు ప్రసేనుడు ఆ మణిని ధరించి వేటకు వెళ్ళాడు. "
  },
  {
    title: "అక్కడ ఒక సింహము అతనిని చంపి, మణిని హరించింది. జాంబవంతుడు ఆ సింహమును చంపి మణిని తన కుమార్తె జాంబవతి కిచ్చాడు. మణి కొరకై ప్రసేనుడిని కృష్ణుడే హతమార్చెనన్న అపవాదు వ్యాపించింది. "
  },
  {
    title: "కృష్ణుడు మణిని అన్వేషిస్తు పోయి పోయి జాంబవంతుని గుహలో ఉన్న మణిని తీసుకున్నాడు. జాంబవంతునికీ, కృష్ణునికీ జరిగిన యుద్ధంలో జాంబవంతుడు పరాజితుడైనాడు. శ్రీకృష్ణుని శ్రీరాముని అవతారంగా గుర్తించిన జాంబవంతుడు మణితో సహా కూతురు జాంబవతిని అతనికి సమర్పించాడు. మణిని తెచ్చి సత్రాజిత్తునకిచ్చాడు. అప్పుడు సత్రాజిత్తు మణితో పాటు తన కుమార్తె సత్యభామను కృష్ణునికిచ్చి వివాహం చేసెను."
  },
  {
    title: "కాళింది, భధ్ర, నాగ్నజితి, మిత్రవింద, లక్షణ కృష్ణుని ఇతర భార్యలు. భద్ర శ్రీకృష్ణుని తండ్రియగు వసుదేవుని చెల్లెలైన శ్రుతకీర్తి పుత్రిక. మిత్రవింద కూడా అవంతీ రాజు పుత్రిక, మేనత్త కూతురు. ఆమెను స్వయంవరంలో వరించి కృష్ణుడు చేపట్టాడు. కోసల దేశాధిపతి నగ్నజిత్తుకు ఏనుగుల వంటి బలం కలిగిన ఏడు వృషభాలు ఉండేవి. "
  },
  {
    title: "వాటిని నిగ్రహించిన వానికి తన కుమార్తె నాగ్నజితిని ఇచ్చి వివాహం చేస్తానని ప్రకటించాడు. కృష్ణుడు ఏడు రూపాలు దాల్చి ఏడు ఎద్దులను బంధించాడు. రాజు పుత్రికనిచ్చి పరిణయం చేశాడు. లక్షణ మద్ర దేశాధిపతి కూతురు. స్వయంవరంలో శ్రీకృష్ణుని వరించింది. ఈ విధంగా కృష్ణుని భార్యలు అష్టమహిషులుగా విలసిల్లారు."
  },
  {
    title: "సుభద్రను అర్జునునికి ఇచ్చి పెళ్ళి చేశారు. వారి కొడుకు కృష్ణునికి మేనల్లుడు అభిమన్యుడు."
  },
  {
    title: "శ్రీకృష్ణుడికి భార్యలందరితోనూ ఒక్కొక్కరి వల్ల పదేసి మంది పిల్లలు పుట్టారు."
  },
  {
    title: "రుక్మిణి వల్ల కృష్ణుడికి ప్రద్యుమ్నుడు, చారుదేష్ణుడు, సుదేష్ణుడు, చారుదేహుడు, సుబారుడు, చారుగుప్తుడు, భద్రచారుడు, చారుచంద్రుడు, విచారుడు, చారుడు అనే బిడ్డలు కలిగారు."
  },
  {
    title: "సత్యభామ వల్ల కృష్ణునికి భానుడు, సుభానుడు, స్వర్భానుడు, ప్రభానుడు, భానుమంతుడు, చంద్రభానుడు, బృహద్భానుడు, అతిభానుడు, శ్రీభానుడు, ప్రతిభానుడు అనే బిడ్డలు కలిగారు."
  },
  {
    title: "జాంబవతీ శ్రీకృష్ణులకు సాంబుడు, సుమిత్రుడు, పురజిత్తు, శతజిత్తు, సహస్రజిత్తు, విజయుడు, చిత్రకేతుడు, వసుమంతుడు, ద్రవిడుడు, క్రతువు అనే సంతానం కలిగింది. జాంబవతికి కలిగిన ఈ బిడ్డలంటే కృష్ణుడికి ప్రత్యేకమైన ప్రేమ ఉండేది."
  },
  {
    title: "నాగ్నజితి, కృష్ణులకు వీరుడు, చంద్రుడు, అశ్వసేనుడు, చిత్రగుడు, వేగవంతుడు, వృషుడు, లముడు, శంకుడు, వసుడు, కుంతి అనే పిల్లలు కలిగారు. "
  },
  {
    title: "కృష్ణుడికి కాళింది వల్ల శ్రుతుడు, కవి, వృషుడు, వీరుడు, సుబాహుడు, భద్రుడు, శాంతి, దర్శుడు, పూర్ణమానుడు, శోమకుడు అనే కుమారులు జన్మించారు."
  },
  {
    title: "లక్షణకు, శ్రీకృష్ణుడికి ప్రఘోషుడు, గాత్రవంతుడు, సింహుడు, బలుడు, ప్రబలుడు, ఊర్ధ్వగుడు, మహాశక్తి, సహుడు, ఓజుడు, అపరాజితుడు అనే సంతానం కలిగింది."
  },
  {
    title: "మిత్రవింద, కృష్ణులకు వృకుడు, హర్షుడు, అనిలుడు, గృద్ధుడు, వర్ధనుడు, అన్నాదుడు, మహాశుడు, పావనుడు, వహ్ని, క్షుధి అనే పుత్రులు పుట్టారు. "
  },
  {
    title: "కృష్ణుడికి భద్ర అనే భార్య వల్ల సంగ్రామజిత్తు, బృహత్సేనుడు, శూరుడు, ప్రహరణుడు, అరిజిత్తు, జయుడు, సుభద్రుడు, వాముడు, ఆయువు, సత్యకుడు అనే పిల్లలు పుట్టారు. "
  },
  {
    title: "చాలామంది అపోహపడుతున్నట్టుగా శ్రీకృష్ణుడికి 16వేలమంది భార్యలతో శారీరక బంధము కలిగియుండలేదు. 16వేల గోపికా స్త్రీలను నరకాసురుని బారి నుండి కాపాడి సంఘములో సముచిత స్థానము కల్పించాడు. భర్త అనగా భరించువాడు అను నానుడి ప్రకారము, ఒక పురుషుని పంచన చేరి, అతని నివాసమునందు నివసించు స్త్రీలకు అతడే భర్తగా నిర్ణయించే అప్పటి కాలమాన స్థితిగతులను బట్టి శ్రీకృష్ణునికి భార్యలుగా చెప్పబడ్డారు."
  },
  {
    title: "కానీ పైన చెప్పబడిన అష్ఠ అష్టమహిషులతోనే శ్రీకృష్ణునికి సంతానము కలిగినది అని గ్రంథాలు ఉద్ఘాటిస్తున్నాయి."
  },
  {
    title: "శ్రీకృష్ణుని అతిశయాన్ని చూసి ఓర్వలేక కాలయవనుడు, జరాసంధుడు, సాళ్వుడు వంటివారు కృష్ణునిపై దండెత్తారు. శ్రీకృష్ణుడు వారిని ఓడించాడు. ఇంకా ద్వివిధుడు, దంతవక్త్రుడు మొదలైనవారు కూడా కృష్ణుని చేత హతులైనారు."
  },
  {
    title: ""
  },
  {
    title: "@మహాభారతంలో శ్రీ కృష్ణుని పాత్ర :"
  },
  {
    title: "మేనత్త కుమారులైన పాండుసుతులతో శ్రీ కృష్ణుని అనుబంధం మరువరానిది. పాండవ మద్యముడైన అర్జునునితో చెలిమి విడదీయరానిది. పాండవుల జీవితములో జరిగిన ప్రతి సంఘటనలో శ్రీకృష్ణుని పాత్ర ఉంది."
  },
  {
    title: "శ్రీకృష్ణుని సంప్రదించకుండా ధర్మరాజు శకునితో ఆడిన జూదము తప్పమిగిలినవన్నీ శ్రీకృష్ణుని సలహా సంప్రదింపులతో జరిగినవే. కీలకమైన సమస్యలన్నీ కృష్ణుని సహాయంతో తీరినవే. ద్రౌపదిని శ్రీకృష్ణుడు స్వంత చెల్లెలి కన్నా మిన్నగా చూసుకున్నాడు. వస్ర్తాపహరణ అవమానము నుండి ఆమె శ్రీకృష్ణుని సహాయంతోనే బయటపడింది."
  },
  {
    title: "పాండవ వనవాస సమయంలో వారికి వచ్చిన అనేక సమస్యలకు శ్రీకృష్ణుని సలహాతో పరిష్కారం చేసుకున్నారు. వారి రాజ్యం మీదకు అనేక మార్లు దండెత్తిన జరాసంధుని భీముని సాయంతో తుదముట్టించి తన రాజ్యానికి శత్రు భయాన్ని తొలగించాడు."
  },
  {
    title: "ద్వారక సముద్రగర్భంలో మునిగిపోతుందని ముందుగానే ఊహించి ద్వారక వాసులను అప్రమత్తంచేసి వారిని ఆపదనుండి రక్షించాడు. ఇంద్రప్రస్థంలో ధర్మరాజు చేసిన అశ్వమేధయాగ సమయంలో మేనత్తకి ఇచ్చిన మాటను పాలించి శిశుపాలుని నూరు తప్పులను సహించిన తరువాత అతనిని చక్రాయుధంతో వధించాడు."
  },
  {
    title: "పాండవుల వనవాసం తరువాత వారి తరుపున కురుసభలో రాయబారము చేశాడు. కురుక్షేత్రంలో యుద్ధసమయంలో అర్జునునికి గీతాభోధ చేసి అతనిని యుద్ధోన్ముఖుని చేశాడు. అర్జునునికి సారథియై యుద్ధం ముగిసే వరకూ పాండవులకు రక్షణగా ఉన్నాడు. "
  },
  {
    title: "అశ్వత్థామ అస్త్రంవల్ల ఉత్తర గర్భంలో పిండం కూడా మృత్యవును ఎదుర్కోగా కృష్ణుడు తన చక్రంతో ఆ గర్భస్థ శిశువును రక్షించాడు. ఆ శిశువే పరీక్షిత్తుగా జన్మించి పాండవుల అనంతరం రాజ్యానికి అధిపతి అయ్యాడు."
  },
  {
    title: ""
  },
  {
    title: "@నిర్యాణం :"
  },
  {
    title: "మహాభారత యుద్ధానంతరం యాదవకులం కూడా అంతఃకలహాలతో నశిస్తుందని గాంధారి శపించింది. బలరాముడు యోగం ద్వారా దేహాన్ని త్యజించాడు. కృష్ణుడు అరణ్యాలకు వెళ్ళాడు. అక్కడినుండి కృష్ణుడు స్వర్గానికి నేరుగా వెళ్ళాడని వ్యాసుని భారతంలో ఉంది. అయితే ఒక నిషాదుని బాణం వలన కృష్ణుడు గాయపడి దేహాన్ని త్యజించాడని మరికొన్ని పురాణాలలో ఉంది."
  },
  {
    title: ""
  },
  {
    title: "@కొన్ని ప్రసిద్ధ శ్రీకృష్ణ మందిరాలు :"
  },
  {
    title: "పూరి (ఒడిషా) - జగన్నాథ మందిరం"
  },
  {
    title: "గురువాయూరు (కేరళ) - గురువాఐరోపాప మందిరం"
  },
  {
    title: "నాథద్వార (గుజరాత్) - శ్రీనాధ్ జీ మందిరం"
  },
  {
    title: "హరేకృష్ణ మందిరాలు - బెంగళూరు, ముంబై, తిరుపతి"
  },
  {
    title: "నార్కెట్ పల్లి - నల్గొండ - వారిజాల వేణుగోపాలస్వామి"
  },
  {
    title: "మధుర  - ఉత్తర ప్రదేశ్"
  },
  {
    title: "ఉడిపి - కర్ణాటక"
  },
  {
    title: "ద్వారక - గుజరాత్"
  },
  {
    title: "మన్నార్ గుడి - తమిళనాడు"
  },
  {
    title: ""
  },
  {
    title: "@శ్రీకృష్ణారాధన ద్వారా ప్రసిద్ధులైనవారు :"
  },
  {
    title: "మీరాబాయి"
  },
  {
    title: "చైతన్యప్రభు"
  },
  {
    title: "సూరదాసు"
  },
  {
    title: "మధ్వాచార్యుడు"
  },
  {
    title: "భక్తి వేదాంత ప్రభుపాద"
  },
];
export default krishnajananam;
