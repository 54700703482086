import React, { useEffect, useState } from "react";
import "../assets/css/styles.css";
import Rectangle1547 from "../assets/images/Rectangle 1547.png";
import Rectangle1545 from '../assets/images/Rectangle 1545.png';
import ViewImg from "../assets/images/Group.png";
import FlowerArrows from "../assets/images/flowerArrows.png";
import TextBg from "../assets/images/textBg.png";
import RashiIcons from "../assets/images/rashiIcons/image.png";
import Image2 from "../assets/images/devotional/image2.png";
import sideGridRightImage from "../assets/images/sideGridRight.png";
import Image3 from "../assets/images/devotional/image3.png";
import Image4 from "../assets/images/devotional/image4.png";
import Image5 from "../assets/images/devotional/image5.png";
import Image6 from "../assets/images/devotional/image6.png";
import Image1 from "../assets/images/devotional/image1.png";
import Vrushabha from "../assets/images/rashiIcons/vrushabha.png";
import Midhuna from "../assets/images/rashiIcons/midhuna.png";
import Karkata from "../assets/images/rashiIcons/karkata.png";
import Simha from "../assets/images/rashiIcons/simha.png";
import Kanya from "../assets/images/rashiIcons/kanya.png";
import Tula from "../assets/images/rashiIcons/tula.png";
import Vrushika from "../assets/images/rashiIcons/vrushika.png";
import Dhanushu from "../assets/images/rashiIcons/dhanushu.png";
import Makara from "../assets/images/rashiIcons/makara.png";
import Kumbha from "../assets/images/rashiIcons/kumbha.png";
import Meena from "../assets/images/rashiIcons/meena.png";
import Sunset from '../assets/images/sunset.png';
import Sunrise from '../assets/images/sunrise.png';
import Footer from "./footer";
import Header from "./header";
import Navbar from "./navbar";
import { Carousel } from 'react-bootstrap';
import { festivaldetailsData } from '../assets/jsons/festivalDetails';
import { Link, useNavigate } from 'react-router-dom';
import { PanchangamData } from "../assets/jsons/panchangam1";
import ItaramuluData from "../assets/jsons/ItaramuluData";
import { LocalData } from "../assets/jsons/localdata";

// Mapping for Telugu weekdays
const weekDays = {
  0: "ఆదివారము",
  1: "సోమవారము",
  2: "మంగళవారము",
  3: "బుధవారము",
  4: "గురువారము",
  5: "శుక్రవారము",
  6: "శనివారము",
};

// Mapping for Telugu months
const months = {
  January: "జనవరి",
  February: "ఫిబ్రవరి",
  March: "మార్చి",
  April: "ఏప్రిల్",
  May: "మే",
  June: "జూన్",
  July: "జూలై",
  August: "ఆగష్టు",
  // August: "ఆగస్టు", 
  September: "సెప్టెంబర్",
  October: "అక్టోబర్",
  November: "నవంబర్",
  December: "డిసెంబర్",
};

const formatDateInTelugu = (date) => {
  const dayOfWeek = weekDays[date.getDay()];
  const day = date.getDate();
  const month = months[date.toLocaleString("default", { month: "long" })];
  const year = date.getFullYear();

  return `${dayOfWeek}, ${month} ,${day}, ${year}`;
};
const formatDateInTelugus = (date) => {
  const dayOfWeek = weekDays[date.getDay()];
  const day = date.getDate();
  const month = months[date.toLocaleString("default", { month: "long" })];
  const year = date.getFullYear();

  return `${dayOfWeek}, ${month} ${day}, ${year}`;
};
const Index = () => {
  //pandugapramukhyatha
  const navigate = useNavigate(); // Initialize useNavigate

  const handleTitleClick = (festival) => {
    // Navigate to the Panchangam page and pass festival details as state
    navigate('/pandugapramukyata', { state: { festival } });
  };
  //date wise jsons
  const [data, setData] = useState({});
  useEffect(() => {
    yearData
      .flatMap((entry) => entry.day)
      .forEach((dayData) => {
        if (formattedTodays == dayData.Date) {
          console.log("Day Data Date:", dayData.Ayanam);
          setData(dayData);
        }
      });
  }, []);
  //For Pandugalu Json
  const [selectedFestival, setSelectedFestival] = useState(null);
  const [currentMonthData, setCurrentMonthData] = useState([]);
  const [displayedFestivals, setDisplayedFestivals] = useState([]);

  const [upavas, setUpavas] = useState([]);
  const thidulu = {
    "Amavas": "అమావాస్య",
    "Chatur": "చతుర్ధి",
    "Ekadas": "ఏకాదశి",
    "Purnim": "పౌర్ణమి",
    "Prados": "ప్రదోషము",
    "Shast": "షష్ఠి",
    "Chavith": "చవితి",
    "MasaShivarat": "మాస శివరాత్రి"

  }
  useEffect(() => {
    if (filteredData) {
      const currentDay = currentDate.getDate();

      const dateKeys = ['Amavas', 'Chatur', 'Ekadas', 'Purnim', 'Prados', 'Shast', 'Chavith', 'MasaShivarat'];
      const upcomingDates = [];

      dateKeys.forEach(key => {

        const dataForKey = filteredData[key];
        if (dataForKey && dataForKey.trim() !== "-" && dataForKey.trim() !== "") {
          const dateStrings = filteredData[key].split(',');

          dateStrings.forEach(dateStr => {
            const [dayOfWeek, month, day] = dateStr.trim().split(' ');
            const trimmedMonth = month.trim();
            const monthIndex = monthsInTelugu.indexOf(trimmedMonth) + 1;

            if (monthIndex > 0) {
              const eventDate = new Date(`${currentYear}-${monthIndex}-${parseInt(day, 10)}`);

              if (!isNaN(eventDate)) {
                if (eventDate >= currentDate) {
                  upcomingDates.push({ key, date: eventDate, label: dateStr.trim() });
                }
              } else {

              }
            } else {

            }

          });

        }

      });

      upcomingDates.sort((a, b) => a.date - b.date);


      const nextThreeDates = upcomingDates.slice(0, 4);
      setUpavas(nextThreeDates);
    } else {
      console.log('No data available for the current month.');
    }
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-indexed: 0 = January, 11 = December
    const currentDay = currentDate.getDate(); // Get the current day

    const currentYearData = LocalData.FestivalUpdated[0]; // Access the current year data

    let upcomingFestivals = [];

    // Get festivals from the current month, starting from today
    let currentMonthData = currentYearData[currentMonth].day.filter(festival => {
      const festivalDay = parseInt(festival.Date.split(', ')[1].split(' ')[0], 10);
      return festivalDay >= currentDay;
    });

    upcomingFestivals = upcomingFestivals.concat(currentMonthData.slice(0, 3));

    // If there are not enough festivals this month, get them from the next month
    if (upcomingFestivals.length < 3 && currentMonth < 11) {
      const nextMonthData = currentYearData[currentMonth + 1].day;
      upcomingFestivals = upcomingFestivals.concat(nextMonthData.slice(0, 3 - upcomingFestivals.length));
    }

    // If still not enough, get festivals from the next year
    if (upcomingFestivals.length < 3 && LocalData.FestivalUpdated.length > 1) {
      const nextYearData = LocalData.FestivalUpdated[1][0].day; // Start from January of the next year
      upcomingFestivals = upcomingFestivals.concat(nextYearData.slice(0, 3 - upcomingFestivals.length));
    }

    // Set the state with the next 3 upcoming festivals
    setDisplayedFestivals(upcomingFestivals.slice(0, 3));

  }, []);

  const handleDateClick = (festival) => {
    setSelectedFestival(festival);
    // Filter to show only the selected date's festival(s)
    setDisplayedFestivals([{ date: festival.Date, festival: festival.Festival }]);
  };


  const today = new Date();
  const formattedToday = formatDateInTelugu(today);
  const formattedTodays = formatDateInTelugus(today);

  const firstThreeLetters = formattedToday.split(",");
  const yearData =
    PanchangamData.PanchangamUpdated && PanchangamData.PanchangamUpdated[2024];
  if (!yearData) {
    return <div>Data for 2024 is not available.</div>;
  }

  const todayData = yearData
    .flatMap((entry) => entry.day)
    .find((dayData) => dayData.Date === formattedToday);
  console.log("Today:", today);
  console.log("Formatted Today:", formattedTodays);
  console.log("Year Data:", yearData);
  console.log("Today Data:", todayData);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const monthsInTelugu = ['జనవరి', 'ఫిబ్రవరి', 'మార్చి', 'ఏప్రిల్', 'మే', 'జూన్', 'జూలై', 'ఆగష్టు', 'సెప్టెంబర్', 'అక్టోబర్', 'నవంబర్', 'డిసెంబర్'];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const currentMonth = monthsInTelugu[currentDate.getMonth()];

  const yearIndex = currentYear === '2024' ? 0 : currentYear === '2025' ? 1 : null;
  const monthIndex = monthsInTelugu.indexOf(currentMonth);

  const filteredData = yearIndex !== null && monthIndex !== -1
    ? ItaramuluData.upavasamDates[yearIndex][monthIndex]
    : null;

  const formatDate = (dateString) => {
    // Mapping of full day names to their shortened forms
    const dayOfWeekMap = {
      'ఆదివారము': 'ఆది',
      'సోమవారము': 'సోమ',
      'మంగళవారము': 'మంగళ',
      'బుధవారము': 'బుధ',
      'గురువారము': 'గురు',
      'శుక్రవారము': 'శుక్ర',
      'శనివారము': 'శని'
    };

    const [dayOfWeek, rest] = dateString.split(', ');
    const [day, monthYear] = rest.split(' ');

    // Shorten the day of the week using the map
    const shortenedDayOfWeek = dayOfWeekMap[dayOfWeek] || dayOfWeek;

    return { dayOfWeek: shortenedDayOfWeek, day, monthYear };
  };
  const readmore = () => {
    navigate('/Muhurthalu');
  }
  return (
    <div>
      <Header />
      <Navbar />

      <div className="container-fluid" style={{ background: "#ffe6d0" }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: "repeat-y",
              transform: "rotateY(180deg)",
              alignItems: "left",
            }}
          ></div>


          
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3 center">
            <div className="row"> 

            <section  >
                <div className="container">
                  <div className="row ">

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
                <div className="schedule-card">
                  <div className="overlay"></div>
                  <div className="content"><br></br>
                    <h1>{firstThreeLetters[1] + firstThreeLetters[3]}</h1>
                    <div className="container" style={{ display: 'flex' }}>
                      <div style={{ position: 'relative', width: '30%', height: '50%', marginTop: "-20px" }}>
                        <img src={Sunrise} alt="Sunrise" style={{ width: '100%', height: '100%', backgroundRepeat: 'no-repeat' }} />
                        <div style={{
                          position: 'absolute',
                          top: '65%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: '#FFFFFF',
                          textAlign: 'center',
                        }}>
                          <h5 style={{ color: '#FFFFFF', marginBottom: '10px' }}>{data.Sunrise}</h5>
                          <small style={{ fontSize: '18px', position: 'relative', display: 'block', marginTop: '10px' }}>సూర్యోదయం</small>
                        </div>
                      </div>
                      <p style={{ marginTop: '-5px' }}>
                        <span className='textCap'><span >{firstThreeLetters[2]}</span></span>
                      </p>

                      <div style={{ position: 'relative', width: '30%', height: '50%', marginTop: "-20px" }}>
                        <img src={Sunset} alt="Sunrise" style={{ width: '100%', height: '100%', backgroundRepeat: 'no-repeat' }} />
                        <div style={{
                          position: 'absolute',
                          top: '65%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: '#FFFFFF',
                          textAlign: 'center',
                        }}>
                          <h5 style={{ color: '#FFFFFF', marginBottom: '10px' }}>{data.Sunset}</h5>
                          <small style={{ fontSize: '18px', position: 'relative', display: 'block', marginTop: '10px' }}>సూర్యాస్తమయం</small>
                        </div>
                      </div>
                    </div>
                    <p style={{ fontWeight: '900', marginTop: "-2px" }}>
                      {firstThreeLetters[0] +
                        ", " +
                        data.Ayanam +
                        ", " +
                        data.Masam +
                        ", " +
                        data.Paksham}{" "}
                    </p>
                    <p style={{ fontWeight: '900' }}>{data.Karanamulu}</p>
                    <p style={{ fontWeight: '900' }}>{yearData.Date}</p>
                    <div style={{ position: 'relative', textAlign: 'center', marginTop: '20px', marginBottom: "20px" }}>
                      <img src={Rectangle1547} alt="Background" />
                      <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#FFFFFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}>
                        <img src={ViewImg} alt="View Icon" style={{ width: '24px', height: '24px' }} />
                        <a href='/calendar' className="view-calendar-link">View Calendar</a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
                <div className="schedule-card">
                  <div className="overlay"></div><br></br>
                  <div className="content">
                    <h1>ఈ రోజు పంచాంగం</h1><br></br>
                    <p style={{ marginTop: '-20px' }}>రాహుకాలం - {data.RahuKalam}</p>
                    <p>యమగండం - {data.Yamaganda}</p>
                    <p>అమృతఘడియలు - {data.Amrutakalam}</p>
                    <p>దుర్ముహూర్తం - {data.DurMuhurtamulu}</p>
                    <p>తిథి - {data.Thiti}</p>
                    <p>నక్షత్రం - {data.Nakshatra1}</p>
                    {/* <a href="#" className="read-more">
                      Get More
                    </a> */}
                    <div style={{ position: 'relative' }} >
                      <img src={Rectangle1547} alt="Background Image" style={{ marginBottom: '20px' }} />
                      <div style={{
                        position: 'absolute',
                        top: '35%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}>
                        <a href='/Panchangam' className="view-calendar-link">Get More</a>
                        {/* <p style={{ margin: 0, fontFamily: "Amaranth", fontSize: "18px" }}>Get More</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             

              <div className="row justify-content-center align-items-center">
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <img
                    src={FlowerArrows}
                    className=""
                    alt="Flower Arrows"
                  />
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <div className="rashipalalu text-center">
                    <h1>రాశి ఫలాలు</h1>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <img
                    src={FlowerArrows}
                    className="img-fluid"
                    alt="Flower Arrows"
                  />
                </div>
              </div>

              <section className="pt-4">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to={{ pathname: '/Rasi-Phalalu', search: '?sort=మేష రాశి' }}>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={RashiIcons} />
                              <br />
                            </div>
                            <p className="rasiGrid">మేష రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Vrushabha} />
                              <br />
                            </div>
                            <p className="rasiGrid">వృషభ రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Midhuna} />
                              <br />
                            </div>
                            <p className="rasiGrid">మిథున రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Karkata} />
                              <br />
                            </div>
                            <p className="rasiGrid">కర్కాటక రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Simha} />
                              <br />
                            </div>
                            <p className="rasiGrid">సింహ రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>

                    <br></br><br></br>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Kanya} />
                              <br />
                            </div>
                            <p className="rasiGrid">కన్యా రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Tula} />
                              <br />
                            </div>
                            <p className="rasiGrid">తులా రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Vrushika} />
                              <br />
                            </div>
                            <p className="rasiGrid">వృశ్చిక రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Dhanushu} />
                              <br />
                            </div>
                            <p className="rasiGrid">ధనుస్సు రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Makara} />
                              <br />
                            </div>
                            <p className="rasiGrid">మకర రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Kumbha} />
                              <br />
                            </div>
                            <p className="rasiGrid">కుంభ రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5">
                      <Link to='/Rasi-Phalalu'>
                        <div className="card bg-brown border-0 h-100">
                          <div className="text-center">
                            <div className="feature mt-n4">
                              <img src={Meena} />
                              <br />
                            </div>
                            <p className="rasiGrid">మీన రాశి</p>
                            <br />
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 center">
                <div className="schedule-card-2">
                  {/* <div className="overlay"></div> */}
                  <div className="content">
                    <h1 className="Anek" >పండుగలు</h1>
                    <br />
                    <div style={{ marginTop: "-5px" }}>
                      <p>
                        {displayedFestivals.length > 0 ? (
                          displayedFestivals.map((festivalInfo, index) => {
                            const { dayOfWeek, day, monthYear } = formatDate(festivalInfo.Date);
                            return (
                              <div key={index}>
                                <div className="row" style={{ marginBottom: '-20px' }}>
                                  <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="rectangle" style={{ position: 'relative', width: '100%', marginBottom: '30px' }}>
                                      <img className="rectangle" src={Rectangle1545} style={{ width: '80%', height: 'auto' }} />
                                      <div style={{
                                        position: 'absolute',
                                        top: '55%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: '#FFFFFF',
                                        textAlign: 'center',
                                        width: '100%',
                                      }}>
                                        <h5 className="Pandagalu" style={{ fontFamily: "Anek Telugu", fontSize: "16px", color: '#FFFFFF', marginBottom: '3px' }}>{monthYear}</h5>
                                        <div style={{
                                          borderBottom: '2px solid #FF9E46',
                                          width: '80%',
                                          margin: '0 auto 10px auto',
                                          marginBottom: '1px'
                                        }}></div>
                                        <small className="Pandagalu" style={{ fontFamily: 'Arbutus', fontSize: '20px', position: 'relative', display: 'block' }}>{day}</small>
                                        <small className="Pandagalu" style={{ fontFamily: "Anek Telugu", fontSize: "16px", position: 'relative', display: 'block' }}>{dayOfWeek}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-9 col-md-8 col-sm-6 browncolor">
                                    <p style={{
                                      textAlign: 'center',
                                      marginTop: '20px',
                                      fontFamily: "Anek Telugu",
                                      fontSize: "20px",
                                      marginRight: '10px'
                                    }}>
                                      {festivalInfo.Festival}
                                    </p>
                                  </div>

                                </div>

                                <div style={{
                                  borderBottom: '2px solid #8E4C10',
                                  width: '100%',
                                  margin: '0 auto 10px auto',
                                  marginBottom: '8px'
                                }}></div>

                              </div>

                            );

                          })
                        ) : (
                          <p>దయచేసి తేదీని ఎంచుకోండి</p>
                        )}


                        <div style={{ position: 'relative', display: 'grid', gap: '10px' }}>
                          <Link to="/Pandugalu">
                            <button
                              style={{
                                alignSelf: 'end',
                                justifySelf: 'center',
                                padding: '10px 20px',
                                background: '#904B0B',
                                color: '#FFFFFF',
                                border: '#AB601B 2px solid',
                                borderRadius: '50px',
                                cursor: 'pointer',
                                marginTop: '10px',
                                marginBottom: '20px'
                              }}
                            >
                              Get More
                            </button>
                          </Link>
                        </div>



                        {/* <div style={{ position: 'relative', display: 'grid', gap: '10px' }} >
                          <img src={Rectangle1547} alt="Background Image" style={{ marginBottom: '20px'}} />
                          <div style={{
                            position: 'absolute',
                            top: '35%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px'
                          }}>

                            <p style={{ margin: 0, fontFamily: "Amaranth", fontSize: "18px" }}>Get More</p>
                          </div>
                        </div> */}


                      </p>

                    </div>

                  </div>
                </div>
              </div>


              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 center">
  <div className="schedule-card-2">
    <div className="overlay"></div>
    <div className="content">
      <h1>ఉపవాసం</h1>
      <br />

      <div className="festival-containers">
        {upavas.map((festivalInfo, index) => {
          return (
            <div key={index} className="festival-items">
              <div className="festival-names">
                <p>{thidulu[festivalInfo.key]}</p>
              </div>
              <div className="festival-dates">
                <p>{festivalInfo.label}</p>
              </div>
            </div>
          );
        })}
      </div>

      {/* Container for the button */}
      <div style={{
        display: 'grid',
        gridTemplateRows: '1fr auto', // First row takes available space, second row for button
        gap: '10px',
        height: '100%' // Ensures it fills the available space
      }}>
        <Link to="/Upavasam">
          <button
            style={{
              gridRow: '2', // Place the button in the second row (at the bottom)
              justifySelf: 'center', // Horizontally center the button
              alignSelf: 'center', // Vertically center the button (on its grid row)
              padding: '10px 20px',
              background: '#904B0B',
              color: '#FFFFFF',
              border: '#AB601B 2px solid',
              borderRadius: '50px',
              cursor: 'pointer',
              marginTop: '10px',
              marginBottom: '20px'
            }}
          >
            Get More
          </button>
        </Link>
      </div>
    </div>
  </div>
</div>


              {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 center">
                <div className="schedule-card-2">
                  <div className="overlay"></div>
                  <div className="content">
                    <h1>ఉపవాసం</h1>
                    <br />

                    <div className="festival-containers">
                      {upavas.map((festivalInfo, index) => {
                        return (
                          <div key={index} className="festival-items">
                            <div className="festival-names">
                              <p>{thidulu[festivalInfo.key]}</p>
                            </div>
                            <div className="festival-dates">
                              <p>{festivalInfo.label}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div> 
                    <div style={{ position: 'relative', display: 'grid', gap: '10px' }}>
                      <Link to="/Upavasam">
                        <button
                          style={{
                            alignSelf: 'end',
                            justifySelf: 'center',
                            padding: '10px 20px',
                            background: '#904B0B',
                            color: '#FFFFFF',
                            border: '#AB601B 2px solid',
                            borderRadius: '50px',
                            cursor: 'pointer',
                            marginTop: '10px',
                            marginBottom: '20px'
                          }}
                        >
                          Get More
                        </button>
                      </Link>
                    </div> 
                  </div>
                </div>
              </div> */}

              <div style={{ marginBottom: '35px' }}></div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 schedule-card-3"> 
                  <Carousel indicators={false} controls={true} interval={3000} style={{ top: '20%' }}  >
                    {Object.keys(festivaldetailsData).map((festivalKey) => {
                      const festival = festivaldetailsData[festivalKey];
                      return (
                        <Carousel.Item key={festivalKey} onClick={() => handleTitleClick(festival)} >
                        <div className="carousel-item-content">
                          <h2>{festival.title}</h2>
                          </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel> 
                </div>  
 
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3 center">
                <div className="schedule-card">
                  <div className="overlay"></div>
                  <div className="content">
                    <h1>ముహూర్తాలు</h1>
                    <div className="link-container">
                      <div className="column">
                        <Link to={{ pathname: '/Muhurthalu', search: "?sort=పెళ్లి ముహూర్తాలు" }}><p>పెళ్లి</p></Link>
                        <Link to={{ pathname: '/Muhurthalu', search: "?sort=గృహ ప్రవేశ ముహూర్తాలు" }}><p>గృహప్రవేశం</p></Link>
                        <Link to={{ pathname: '/Muhurthalu', search: "?sort=భూమి పూజ ముహూర్తాలు" }}><p>భూమి పూజ</p></Link>
                      </div>
                      <div className="column">
                        <Link to={{ pathname: '/Muhurthalu', search: "?sort=ఆస్తి కొనుగోలు ముహూర్తాలు" }}><p>ఆస్తి కొనుగోలు</p></Link>
                        <Link to={{ pathname: '/Muhurthalu', search: "?sort=నామకరణ ముహూర్తాలు" }}><p>నామకరణము</p></Link>
                        <Link to={{ pathname: '/Muhurthalu', search: "?sort=వాహన కొనుగోలు ముహూర్తాలు" }}><p>వాహన కొనుగోలు</p></Link>
                      </div>
                    </div>
                    <div style={{ position: 'relative' }} onClick={readmore}>
                      <img src={Rectangle1547} alt="Background Image" style={{ marginBottom: '10px' }} />
                      <div style={{
                        position: 'absolute',
                        top: '35%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}>
                        <p style={{ margin: 0, fontFamily: "Amaranth", fontSize: "18px" }}>Get More</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
                <div className="schedule-card">
                  <div className="overlay"></div>
                  <div className="content">
                    <h1>హిందూ ధర్మశాస్త్రం</h1>
                    <div className="link-container">
                      <div className="column">
                        <Link to="/Jeevanashaili"><p>జీవిత శైలి</p></Link>
                        <Link to="/Dharmasandehalu"><p>ధర్మ సందేశాలు</p></Link>
                        <Link to="/Hindu-Darmashastram"><p>హిందూ ధర్మ శాస్త్రాలు</p></Link>
                        <Link to="/Pandugapramukyata"><p>పండుగ ప్రాముఖ్యత</p></Link>
                      </div>
                      <div className="column">
                        <Link to="/Masavishistatha"><p>మాస విశిష్టత</p></Link>
                        <Link to="/Srustirahasyalu"><p>సృష్టి రహస్యాలు</p></Link>
                        {/* <a href="#">సంప్రదాయాలు</a> */}
                      </div>
                    </div>
                    <div style={{ position: 'relative' }} onClick={readmore}>
                      <img src={Rectangle1547} alt="Background Image" style={{ marginBottom: '10px' }} />
                      <div style={{
                        position: 'absolute',
                        top: '35%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}>
                        <p style={{ margin: 0, fontFamily: "Amaranth", fontSize: "18px" }}>Get More</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center align-items-center">
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <img
                    src={FlowerArrows}
                    className=""
                    alt="Flower Arrows"
                  />
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <div className="rashipalalu text-center">
                    <h1>భక్తిరంజిని</h1>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <img
                    src={FlowerArrows}
                    className="img-fluid"
                    alt="Flower Arrows"
                  />
                </div>
              </div>

              {/* <div className="row justify-content-center align-items-center">
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <img
                    src={FlowerArrows}
                    className="img-fluid"
                    alt="Flower Arrows"
                  />
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <div className="rashipalalu text-center">
                    <h1>భక్తిరంజిని</h1>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                  <img
                    src={FlowerArrows}
                    className="img-fluid"
                    alt="Flower Arrows"
                  />
                </div>
              </div> */}

              <section className="pt-4">
                <div className="container px-lg-5">
                  <div className="row gx-lg-5"> 
                    <div className="col-lg-2 col-xxl-2 mb-5" onClick={() => handleNavigation('/ashtotharalu')}>
                      <div className="card bg-brown border-0 h-100">
                        <div className="text-center">
                          <div className="feature mt-n4">
                            <img src={Image2} />
                            <br />
                          </div>
                          <p className="rasiGrid">అష్టోత్తరాలు</p>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5" onClick={() => handleNavigation('/slokalu')}>
                      <div className="card bg-brown border-0 h-100">
                        <div className="text-center">
                          <div className="feature mt-n4">
                            <img src={Image3} />
                            <br />
                          </div>
                          <p className="rasiGrid">శ్లోకాలు</p>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5" onClick={() => handleNavigation('/Shotramlu')}>
                      <div className="card bg-brown border-0 h-100">
                        <div className="text-center">
                          <div className="feature mt-n4">
                            <img src={Image4} />
                            <br />
                          </div>
                          <p className="rasiGrid">స్తోత్రములు</p>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5" onClick={() => handleNavigation('/Thidra-Yatra')}>
                      <div className="card bg-brown border-0 h-100">
                        <div className="text-center">
                          <div className="feature mt-n4">
                            <img src={Image5} />
                            <br />
                          </div>
                          <p className="rasiGrid">తీర్థ యాత్ర</p>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5" onClick={() => handleNavigation('/Athyadhimika-shakthi')}>
                      <div className="card bg-brown border-0 h-100">
                        <div className="text-center">
                          <div className="feature mt-n4">
                            <img src={Image6} />
                            <br />
                          </div>
                          <p className="rasiGrid">ఆధ్యాత్మిక శక్తి </p>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xxl-2 mb-5" onClick={() => handleNavigation('/Upavasam')}>
                      <div className="card bg-brown border-0 h-100">
                        <div className="text-center">
                          <div className="feature mt-n4">
                            <img src={Image1} />
                            <br />
                          </div>
                          <p className="rasiGrid">ఉపవాసం</p>
                          <br />
                        </div>
                      </div>
                    </div>
                    </div>
              </div>
              </section>
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-3">
                      <div className="rectangle-11"></div>
                      <img
                        src={Rectangle1548}
                        className="img-fluid"
                        alt="Responsive image"
                      />
                    </div> */}



                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3 center">
                      <div className="schedule-card">
                        <div className="overlay"></div>
                        <div className="content"><br />
                          <h1 style={{ marginTop: '10px' }}>ఆస్ట్రాలజీ</h1>
                          <br />
                          <div className="link-container">
                            <div className="column">
                              <Link to="/grahalu "><p>గ్రహాలు</p></Link>
                              <Link to="/Sankya-Shastram"><p>సంఖ్య శాస్త్రం</p></Link>
                            </div>
                            <div className="column">
                              <Link to="/bhavishyavani "><p>భవిష్యవాణి</p></Link>
                              <Link to="/Vasthu-Chitkalu"><p>వాస్తు చిట్కాలు</p></Link>
                            </div>
                          </div>
                        </div>
                        {/* <div style={{ position: 'relative' }}>
                          <img src={Rectangle1547} alt="Background Image" style={{ marginBottom: '10px' }} />
                          <div style={{
                            position: 'absolute',
                            top: '35%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px'
                          }}>
                            <p style={{ margin: 0, fontFamily: "Amaranth", fontSize: "18px" }}>Get More</p>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
                      <div className="schedule-card">
                        <div className="overlay"></div>
                        <div className="content"><br />
                          <h1 style={{ marginTop: '10px' }}>విలువైన సమాచారం</h1>
                          <br />
                          <div className="link-container">
                            <div className="column">
                              <Link to="/Grahanam"><p>గ్రహణం</p></Link>
                              <Link to="/samethalu"><p>సామెతలు</p></Link>
                              <Link to="/samskrutham"><p>సంస్కృతం</p></Link>
                              <Link to="/Nithyasathyalu"><p>నిత్య సత్యాలు</p></Link>
                            </div>
                            <div className="column">
                              <Link to="/Nithipadhyalu"><p>నీతి పద్యాలు</p></Link>
                              <Link to="/rastralabashalu"><p>రాష్ట్రాల భాషలు</p></Link>
                              <Link to="/Villuvina-Samacharam"><p>విలువైన సమాచారం</p></Link>
                            </div>
                          </div>
                        </div>
                        <div style={{ position: 'relative' }} onClick={readmore}>
                          <img src={Rectangle1547} alt="Background Image" style={{ marginBottom: '10px' }} />

                          <div
                            style={{
                              position: 'absolute',
                              top: '35%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: '#FFFFFF',
                              textAlign: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '10px'
                            }}
                          >
                            <Link to="/Villuvina-Samacharam" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
                              <p style={{ margin: 0, fontFamily: "Amaranth", fontSize: "18px" }}>Get More</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ marginBottom: '35px' }}></div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 schedule-card-3">
                      <Carousel indicators={false} controls={true} interval={3000} style={{ top: '20%' }}>
                        <Carousel.Item>
                          <div className="carousel-item-content">
                            <a href="/Ramayanam">
                              <h2>రామాయణం</h2>
                            </a>
                          </div>
                        </Carousel.Item>
                        <Carousel.Item>
                          <div className="carousel-item-content">
                            <a href="/Adhi-Parvam">
                              <h2>మహాభారతం</h2>
                            </a>
                          </div>
                        </Carousel.Item>
                        <Carousel.Item>
                          <div className="carousel-item-content">
                            <a href="/Krishnani-Jananam" >
                              <h2> భగవద్గీత</h2>
                            </a>
                          </div>
                        </Carousel.Item>
                      </Carousel>
                    </div> 

                  </div>

                </div>

                <div
                  className="col-lg-1 col-md-0"
                  style={{
                    background: `url(${sideGridRightImage}) `,
                    backgroundRepeat: "repeat-y",
                  }}
                ></div>
              </section>
            </div>
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: "repeat-y",
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Index;




