import React, { useEffect, useState } from 'react'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Sankyapradanyata = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchedCategories = ItaramuluData.SankhyalaPramukyatalu.CatID;
    setCategories(fetchedCategories);
    if (fetchedCategories.length > 0) {
      setSelectedCategory(fetchedCategories[0]);  // Set the first category by default
    }
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedItem(null);
  };

  const handleItemClick = (item) => {
    if (selectedItem === item) {
      // If the clicked item is already selected, unselect it (toggle close)
      setSelectedItem(null);
    } else {
      // Otherwise, select the clicked item (toggle open)
      setSelectedItem(item);
    }
  };

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="row">
              <div>
                {categories.map((category, index) => (
                  <div className="card-header card mb-3"
                    key={index}
                    onClick={() => handleCategoryClick(category)}
                    style={{
                      backgroundColor: selectedCategory === category ? '#B35C0C' : '#754213',
                      color: "#FFFFFF"
                    }}>
                    <h5 className="mb-0">
                      <div
                        className="btn btn" style={{ color: '#FFFFFF' }}>
                        {category.title}
                      </div>
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            style={{
              overflowY: rightSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            {selectedCategory && (
              <div>
                {selectedCategory.telugu.map((item, index) => (
                  <div className="card-body rightPanel" key={index}>
                    <h5 onClick={() => handleItemClick(item)}>
                      {item.title}
                    </h5>
                    {selectedItem === item && (  // Toggle content visibility based on selectedItem
                      <div style={{ marginBottom: '20px' }}>
                        {selectedItem.subtitle.map((sub, subIndex) => (
                          <div key={subIndex}>
                            <ul>{sub.title}</ul>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Sankyapradanyata;
