import React, { useState } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Rastralabashalu = () => {

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 p-3"
              onMouseEnter={() => {
                setLeftSticky(false);
                setRightSticky(true);
              }}
              style={{
                overflowY: leftSticky ? 'hidden' : 'auto',
                height: '80vh',
                position: leftSticky ? 'sticky' : 'static',
                top: 0,
              }}
          >
            <div className="table-responsive">
            <div style={{color:'#FFFFFF' ,backgroundColor: '#B35C0C',height:'40px',borderRadius:'5px 5px 0px 0px',textAlign:'center'}}>{ItaramuluData.officiallanguages.title}</div>

              <table className="table rightPanel1">
                <thead style={{ backgroundColor: '#B35C0C', padding: '10px',color:'#FFFFFF' }}>
                  <tr>
                    {/* <th>{ItaramuluData.officiallanguages.title}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {ItaramuluData.officiallanguages.CatID.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td style={{ fontWeight: 'bold', }}>భాష:</td>
                        <td>{item.title}</td>
                      </tr><tr key={`state-${index}`}>
                        <td style={{ fontWeight: 'bold' }}>రాష్ట్రం:</td>
                        <td>{item.state}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Rastralabashalu;
