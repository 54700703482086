import React, { useEffect, useState } from "react";
import Header from "../header";
import Navbar from "../navbar";
import Footer from "../footer";
import ItaramuluData from "../../assets/jsons/ItaramuluData";
import sideGridRightImage from "../../assets/images/sideGridRight.png";
import { useLocation } from "react-router-dom";

const Muhurthalu = () => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedText, setSelectedText] = useState("పెళ్లి ముహూర్తాలు");
  const [activeText, setActiveText] = useState("పెళ్లి ముహూర్తాలు"); // State to track the active text button
  const [activeYear, setActiveYear] = useState("2024");

  // Handler for year button click
  const handleYearClick = (year) => {
    setSelectedYear(year);
    setSelectedText(selectedText);
    setActiveYear(year)// Reset selected text when year changes
    console.log(selectedText,'selectedText')
  };

  // Handler for text button click
  const handleTextClick = (text) => {
    setSelectedText(text);
    setActiveText(text); // Set the active text button
  };

  // Determine the year data based on selected year
  const yearData = ItaramuluData.MuhurthamUpdated.find((data) =>
    data.Title.some((title) =>
      title.dates.some((date) => date.date.includes(selectedYear))
    )
  );

  // Filtered data based on selected year and text
  const filteredData =
    yearData?.Title.filter(
      (title) => !selectedText || title.txt === selectedText
    ).flatMap((title) => title.dates) || [];

  // Inline styles for active button
  const getButtonStyle = (text) => ({
    backgroundColor: activeText === text ? '#B35C0C' : '#754213',
    color: '#FFFFFF',
  });
  const getButtonStyles = (type, value) => ({
    backgroundColor: type === "year" ? (activeYear === value ? '#B35C0C' : '#754213') : (activeText === value ? '#B35C0C' : '#754213'),
    color: '#FFFFFF',
  });
const location = useLocation();
useEffect(()=>{
  const params = new URLSearchParams(location.search);
  const sort = params.get('sort');

  
  if(sort){
    handleTextClick(sort);
  }
},[location.search]);

const [leftSticky, setLeftSticky] = useState(true);
const [rightSticky, setRightSticky] = useState(true);

  return (
    <>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
        <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
        <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
          style={{
            overflowY: leftSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: leftSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
            <div className="d-flex justify-content-evenly p-3">
            <button className="button1"
              onClick={() => handleYearClick("2024")}
              style={getButtonStyles("year", "2024")}
            >
              2024
            </button>
            <button className="button1"
              onClick={() => handleYearClick("2025")}
              style={getButtonStyles("year", "2025")}
            >
              2025
            </button>
            </div>
            <div className="row">
              <div id="accordion">
                <div
                  className="card-header card mb-3"
                  style={getButtonStyle("పెళ్లి ముహూర్తాలు")}
                >
                  <button
                    className="btn btn"
                    onClick={() => handleTextClick("పెళ్లి ముహూర్తాలు")}
                    style={{color: '#FFFFFF'}}
                  >
                    పెళ్లి ముహూర్తాలు
                  </button>
                </div>

                <div
                  className="card-header card mb-3"
                  style={getButtonStyle("గృహ ప్రవేశ ముహూర్తాలు")}
                >
                  <button
                    className="btn btn"
                    onClick={() => handleTextClick("గృహ ప్రవేశ ముహూర్తాలు")}
                    style={{color: '#FFFFFF'}}
                  >
                    గృహ ప్రవేశ ముహూర్తాలు
                  </button>

                </div>
                <div
                  className="card-header card mb-3"
                  style={getButtonStyle("వాహన కొనుగోలు ముహూర్తాలు")}
                >
                  <button
                    className="btn btn"
                    onClick={() => handleTextClick("వాహన కొనుగోలు ముహూర్తాలు")}
                    style={{color: '#FFFFFF'}}
                  >
                    వాహన కొనుగోలు
                  </button>
                </div>

                <div
                  className="card-header card mb-3"
                    style={getButtonStyle("భూమి పూజ ముహూర్తాలు")}
                >
                  <button
                    className="btn btn"
                    onClick={() => handleTextClick("భూమి పూజ ముహూర్తాలు")}
                    style={{color: '#FFFFFF'}}
                  >
                    భూమి పూజ
                  </button>

                </div>
                <div
                  className="card-header card mb-3"
                    style={getButtonStyle("ఆస్తి కొనుగోలు ముహూర్తాలు")}
                >
                  <button
                    className="btn btn"
                    onClick={() => handleTextClick("ఆస్తి కొనుగోలు ముహూర్తాలు")}
                    style={{color: '#FFFFFF'}}
                  >
                    ఆస్తి కొనుగోలు
                  </button>
                </div>

                <div
                  className="card-header card mb-3"
                    style={getButtonStyle("నామకరణ ముహూర్తాలు")}
                >
                  <button
                    className="btn btn"
                    onClick={() => handleTextClick("నామకరణ ముహూర్తాలు")}
                    style={{color: '#FFFFFF'}}
                  >
                    నామకరణ
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(true);
            setRightSticky(false);
          }}
          style={{
            overflowY: rightSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: rightSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
            <div className="card-body rightPanel">
              <h5>{selectedText}</h5>
              {filteredData.map((item, index) => (
                <ul key={index}>{item.date}</ul>
              ))}
            </div>
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Muhurthalu;
