import React, { useState } from 'react'
import Header from './header'
import Navbar from './navbar'
import Footer from './footer'
import sideGridRightImage from '../assets/images/sideGridRight.png';
import ItaramuluData from '../assets/jsons/ItaramuluData';

const Panchangam = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  const handleToggle = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const renderCatIDList = (category) => {
    console.log(category)
    if (category && Array.isArray(category.CatID)) {
      return category.CatID.map((item, index) => (
        <>
        <div key={index}>{item.title}</div>
        <div>{item.measure}</div>
        </>
      ));
    }
    return null;
  };

  const filteredCategories = [
    'Telugunakshatramulu',
    'Teluguthidulu',
    'telugudays',
    'karanamulu',
    'yogalu',

  ].reduce((acc, key) => {
    if (ItaramuluData[key]) {
      acc[key] = ItaramuluData[key];
    }
    return acc;
  }, {});


  return (

    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              transform: 'rotateY(180deg)',
            }}
          >
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3">
            <div className="row">
              <div id="accordion">
                {Object.keys(filteredCategories).map((key, index) => (
                  <div className="card mb-3" style={{ backgroundColor: '#754213' }} key={index}>
                    <div className="card-header">
                      <h5 className="mb-0">
                        <button
                          className="btn btn"
                          onClick={() => handleToggle(ItaramuluData[key])}
                          aria-expanded={activeCategory === ItaramuluData[key]}
                          aria-controls={`collapse${index}`}
                          style={{ color: '#FFFFFF' }}
                        >
                          {ItaramuluData[key].title}
                        </button>
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3">
            {Object.keys(filteredCategories).map((key, index) => (
              <div
                key={index}
                id={`collapse${index}`}
                className={`collapse ${activeCategory === ItaramuluData[key] ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-parent="#accordion"
              >
                <div className="card-body" style={{ backgroundColor: '#B35C0C', color: '#FFFFFF' }}>
                {ItaramuluData[key].title}
                  {renderCatIDList(ItaramuluData[key])}
                </div>
              </div>
            ))}
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
            }}
          >
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Panchangam