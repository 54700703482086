import React, { useState } from 'react';
import krishnajananam from '../../assets/jsons/Telugu/About_Krishna'; // Assuming this is the JSON file with data
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import Flower from '../../assets/images/image.png'; // Assuming this is the path to the flower image

const Krishnajananam = () => {
    const [leftSticky, setLeftSticky] = useState(true);
    const [rightSticky, setRightSticky] = useState(true);

    return (
        <div>
            <Header />
            <Navbar />
            <div className="container-fluid" style={{ background: '#ffe6d0' }}>
                <div className="row">
                    <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
                    <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 p-3"
                        onMouseEnter={() => {
                            setLeftSticky(false);
                            setRightSticky(true);
                        }}
                        style={{
                            overflowY: leftSticky ? 'hidden' : 'auto',
                            height: '80vh',
                            position: leftSticky ? 'sticky' : 'static',
                            top: 0,
                        }}
                    >
                        <div className="row">
                            <div style={{ textAlign: 'center' }}>
                                <div className="card-body rightPanel">
                                    <h5>శ్రీకృష్ణుని జీవితం</h5>
                                    {krishnajananam
                                        .filter(item => item.title.trim() !== "")
                                        .map((item, index) => (
                                            <div key={index}>
                                                <p>
                                                    <img
                                                        src={Flower}
                                                        alt="Flower"
                                                        style={{ width: '20px', marginRight: '10px' }}
                                                    />
                                                    {item.title}
                                                </p>
                                                <br />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
                    <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Krishnajananam;
