import React, { useEffect, useState } from 'react'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import sideGridRightImage from '../../assets/images/sideGridRight.png';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

import Right from "../../assets/images/rightside.png";
import Left from "../../assets/images/leftside.png";
const getFormattedDate = (monthIndex, year) => {
  const months = ["జనవరి", "ఫిబ్రవరి", "మార్చి", "ఏప్రిల్", "మే", "జూన్", "జూలై", "ఆగస్టు", "సెప్టెంబర్", "అక్టోబర్", "నవంబర్", "డిసెంబర్"];
  return `${months[monthIndex]} ${year}`;
};

const Grahalu = () => {
  const today = new Date();
  const [currentMonthIndex, setCurrentMonthIndex] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const incrementMonth = () => {
    setCurrentMonthIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex === 12) {
        setCurrentYear((prevYear) => prevYear < 2025 ? prevYear + 1 : 2024);
        return 0;
      }
      return newIndex;
    });
  };

  const decrementMonth = () => {
    setCurrentMonthIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex === -1) {
        setCurrentYear((prevYear) => prevYear > 2024 ? prevYear - 1 : 2025);
        return 11;
      }
      return newIndex;
    });
  };

  const getCurrentData = () => {
    const yearIndex = currentYear - 2024;
    if (yearIndex >= 0 && yearIndex < ItaramuluData.upavasamDates.length) {
      const monthYearData = ItaramuluData.upavasamDates[yearIndex];
      return monthYearData[currentMonthIndex % monthYearData.length]?.data || {};
    }
    return {};
  };
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(true);
  const [isIncrementDisabled, setIsIncrementDisabled] = useState(false);
  useEffect(() => {
    // Disable or enable navigation buttons based on the current month and year
    setIsDecrementDisabled(currentMonthIndex === 0 && currentYear === 2024);
    setIsIncrementDisabled(currentMonthIndex === 11 && currentYear === 2025);
  }, [currentMonthIndex, currentYear]);

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  return (
    <>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
              transform: 'rotateY(180deg)',
            }}
          >
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3"
                 onMouseEnter={() => {
                  setLeftSticky(false);
                  setRightSticky(true);
              }}
              style={{
                  overflowY: leftSticky ? 'hidden' : 'auto',
                  height: '80vh',
                  position: leftSticky ? 'sticky' : 'static',
                  top: 0,
              }}
          >
            <div className="row">
              <div className="col-lg-3 col-md-2">
                <img
                  src={Left}
                  onClick={!isDecrementDisabled ? decrementMonth : null}
                  className={`img-fluid ${isDecrementDisabled ? "disabled" : ""
                    }`}
                  style={
                    isDecrementDisabled
                      ? { opacity: 0.5, cursor: "not-allowed" }
                      : {}
                  }
                />
                 <div
                  style={{
                    position: "absolute",
                    top: "55%",
                    left: "16%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Previous
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <h2 style={{ marginLeft: "200px", color: "#6B3300"  }}>
                  {/* {months[currentMonthIndex]} {currentYear} */}
                  {getFormattedDate(currentMonthIndex, currentYear)}
                </h2>
              </div>
              <div className="col-lg-3 col-md-2">
                <img
                  src={Right}
                  onClick={!isIncrementDisabled ? incrementMonth : null}
                  className={`img-fluid ${isIncrementDisabled ? "disabled" : ""
                    }`}
                  style={
                    isIncrementDisabled
                      ? {
                        marginLeft: "80px",
                        opacity: 0.5,
                        cursor: "not-allowed",
                      }
                      : { marginLeft: "80px" }
                  }
                />
              </div>
              <div
                  style={{
                    position: "absolute",
                    top: "56%",
                    left: "108%",
                    transform: "translate(-30%, -30%)",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Next
                </div>
              </div>
              <div className='row'>  
                <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 p-3'></div>
                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3'>
                <div>
                <div className="card-body rightPanel">
                  {/* <h1>{getFormattedDate(currentMonthIndex, currentYear)}</h1>
                <button onClick={decrementMonth}>Previous Month</button>
                <button onClick={incrementMonth}>Next Month</button> */}
                  <h5>{getFormattedDate(currentMonthIndex, currentYear)}</h5>
                  <ul>
                    <div>అమావాస్య: {getCurrentData().Amavasya}</div>
                    <div>పౌర్ణమి: {getCurrentData().Purni}</div>
                    <div>చతుర్ధి: {getCurrentData().Chaturthi}</div>
                    <div>ప్రదోషము: {getCurrentData().Pradosham}</div>
                    {/* <div>షష్ఠి: {getCurrentData().sasti}</div>
                  <div>చవితి: {getCurrentData().Chavi}</div> */}
                    <div>ఏకాదశి: {getCurrentData().Ekadasi}</div>
                    {/* <div>మాస శివరాత్రి: {getCurrentData().Masa}</div> */}
                  </ul>
                  <br></br>
                </div>
              </div>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-12 col-xs-12 p-3'></div>
              </div>
           
          
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Grahalu
