import React, { useEffect, useState } from "react";
import { PanchangamData } from "../assets/jsons/panchangam1";
import Rectangle1547 from "../assets/images/Rectangle 1547.png";

function Today_panchangam() {

    const weekDays = {
        0: "ఆదివారము",
        1: "సోమవారము",
        2: "మంగళవారము",
        3: "బుధవారము",
        4: "గురువారము",
        5: "శుక్రవారము",
        6: "శనివారము",
      };
      
      // Mapping for Telugu months
      const months = {
        January: "జనవరి",
        February: "ఫిబ్రవరి",
        March: "మార్చి",
        April: "ఏప్రిల్",
        May: "మే",
        June: "జూన్",
        July: "జూలై",
        August: "ఆగష్టు",
        // August: "ఆగస్టు", 
        September: "సెప్టెంబర్",
        October: "అక్టోబర్",
        November: "నవంబర్",
        December: "డిసెంబర్",
      };
    
      const formatDateInTelugus = (date) => {
        const dayOfWeek = weekDays[date.getDay()];
        const day = date.getDate();
        const month = months[date.toLocaleString("default", { month: "long" })];
        const year = date.getFullYear();
      
        return `${dayOfWeek}, ${month} ${day}, ${year}`;
      };
    const [data, setData] = useState({});
    const today = new Date();
    const formattedTodays = formatDateInTelugus(today);
    useEffect(() => {
        yearData
          .flatMap((entry) => entry.day)
          .forEach((dayData) => {
            if (formattedTodays == dayData.Date) {
              console.log("Day Data Date:", dayData.Ayanam);
              setData(dayData);
            }
          });
      }, []);
    const yearData =
    PanchangamData.PanchangamUpdated && PanchangamData.PanchangamUpdated[2024];
  if (!yearData) {
    return <div>Data for 2024 is not available.</div>;
  }


 
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
    <div className="schedule-card">
      <div className="overlay"></div><br></br>
      <div className="content">
        <h1>ఈ రోజు పంచాంగం</h1><br></br>
        <p style={{ marginTop: '-20px' }}>రాహుకాలం - {data.RahuKalam}</p>
        <p>యమగండం - {data.Yamaganda}</p>
        <p>అమృతఘడియలు - {data.Amrutakalam}</p>
        <p>దుర్ముహూర్తం - {data.DurMuhurtamulu}</p>
        <p>తిథి - {data.Thiti}</p>
        <p>నక్షత్రం - {data.Nakshatra1}</p>
       
        <div style={{ position: 'relative' }} >
          <img src={Rectangle1547} alt="Background Image" style={{ marginBottom: '20px' }} />
          <div style={{
            position: 'absolute',
            top: '35%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#FFFFFF',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px'
          }}>

            <p style={{ margin: 0, fontFamily: "Amaranth", fontSize: "18px" }}>Read More</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Today_panchangam
