import React, { useState } from 'react'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Nithyasathyalu = () => {
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 p-3">
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="card-body rightPanel">
              <h5>{ItaramuluData.truewords.title}</h5>
              {Array.isArray(ItaramuluData.truewords.CatID) &&
                ItaramuluData.truewords.CatID
                  .filter((item) => item.title !== "")
                  .map((item, index) => (
                    <div
                      className="collapse show"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <ul key={index}>{item.title}</ul>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 p-3">
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Nithyasathyalu