import React, { useState } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Telugusamskrutham = () => {
  // Filtered categories
  const filteredCategories = [
    'Teluguyears',
    'Telugurasulu',
    'Telugumonths',
    'Telugunumbers',
    'Teluguletters',
    'teluguguninthamulu',
    'JanapadaKalalu',
    'Kulavruthulu',
    'telugunavagrahalu',
    'Telugurutuvulu',
    'kolethelu',
    'Telugunpakshamulu',
    'telugulagnam',
    'Telugupakshmaulonithidulu'
  ].reduce((acc, key) => {
    if (ItaramuluData[key]) {
      acc[key] = ItaramuluData[key];
    }
    return acc;
  }, {});

  // Initialize with the first category from filteredCategories
  const defaultCategoryKey = Object.keys(filteredCategories)[0];
  const [activeCategory, setActiveCategory] = useState(filteredCategories[defaultCategoryKey]);


  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);


  const handleToggle = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const getCategoryTitle = (category, index) => {
    switch (index) {
      case 0:
        return "తెలుగు సంవత్సరాలు"; // Telugu text for index 0
      case 3:
        return "తెలుగు అంకెలు"; // Telugu text for index 3
      case 9:
        return "ఋతువులు"; // Telugu text for index 9
      default:
        return category.title;
    }
  };

  const renderCatIDList = (category, index) => {
    const title = getCategoryTitle(category, index);
    console.log('Rendering category:', title);

    if (category && Array.isArray(category.CatID)) {
      return category.CatID.map((item, index) => (
        <div key={index}>
          <div>{item.title}</div>
          <div>{item.measure}</div>
        </div>
      ));
    } else if (Array.isArray(category)) {
      return category.map((item, index) => (
        <div key={index}>
          <div>{item.title}</div>
          {item.CatID && item.CatID.map((catItem, catIndex) => (
            <div key={catIndex} style={{ marginLeft: '20px' }}>
              <div>{catItem.title}</div>
              <div>{catItem.measure}</div>
            </div>
          ))}
        </div>
      ));
    }
    return null;
  };

  console.log('Filtered Categories:', filteredCategories);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
          style={{
            overflowY: leftSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: leftSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >

            <div className="row">
              <div id="accordion">
                {Object.keys(filteredCategories).map((key, index) => {
                  const title = getCategoryTitle(filteredCategories[key], index);

                  return (
                    <div
                      key={index}
                      id={`heading${index}`}
                      onClick={() => handleToggle(filteredCategories[key])}
                      className="card-header card mb-3"
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: activeCategory === filteredCategories[key] ? '#B35C0C' : '#754213'
                      }}
                    >
                      <h5 className="mb-0">
                        <button
                          className="btn btn"
                          aria-expanded={activeCategory === filteredCategories[key]}
                          aria-controls={`collapse${index}`}
                          style={{ color: '#FFFFFF' }}
                        >
                          {title}
                        </button>
                      </h5>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(true);
            setRightSticky(false);
          }}
          style={{
            overflowY: rightSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: rightSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
          
            {Object.keys(filteredCategories).map((key, index) => (
              <div
                key={index}
                id={`collapse${index}`}
                className={`collapse ${activeCategory === filteredCategories[key] ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-parent="#accordion"
              >
                <div className="card-body rightPanel">
                  <h5>{getCategoryTitle(filteredCategories[key], index)}</h5>
                  <ul>{renderCatIDList(filteredCategories[key], index)}</ul>
                  <br />
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Telugusamskrutham;
