import React, { useState, useEffect } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay } from 'date-fns';
import '../assets/css/styles.css';
import Footer from './footer';
import Header from './header';
import Navbar from './navbar';
import preview from '../assets/images/devotional/preview.png';
import next from '../assets/images/devotional/next.png';
import { PanchangamData } from '../assets/jsons/panchangam1';
import { Carousel } from 'react-bootstrap';
import { LocalData } from '../assets/jsons/localdata';
import CalendarBg from '../assets/images/calendarbg.png'
import Amavasya_pic from '../assets/images/Ellipse 25.png';

import { festivaldetailsData } from '../assets/jsons/festivalDetails';
import { useNavigate } from 'react-router-dom';
// import footerBg from '../assets/images/footerbackground.png'
// Telugu months mapping
const teluguMonths = {
    January: "జనవరి",
    February: "ఫిబ్రవరి",
    March: "మార్చి",
    April: "ఏప్రిల్",
    May: "మే",
    June: "జూన్",
    July: "జూలై",
    August: "ఆగస్టు",
    September: "సెప్టెంబర్",
    October: "అక్టోబర్",
    November: "నవంబర్",
    December: "డిసెంబర్"
};

const Calendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dayData, setDayData] = useState(null);
    const [dayDataMap, setDayDataMap] = useState({}); 
    const styles = {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 'calc(-1 * var(--bs-gutter-y))',
        marginRight: 'calc(-0.5 * var(--bs-gutter-x))',
        marginLeft: 'calc(-0.5 * var(--bs-gutter-x))',
    };

    //For Pandugalu Json
    const [selectedFestival, setSelectedFestival] = useState(null);
    const [currentMonthData, setCurrentMonthData] = useState([]);
    const [displayedFestivals, setDisplayedFestivals] = useState([]);
    const navigate = useNavigate(); 
    const handleTitleClick = (festival) => {
       
        navigate('/pandugapramukyata', { state: { festival } });
      };
    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // 0-indexed: 0 = January, 11 = December

        // Filter data for the current month
        const currentMonthData = LocalData.FestivalUpdated.flatMap(yearData =>
            yearData.filter((monthData, monthIndex) => monthIndex === currentMonth)
        );

        setCurrentMonthData(currentMonthData);

        // Display all festivals for the current month initially
        const allFestivals = currentMonthData.flatMap(monthData =>
            monthData.day.map(dateInfo => ({
                date: dateInfo.Date,
                festival: dateInfo.Festival
            }))
        );
        setDisplayedFestivals(allFestivals);
    }, []);

    const handleDateClick = (festival) => {
        setSelectedFestival(festival);
        // Filter to show only the selected date's festival(s)
        setDisplayedFestivals([{ date: festival.Date, festival: festival.Festival }]);
    };
  
    const loadDayData = (date) => {
        const year = 2024; 
        const monthIndex = date.getMonth();
        const dayIndex = date.getDate() - 1;

        const yearData = PanchangamData.PanchangamUpdated[year];

        if (yearData && yearData[monthIndex] && yearData[monthIndex].day) {
            const monthData = yearData[monthIndex].day;
            if (monthData && monthData[dayIndex]) {
                setDayData(monthData[dayIndex]);

                
            } else {
                setDayData(null);
            }
        } else {
            setDayData(null);
        }
    };
  
    useEffect(() => {
        
        loadDayData(currentMonth);
    }, [currentMonth]);

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };


    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        const formattedDate = format(currentMonth, dateFormat);
        const [month, year] = formattedDate.split(" ");
        const teluguMonth = teluguMonths[month];
        return (
            <div className="header flex-middle text-center" style={styles}>
                <div className="col col-start">
                    <button className="nav-button" onClick={prevMonth}>
                        <img src={preview} className='preview' alt="Previous Month" />
                    </button>
                </div>
               
                <div className="col col-center telugu-mon">
                    {dayData ? ( <span>{`${dayData.Masam}`}&nbsp;&nbsp;{`${dayData.Date} `}</span>):( <span>{`${teluguMonth} ${year}`}</span>)}
                   
                </div>
                <div className="col col-end">
                    <button className="nav-button" onClick={nextMonth}>
                        <img src={next} className='preview' alt="Next Month" />
                    </button>
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const days = ["ఆది", "సోమ", "మంగళ", "బుధ", "గురు", "శుక్ర", "శని"];
        return (
            <div className="days row text-center">
                {days.map((day, index) => (
                    <div className="col col-center" key={index}>
                        {day}
                    </div>
                ))}
            </div>
        );
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "dd";
        const today = new Date();
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
       
        while (day <= endDate) {
            let rowIndex = rows.length;
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const isToday = isSameDay(day, today);
                
                const cloneDay = day;
                const monthIndex = day.getMonth();
                const dayIndex = day.getDate() - 1;
                const yearData = PanchangamData.PanchangamUpdated[2024];
                 let Amrutakalam = '';
                 let DurMuhurtamulu ='';
                 let festival_date = '';
                 let Amavasya = '';
                if (yearData && yearData[monthIndex] && yearData[monthIndex].day) {
                    const monthData = yearData[monthIndex].day;
                    if (monthData && monthData[dayIndex]) {
                        console.log(monthData[dayIndex],'leela')
                        Amrutakalam = monthData[dayIndex].Amrutakalam;

                        DurMuhurtamulu=monthData[dayIndex].DurMuhurtamulu;
                        festival_date = monthData[dayIndex].FestDate;
                        Amavasya = monthData[dayIndex].Amavasya;
                    }
                }
                
                const isDisabled = !isSameMonth(day, monthStart);
              
                const isSelected = isSameDay(day, selectedDate);
                
                // Check if this day is a festival
              
                let cellClasses = "col cell-table";
                let cellStyle = {
                  border: '2px solid #AC9179',
                  textAlign: 'end',
                  color: '#924906', 
                  backgroundColor: 'inherit', 
                  
                };
                
              
                if (isDisabled) {
                  cellClasses += " disabled";
                  cellStyle.color = '#E6CAB1'; 
                } else if (isSelected) {
                  cellClasses += " selected";
                  cellStyle.backgroundColor = '#FFF9F3'; 
                }
              
                if (isToday) {
                  cellClasses += " today";
                  cellStyle.backgroundColor = '#FED2B3'; 
                }
              
                if (festival_date != "") {
                  cellClasses += " festival-highlight"; 
                  cellStyle.backgroundColor = '#B35C0C'; 
                 
                }
              
                days.push(
                    <div
                        // className={`col cell-table ${!isSameMonth(day, monthStart) ? "disabled" : isSameDay(day, selectedDate) ? "selected" : isToday? "today":""}`}
                        className={cellClasses}
                        key={day}
                        style={cellStyle}
                        onClick={() => onDateClick(cloneDay)}
                    >
                        <span>{Amavasya != "" &&(<img src={Amavasya_pic} style={{width:"10px",height:"10px",float:"left"}}/>)}</span>
                        <span className="number" style={{ color: festival_date != "" ? '#FFFFFF' : '#924906',fontFamily:"Roboto",fontSize:"24px"}}>{formattedDate}</span><br/>
                        
                        <span className="number"  style={{ color: festival_date != "" ? '#FFFFFF' : '#924906',fontFamily:"Anek Telugu",fontSize:"12px"}}>అమృతకాలం<br/>{Amrutakalam}</span> 
                    </div>
                );
                day = addDays(day, 1);
            }

            const rowStyle = {
                backgroundColor: rowIndex % 2 === 0 ? '#FFF9F3' : '#FDFDFD',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            };
    
            rows.push(
                <div
                    className="row"
                    key={day}
                    style={rowStyle}
                >
                    {days}
                </div>

            );
            days = [];
        }
        return <div className="body"  >{rows}</div>;
    };

    const onDateClick = day => {
      
        setSelectedDate(day);
        loadDayData(day);
    };

    const renderDayData = () => {
        if (!dayData) return <div>డేటా లేదు</div>;
        return (
            <>
                {/* <div className="day-data">
                <h4>{dayData.Date}</h4>
                <p>అభిజిత్: {dayData.Abhijit}</p>
                <p>అమృతకలం: {dayData.Amrutakalam}</p>
                <p>అయనం: {dayData.Ayanam}</p>
                <p>చంద్రాష్టమం: {dayData.Chandrashtama}</p>
                <p>దుర్ముహూర్తములు: {dayData.DurMuhurtamulu}</p>
                <p>గులిక కాళం: {dayData.GulikaiKalam}</p>
                <p>కరణములు: {dayData.Karanamulu}</p>
                <p>మాసం: {dayData.Masam}</p>
                <p>నక్షత్రం: {dayData.MainNakshatra}</p>
                <p>పక్షం: {dayData.Paksham}</p>
                <p>రాహుకాలం: {dayData.RahuKalam}</p>
                <p>ఋతువు: {dayData.Ruthuvu}</p>
                <p>సూర్యోదయం: {dayData.Sunrise}</p>
                <p>సూర్యాస్తమయం: {dayData.Sunset}</p>
                <p>తిథి: {dayData.Thiti}</p>
                <p>యమగండం: {dayData.Yamaganda}</p>
                <p>యోగం: {dayData.Yoga}</p>
                <p>వర్జ్యం: {dayData.Varjyam}</p>
                <p>చంద్రోదయం: {dayData.Moonrise}</p>
                <p>చంద్రాస్తమయం: {dayData.Moonset}</p>
                <p>శకా: {dayData.Shaka}</p>
            </div> */}
                <div className='row'>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3 center">
                        <div className="schedule-card">
                            <div className="overlay"></div>
                            <div className="content">
                                <h1>ఈ రోజు పంచాంగం</h1>
                                <p>రాహుకాలం -  {dayData.RahuKalam}</p>
                                <p>యమగండం - {dayData.Yamaganda}</p>
                                <p>అమృతఘడియలు - {dayData.Amrutakalam}</p>
                                <p>దుర్ముహూర్తం - {dayData.DurMuhurtamulu}</p>
                                <p>తిథి - {dayData.Thiti}</p>
                                <p>నక్షత్రం - {dayData.Nakshatra1}</p>
                                <a href="#" className="read-more">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3 center">
                        <div className="schedule-card">
                            <div className="overlay"></div>
                            <div className="content">
                                <h1>పండుగలు</h1>
                                <br />
                                <div>
                                    <p>{displayedFestivals.length > 0 ? (
                                        displayedFestivals.map((festivalInfo, index) => (
                                            <div key={index}>
                                                <p>{festivalInfo.date} : {festivalInfo.festival}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p>దయచేసి తేదీని ఎంచుకోండి</p>
                                    )}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div>
            <Header />
            <Navbar />
            <div className="container-fluid" style={{ background: '#FFE6D0' }}>
                <div className="row">
                    <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 schedule-card-4">
                                <Carousel indicators={false} controls={true} interval={2000}>
                                   {Object.keys(festivaldetailsData).map((festivalKey) => {
                                    const festival = festivaldetailsData[festivalKey];
                                    return (
                                        <Carousel.Item key={festivalKey} onClick={() => handleTitleClick(festival)} > 
                                        <h2>{festival.title}</h2> 
                                        </Carousel.Item> 
                                    );
                                    })}
                                </Carousel>
                            </div>
                            <section>
                                <br />
                           

                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-3 center">
                                    <table className="calendar" width="100%" style={{ backgroundImage: `url(${CalendarBg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan="7">
                                                    {renderHeader()}
                                                    {renderDays()}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {renderCells()}
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                {renderDayData()}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Calendar;




