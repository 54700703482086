const krishnaslokas = [
  {
    slokalu: [
      {
        title: "కరారవిందేన పదారవిందం ముఖారవిందే వినివేశయంతమ్ ।"
      },
      {
        title: "వటస్య పత్రస్య పుటే శయానం బాలం ముకుందం మనసా స్మరామి ॥ 1 ॥"
      },
      {
        title: ""
      },
      {
        title: "సంహృత్య లోకాన్వటపత్రమధ్యే శయానమాద్యంతవిహీనరూపమ్ ।"
      },
      {
        title: "సర్వేశ్వరం సర్వహితావతారం బాలం ముకుందం మనసా స్మరామి ॥ 2 ॥"
      },
      {
        title: ""
      },
      {
        title: "ఇందీవరశ్యామలకోమలాంగం ఇంద్రాదిదేవార్చితపాదపద్మమ్ ।"
      },
      {
        title: "సంతానకల్పద్రుమమాశ్రితానాం బాలం ముకుందం మనసా స్మరామి ॥ 3 ॥"
      },
      {
        title: ""
      },
      {
        title: "లంబాలకం లంబితహారయష్టిం శృంగారలీలాంకితదంతపంక్తిమ్ ।"
      },
      {
        title: "బింబాధరం చారువిశాలనేత్రం బాలం ముకుందం మనసా స్మరామి ॥ 4 ॥"
      },
      {
        title: ""
      },
      {
        title: "శిక్యే నిధాయాద్యపయోదధీని బహిర్గతాయాం వ్రజనాయికాయామ్ ।"
      },
      {
        title: "భుక్త్వా యథేష్టం కపటేన సుప్తం బాలం ముకుందం మనసా స్మరామి ॥ 5 ॥"
      },
      {
        title: ""
      },
      {
        title: "కలిందజాంతస్థితకాలియస్య ఫణాగ్రరంగేనటనప్రియంతమ్ ।"
      },
      {
        title: "తత్పుచ్ఛహస్తం శరదిందువక్త్రం బాలం ముకుందం మనసా స్మరామి ॥ 6 ॥"
      },
      {
        title: ""
      },
      {
        title: "ఉలూఖలే బద్ధముదారశౌర్యం ఉత్తుంగయుగ్మార్జున భంగలీలమ్ ।"
      },
      {
        title: "ఉత్ఫుల్లపద్మాయత చారునేత్రం బాలం ముకుందం మనసా స్మరామి ॥ 7 ॥"
      },
      {
        title: ""
      },
      {
        title: "ఆలోక్య మాతుర్ముఖమాదరేణ స్తన్యం పిబంతం సరసీరుహాక్షమ్ ।"
      },
      {
        title: "సచ్చిన్మయం దేవమనంతరూపం బాలం ముకుందం మనసా స్మరామి ॥ 8 ॥"
      }
    ],
    title: "బాలముకుంద అష్టకం",
    //  img: require('../../Utils/LoadIcons/19.png'),
  },
  {
    slokalu: [
      {
        title: "వసుదేవ సుతం దేవం కంస చాణూర మర్దనమ్ ।"
      },
      {
        title: "దేవకీ పరమానందం కృష్ణం వందే జగద్గురుమ్ ॥"
      },
      {
        title: ""
      },
      {
        title: "అతసీ పుష్ప సంకాశం హార నూపుర శోభితమ్ ।"
      },
      {
        title: "రత్న కంకణ కేయూరం కృష్ణం వందే జగద్గురుమ్ ॥"
      },
      {
        title: ""
      },
      {
        title: "కుటిలాలక సంయుక్తం పూర్ణచంద్ర నిభాననమ్ ।"
      },
      {
        title: "విలసత్ కుండలధరం కృష్ణం వందే జగద్గురమ్ ॥"
      },
      {
        title: ""
      },
      {
        title: "మందార గంధ సంయుక్తం చారుహాసం చతుర్భుజమ్ ।"
      },
      {
        title: "బర్హి పింఛావ చూడాంగం కృష్ణం వందే జగద్గురుమ్ ॥"
      },
      {
        title: ""
      },
      {
        title: "ఉత్ఫుల్ల పద్మపత్రాక్షం నీల జీమూత సన్నిభమ్ ।"
      },
      {
        title: "యాదవానాం శిరోరత్నం కృష్ణం వందే జగద్గురుమ్ ॥"
      },
      {
        title: ""
      },
      {
        title: "రుక్మిణీ కేళి సంయుక్తం పీతాంబర సుశోభితమ్ ।"
      },
      {
        title: "అవాప్త తులసీ గంధం కృష్ణం వందే జగద్గురుమ్ ॥"
      },
      {
        title: ""
      },
      {
        title: "గోపికానాం కుచద్వంద కుంకుమాంకిత వక్షసమ్ ।"
      },
      {
        title: "శ్రీనికేతం మహేష్వాసం కృష్ణం వందే జగద్గురుమ్ ॥"
      },
      {
        title: ""
      },
      {
        title: "శ్రీవత్సాంకం మహోరస్కం వనమాలా విరాజితమ్ ।"
      },
      {
        title: "శంఖచక్ర ధరం దేవం కృష్ణం వందే జగద్గురుమ్ ॥"
      },
      {
        title: ""
      },
      {
        title: "కృష్ణాష్టక మిదం పుణ్యం ప్రాతరుత్థాయ యః పఠేత్ ।"
      },
      {
        title: "కోటిజన్మ కృతం పాపం స్మరణేన వినశ్యతి ॥"
      }
    ],
    title: "కృష్ణాష్టకం",
    // img: require('../../Utils/LoadIcons/20.png'),
  },
  {
    slokalu: [
      {
        title: "అచ్యుతం కేశవం రామనారాయణం"
      },
      {
        title: "కృష్ణదామోదరం వాసుదేవం హరిమ్ ।"
      },
      {
        title: "శ్రీధరం మాధవం గోపికా వల్లభం"
      },
      {
        title: "జానకీనాయకం రామచంద్రం భజే ॥ 1 ॥"
      },
      {
        title: ""
      },
      {
        title: "అచ్యుతం కేశవం సత్యభామాధవం"
      },
      {
        title: "మాధవం శ్రీధరం రాధికా రాధితమ్ ।"
      },
      {
        title: "ఇందిరామందిరం చేతసా సుందరం"
      },
      {
        title: "దేవకీనందనం నందజం సందధే ॥ 2 ॥"
      },
      {
        title: ""
      },
      {
        title: "విష్ణవే జిష్ణవే శంకనే చక్రిణే"
      },
      {
        title: "రుక్మిణీ రాగిణే జానకీ జానయే ।"
      },
      {
        title: "వల్లవీ వల్లభాయార్చితా యాత్మనే"
      },
      {
        title: "కంస విధ్వంసినే వంశినే తే నమః ॥ 3 ॥"
      },
      {
        title: ""
      },
      {
        title: "కృష్ణ గోవింద హే రామ నారాయణ"
      },
      {
        title: "శ్రీపతే వాసుదేవాజిత శ్రీనిధే ।"
      },
      {
        title: "అచ్యుతానంత హే మాధవాధోక్షజ"
      },
      {
        title: "ద్వారకానాయక ద్రౌపదీరక్షక ॥ 4 ॥"
      },
      {
        title: ""
      },
      {
        title: "రాక్షస క్షోభితః సీతయా శోభితో"
      },
      {
        title: "దండకారణ్యభూ పుణ్యతాకారణః ।"
      },
      {
        title: "లక్ష్మణోనాన్వితో వానరైః సేవితో"
      },
      {
        title: "అగస్త్య సంపూజితో రాఘవః పాతు మామ్ ॥ 5 ॥"
      },
      {
        title: ""
      },
      {
        title: "ధేనుకారిష్టకాఽనిష్టికృద్-ద్వేషిహా"
      },
      {
        title: "కేశిహా కంసహృద్-వంశికావాదకః ।"
      },
      {
        title: "పూతనాకోపకః సూరజాఖేలనో"
      },
      {
        title: "బాలగోపాలకః పాతు మాం సర్వదా ॥ 6 ॥"
      },
      {
        title: ""
      },
      {
        title: "బిద్యుదుద్-యోతవత్-ప్రస్ఫురద్-వాససం"
      },
      {
        title: "ప్రావృడం-భోదవత్-ప్రోల్లసద్-విగ్రహమ్ ।"
      },
      {
        title: "వాన్యయా మాలయా శోభితోరః స్థలం"
      },
      {
        title: "లోహితాఙ్-ఘిద్వయం వారిజాక్షం భజే ॥ 7 ॥"
      },
      {
        title: ""
      },
      {
        title: "కుంచితైః కుంతలై భ్రాజమానాననం"
      },
      {
        title: "రత్నమౌళిం లసత్-కుండలం గండయోః ।"
      },
      {
        title: "హారకేయూరకం కంకణ ప్రోజ్జ్వలం"
      },
      {
        title: "కింకిణీ మంజులం శ్యామలం తం భజే ॥ 8 ॥"
      },
      {
        title: ""
      },
      {
        title: "అచ్యుతస్యాష్టకం యః పఠేదిష్టదం"
      },
      {
        title: "ప్రేమతః ప్రత్యహం పూరుషః సస్పృహమ్ ।"
      },
      {
        title: "వృత్తతః సుందరం కర్తృ విశ్వంభరః"
      },
      {
        title: "తస్య వశ్యో హరి ర్జాయతే సత్వరమ్ ॥"
      }
    ],
    title: "అచ్యుతాష్టకం",
    // img: require('../../Utils/LoadIcons/21.png'),
  },
  {
    slokalu: [
      {
        title: "ఓం అస్య శ్రీకృష్ణసహస్రనామస్తోత్రమంత్రస్య పరాశర ఋషిః, అనుష్టుప్ ఛందః, శ్రీకృష్ణః పరమాత్మా దేవతా, శ్రీకృష్ణేతి బీజం, శ్రీవల్లభేతి శక్తిః, శారంగీతి కీలకం, శ్రీకృష్ణప్రీత్యర్థే జపే వినియోగః ॥"
      },
      {
        title: ""
      },
      {
        title: "@న్యాసః"
      },
      {
        title: "పరాశరాయ ఋషయే నమః ఇతి శిర"
      },
      {
        title: "అనుష్టుప్ ఛందసే నమః ఇతి ముఖే,"
      },
      {
        title: "గోపాలకృష్ణదేవతాయై నమః ఇతి హృదయే,"
      },
      {
        title: "శ్రీకృష్ణాయ బీజాయ నమః ఇతి గుహ్యే,"
      },
      {
        title: "శ్రీవల్లభాయ శక్త్యై నమః ఇతి పాదయోః,"
      },
      {
        title: "శారంగధరాయ కీలకాయ నమః ఇతి సర్వాంగే ॥"
      },
      {
        title: ""
      },
      {
        title: "@కరన్యాసః"
      },
      {
        title: "శ్రీకృష్ణ ఇత్యారభ్య శూరవంశైకధీరిత్యంతాని అంగుష్ఠాభ్యాం నమః ।"
      },
      {
        title: "శౌరిరిత్యారభ్య స్వభాసోద్భాసితవ్రజ ఇత్యంతాని తర్జనీభ్యాం నమః ।"
      },
      {
        title: "కృతాత్మవిద్యావిన్యాస ఇత్యారభ్య ప్రస్థానశకటారూఢ ఇతి మధ్యమాభ్యాం నమః,"
      },
      {
        title: "బృందావనకృతాలయ ఇత్యారభ్య మధురాజనవీక్షిత ఇత్యనామికాభ్యాం నమః,"
      },
      {
        title: "రజకప్రతిఘాతక ఇత్యారభ్య ద్వారకాపురకల్పన ఇతి కనిష్ఠికాభ్యాం నమః"
      },
      {
        title: "ద్వారకానిలయ ఇత్యారభ్య పరాశర ఇతి కరతలకరపృష్ఠాభ్యాం నమః,"
      },
      {
        title: "ఏవం హృదయాదిన్యాసః ॥"
      },
      {
        title: ""
      },
      {
        title: "@ధ్యానం"
      },
      {
        title: "కేషాంచిత్ప్రేమపుంసాం విగలితమనసాం బాలలీలావిలాసం"
      },
      {
        title: "కేషాం గోపాలలీలాంకితరసికతనుర్వేణువాద్యేన దేవమ్ ।"
      },
      {
        title: "కేషాం వామాసమాజే జనితమనసిజో దైత్యదర్పాపహైవం"
      },
      {
        title: "జ్ఞాత్వా భిన్నాభిలాషం స జయతి జగతామీశ్వరస్తాదృశోఽభూత్ ॥ 1 ॥"
      },
      {
        title: ""
      },
      {
        title: "క్షీరాబ్ధౌ కృతసంస్తవస్సురగణైర్బ్రహ్మాదిభిః పండితైః"
      },
      {
        title: "ప్రోద్భూతో వసుదేవసద్మని ముదా చిక్రీడ యో గోకులే ।"
      },
      {
        title: "కంసధ్వంసకృతే జగామ మధురాం సారామసద్వారకాం"
      },
      {
        title: "గోపాలోఽఖిలగోపికాజనసఖః పాయాదపాయాత్ స నః ॥ 2 ॥"
      },
      {
        title: ""
      },
      {
        title: "ఫుల్లేందీవరకాంతిమిందువదనం బర్హావతంసప్రియం"
      },
      {
        title: "శ్రీవత్సాంకముదారకౌస్తుభధరం పీతాంబరం సుందరమ్ ।"
      },
      {
        title: "గోపీనాం నయనోత్పలార్చితతనుం గోగోపసంఘావృతం"
      },
      {
        title: "గోవిందం కలవేణువాదనరతం దివ్యాంగభూషం భజే ॥ 3 ॥"
      },
      {
        title: ""
      },
      {
        title: "ఓమ్ ।"
      },
      {
        title: "కృష్ణః శ్రీవల్లభః శారంగీ విష్వక్సేనః స్వసిద్ధిదః ।"
      },
      {
        title: "క్షీరోదధామా వ్యూహేశః శేషశాయీ జగన్మయః ॥ 1 ॥"
      },
      {
        title: ""
      },
      {
        title: "భక్తిగమ్యస్త్రయీమూర్తిర్భారార్తవసుధాస్తుతః ।"
      },
      {
        title: "దేవదేవో దయాసింధుర్దేవదేవశిఖామణిః ॥ 2 ॥"
      },
      {
        title: ""
      },
      {
        title: "సుఖభావస్సుఖాధారో ముకుందో ముదితాశయః ।"
      },
      {
        title: "అవిక్రియః క్రియామూర్తిరధ్యాత్మస్వస్వరూపవాన్ ॥ 3 ॥"
      },
      {
        title: ""
      },
      {
        title: "శిష్టాభిలక్ష్యో భూతాత్మా ధర్మత్రాణార్థచేష్టితః ।"
      },
      {
        title: "అంతర్యామీ కలారూపః కాలావయవసాక్షికః ॥ 4 ॥"
      },
      {
        title: ""
      },
      {
        title: "వసుధాయాసహరణో నారదప్రేరణోన్ముఖః ।"
      },
      {
        title: "ప్రభూష్ణుర్నారదోద్గీతో లోకరక్షాపరాయణః ॥ 5 ॥"
      },
      {
        title: ""
      },
      {
        title: "రౌహిణేయకృతానందో యోగజ్ఞాననియోజకః ।"
      },
      {
        title: "మహాగుహాంతర్నిక్షిప్తః పురాణవపురాత్మవాన్ ॥ 6 ॥"
      },
      {
        title: ""
      },
      {
        title: "శూరవంశైకధీశ్శౌరిః కంసశంకావిషాదకృత్ ।"
      },
      {
        title: "వసుదేవోల్లసచ్ఛక్తిర్దేవక్యష్టమగర్భగః ॥ 7 ॥"
      },
      {
        title: ""
      },
      {
        title: "వసుదేవసుతః శ్రీమాందేవకీనందనో హరిః ।"
      },
      {
        title: "ఆశ్చర్యబాలః శ్రీవత్సలక్ష్మవక్షాశ్చతుర్భుజః ॥ 8 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్వభావోత్కృష్టసద్భావః కృష్ణాష్టమ్యంతసంభవః ।"
      },
      {
        title: "ప్రాజాపత్యర్క్షసంభూతో నిశీథసమయోదితః ॥ 9 ॥"
      },
      {
        title: ""
      },
      {
        title: "శంఖచక్రగదాపద్మపాణిః పద్మనిభేక్షణః ।"
      },
      {
        title: "కిరీటీ కౌస్తుభోరస్కః స్ఫురన్మకరకుండలః ॥ 10 ॥"
      },
      {
        title: ""
      },
      {
        title: "పీతవాసా ఘనశ్యామః కుంచితాంచితకుంతలః ।"
      },
      {
        title: "సువ్యక్తవ్యక్తాభరణః సూతికాగృహభూషణః ॥ 11 ॥"
      },
      {
        title: ""
      },
      {
        title: "కారాగారాంధకారఘ్నః పితృప్రాగ్జన్మసూచకః ।"
      },
      {
        title: "వసుదేవస్తుతః స్తోత్రం తాపత్రయనివారణః ॥ 12 ॥"
      },
      {
        title: ""
      },
      {
        title: "నిరవద్యః క్రియామూర్తిర్న్యాయవాక్యనియోజకః ।"
      },
      {
        title: "అదృష్టచేష్టః కూటస్థో ధృతలౌకికవిగ్రహః ॥ 13 ॥"
      },
      {
        title: ""
      },
      {
        title: "మహర్షిమానసోల్లాసో మహీమంగలదాయకః ।"
      },
      {
        title: "సంతోషితసురవ్రాతః సాధుచిత్తప్రసాదకః ॥ 14 ॥"
      },
      {
        title: ""
      },
      {
        title: "జనకోపాయనిర్దేష్టా దేవకీనయనోత్సవః ।"
      },
      {
        title: "పితృపాణిపరిష్కారో మోహితాగారరక్షకః ॥ 15 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్వశక్త్యుద్ధాటితాశేషకపాటః పితృవాహకః ।"
      },
      {
        title: "శేషోరగఫణాచ్ఛత్రశ్శేషోక్తాఖ్యాసహస్రకః ॥ 16 ॥"
      },
      {
        title: ""
      },
      {
        title: "యమునాపూరవిధ్వంసీ స్వభాసోద్భాసితవ్రజః ।"
      },
      {
        title: "కృతాత్మవిద్యావిన్యాసో యోగమాయాగ్రసంభవః ॥ 17 ॥"
      },
      {
        title: ""
      },
      {
        title: "దుర్గానివేదితోద్భావో యశోదాతల్పశాయకః ।"
      },
      {
        title: "నందగోపోత్సవస్ఫూర్తిర్వ్రజానందకరోదయః ॥ 18 ॥"
      },
      {
        title: ""
      },
      {
        title: "సుజాతజాతకర్మ శ్రీర్గోపీభద్రోక్తినిర్వృతః ।"
      },
      {
        title: "అలీకనిద్రోపగమః పూతనాస్తనపీడనః ॥ 19 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్తన్యాత్తపూతనాప్రాణః పూతనాక్రోశకారకః ।"
      },
      {
        title: "విన్యస్తరక్షాగోధూలిర్యశోదాకరలాలితః ॥ 20 ॥"
      },
      {
        title: ""
      },
      {
        title: "నందాఘ్రాతశిరోమధ్యః పూతనాసుగతిప్రదః ।"
      },
      {
        title: "బాలః పర్యంకనిద్రాలుర్ముఖార్పితపదాంగులిః ॥ 21 ॥"
      },
      {
        title: ""
      },
      {
        title: "అంజనస్నిగ్ధనయనః పర్యాయాంకురితస్మితః ।"
      },
      {
        title: "లీలాక్షస్తరలాలోకశ్శకటాసురభంజనః ॥ 22 ॥"
      },
      {
        title: ""
      },
      {
        title: "ద్విజోదితస్వస్త్యయనో మంత్రపూతజలాప్లుతః ।"
      },
      {
        title: "యశోదోత్సంగపర్యంకో యశోదాముఖవీక్షకః ॥ 23 ॥"
      },
      {
        title: ""
      },
      {
        title: "యశోదాస్తన్యముదితస్తృణావర్తాదిదుస్సహః ।"
      },
      {
        title: "తృణావర్తాసురధ్వంసీ మాతృవిస్మయకారకః ॥ 24 ॥"
      },
      {
        title: ""
      },
      {
        title: "ప్రశస్తనామకరణో జానుచంక్రమణోత్సుకః ।"
      },
      {
        title: "వ్యాలంబిచూలికారత్నో ఘోషగోపప్రహర్షణః ॥ 25 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్వముఖప్రతిబింబార్థీ గ్రీవావ్యాఘ్రనఖోజ్జ్వలః ।"
      },
      {
        title: "పంకానులేపరుచిరో మాంసలోరుకటీతటః ॥ 26 ॥"
      },
      {
        title: ""
      },
      {
        title: "ఘృష్టజానుకరద్వంద్వః ప్రతిబింబానుకారకృత్ ।"
      },
      {
        title: "అవ్యక్తవర్ణవాగ్వృత్తిః స్మితలక్ష్యరదోద్గమః ॥ 27 ॥"
      },
      {
        title: ""
      },
      {
        title: "ధాత్రీకరసమాలంబీ ప్రస్ఖలచ్చిత్రచంక్రమః ।"
      },
      {
        title: "అనురూపవయస్యాఢ్యశ్చారుకౌమారచాపలః ॥ 28 ॥"
      },
      {
        title: ""
      },
      {
        title: "వత్సపుచ్ఛసమాకృష్టో వత్సపుచ్ఛవికర్షణః ।"
      },
      {
        title: "విస్మారితాన్యవ్యాపారో గోపగోపీముదావహః ॥ 29 ॥"
      },
      {
        title: ""
      },
      {
        title: "అకాలవత్సనిర్మోక్తా వ్రజవ్యాక్రోశసుస్మితః ।"
      },
      {
        title: "నవనీతమహాచోరో దారకాహారదాయకః ॥ 30 ॥"
      },
      {
        title: ""
      },
      {
        title: "పీఠోలూఖలసోపానః క్షీరభాండవిభేదనః ।"
      },
      {
        title: "శిక్యభాండసమాకర్షీ ధ్వాంతాగారప్రవేశకృత్ ॥ 31 ॥"
      },
      {
        title: ""
      },
      {
        title: "భూషారత్నప్రకాశాఢ్యో గోప్యుపాలంభభర్త్సితః ।"
      },
      {
        title: "పరాగధూసరాకారో మృద్భక్షణకృతేక్షణః ॥ 32 ॥"
      },
      {
        title: ""
      },
      {
        title: "బాలోక్తమృత్కథారంభో మిత్రాంతర్గూఢవిగ్రహః ।"
      },
      {
        title: "కృతసంత్రాసలోలాక్షో జననీప్రత్యయావహః ॥ 33॥"
      },
      {
        title: ""
      },
      {
        title: "మాతృదృశ్యాత్తవదనో వక్త్రలక్ష్యచరాచరః ।"
      },
      {
        title: "యశోదాలాలితస్వాత్మా స్వయం స్వాచ్ఛంద్యమోహనః ॥ 34 ॥"
      },
      {
        title: ""
      },
      {
        title: "సవిత్రీస్నేహసంశ్లిష్టః సవిత్రీస్తనలోలుపః ।"
      },
      {
        title: "నవనీతార్థనాప్రహ్వో నవనీతమహాశనః ॥ 35 ॥"
      },
      {
        title: ""
      },
      {
        title: "మృషాకోపప్రకంపోష్ఠో గోష్ఠాంగణవిలోకనః ।"
      },
      {
        title: "దధిమంథఘటీభేత్తా కింకిణీక్వాణసూచితః ॥ 36 ॥"
      },
      {
        title: ""
      },
      {
        title: "హైయంగవీనరసికో మృషాశ్రుశ్చౌర్యశంకితః ।"
      },
      {
        title: "జననీశ్రమవిజ్ఞాతా దామబంధనియంత్రితః ॥ 37 ॥"
      },
      {
        title: ""
      },
      {
        title: "దామాకల్పశ్చలాపాంగో గాఢోలూఖలబంధనః ।"
      },
      {
        title: "ఆకృష్టోలూఖలోఽనంతః కుబేరసుతశాపవిత్ ॥ । 38 ॥"
      },
      {
        title: ""
      },
      {
        title: "నారదోక్తిపరామర్శీ యమలార్జునభంజనః ।"
      },
      {
        title: "ధనదాత్మజసంఘుష్టో నందమోచితబంధనః ॥ 39 ॥"
      },
      {
        title: ""
      },
      {
        title: "బాలకోద్గీతనిరతో బాహుక్షేపోదితప్రియః ।"
      },
      {
        title: "ఆత్మజ్ఞో మిత్రవశగో గోపీగీతగుణోదయః ॥ 40 ॥"
      },
      {
        title: ""
      },
      {
        title: "ప్రస్థానశకటారూఢో బృందావనకృతాలయః ।"
      },
      {
        title: "గోవత్సపాలనైకాగ్రో నానాక్రీడాపరిచ్ఛదః ॥ 41 ॥"
      },
      {
        title: ""
      },
      {
        title: "క్షేపణీక్షేపణప్రీతో వేణువాద్యవిశారదః ।"
      },
      {
        title: "వృషవత్సానుకరణో వృషధ్వానవిడంబనః ॥ 42 ॥"
      },
      {
        title: ""
      },
      {
        title: "నియుద్ధలీలాసంహృష్టః కూజానుకృతకోకిలః ।"
      },
      {
        title: "ఉపాత్తహంసగమనస్సర్వజంతురుతానుకృత్ ॥ 43 ॥"
      },
      {
        title: ""
      },
      {
        title: "భృంగానుకారీ దధ్యన్నచోరో వత్సపురస్సరః ।"
      },
      {
        title: "బలీ బకాసురగ్రాహీ బకతాలుప్రదాహకః ॥ 44 ॥"
      },
      {
        title: ""
      },
      {
        title: "భీతగోపార్భకాహూతో బకచంచువిదారణః ।"
      },
      {
        title: "బకాసురారిర్గోపాలో బాలో బాలాద్భుతావహః ॥ 45 ॥"
      },
      {
        title: ""
      },
      {
        title: "బలభద్రసమాశ్లిష్టః కృతక్రీడానిలాయనః ।"
      },
      {
        title: "క్రీడాసేతునిధానజ్ఞః ప్లవంగోత్ప్లవనోఽద్భుతః ॥ 46 ॥"
      },
      {
        title: ""
      },
      {
        title: "కందుకక్రీడనో లుప్తనందాదిభవవేదనః ।"
      },
      {
        title: "సుమనోఽలంకృతశిరాః స్వాదుస్నిగ్ధాన్నశిక్యభృత్ ॥ 47 ॥"
      },
      {
        title: ""
      },
      {
        title: "గుంజాప్రాలంబనచ్ఛన్నః పింఛైరలకవేషకృత్ ।"
      },
      {
        title: "వన్యాశనప్రియః శృంగరవాకారితవత్సకః ॥ 48 ॥"
      },
      {
        title: ""
      },
      {
        title: "మనోజ్ఞపల్లవోత్తంసపుష్పస్వేచ్ఛాత్తషట్పదః ।"
      },
      {
        title: "మంజుశింజితమంజీరచరణః కరకంకణః ॥ 49 ॥"
      },
      {
        title: ""
      },
      {
        title: "అన్యోన్యశాసనః క్రీడాపటుః పరమకైతవః ।"
      },
      {
        title: "ప్రతిధ్వానప్రముదితః శాఖాచతురచంక్రమః ॥ 50 ॥"
      },
      {
        title: ""
      },
      {
        title: "అఘదానవసంహర్తా వ్రజవిఘ్నవినాశనః ।"
      },
      {
        title: "వ్రజసంజీవనః శ్రేయోనిధిర్దానవముక్తిదః ॥ 51 ॥"
      },
      {
        title: ""
      },
      {
        title: "కాలిందీపులినాసీనస్సహభుక్తవ్రజార్భకః ।"
      },
      {
        title: "కక్షాజఠరవిన్యస్తవేణుర్వల్లవచేష్టితః ॥ 52 ॥"
      },
      {
        title: ""
      },
      {
        title: "భుజసంధ్యంతరన్యస్తశృంగవేత్రః శుచిస్మితః ।"
      },
      {
        title: "వామపాణిస్థదధ్యన్నకబలః కలభాషణః ॥ 53 ॥"
      },
      {
        title: ""
      },
      {
        title: "అంగుల్యంతరవిన్యస్తఫలః పరమపావనః ।"
      },
      {
        title: "అదృశ్యతర్ణకాన్వేషీ వల్లవార్భకభీతిహా ॥ 54 ॥"
      },
      {
        title: ""
      },
      {
        title: "అదృష్టవత్సపవ్రాతో బ్రహ్మవిజ్ఞాతవైభవః ।"
      },
      {
        title: "గోవత్సవత్సపాన్వేషీ విరాట్-పురుషవిగ్రహః ॥ 55 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్వసంకల్పానురూపార్థో వత్సవత్సపరూపధృక్ ।"
      },
      {
        title: "యథావత్సక్రియారూపో యథాస్థాననివేశనః ॥ 56 ॥"
      },
      {
        title: ""
      },
      {
        title: "యథావ్రజార్భకాకారో గోగోపీస్తన్యపస్సుఖీ ।"
      },
      {
        title: "చిరాద్వలోహితో దాంతో బ్రహ్మవిజ్ఞాతవైభవః ॥ 57 ॥"
      },
      {
        title: ""
      },
      {
        title: "విచిత్రశక్తిర్వ్యాలీనసృష్టగోవత్సవత్సపః ।"
      },
      {
        title: "బ్రహ్మత్రపాకరో ధాతృస్తుతస్సర్వార్థసాధకః ॥ 58 ॥"
      },
      {
        title: ""
      },

      {
        title: "బ్రహ్మ బ్రహ్మమయోఽవ్యక్తస్తేజోరూపస్సుఖాత్మకః ।"
      },
      {
        title: "నిరుక్తం వ్యాకృతిర్వ్యక్తో నిరాలంబనభావనః ॥ 59 ॥"
      },
      {
        title: ""
      },
      {
        title: "ప్రభవిష్ణురతంత్రీకో దేవపక్షార్థరూపధృక్ ।"
      },
      {
        title: "అకామస్సర్వవేదాదిరణీయస్థూలరూపవాన్ ॥ 60 ॥"
      },
      {
        title: ""
      },

      {
        title: "వ్యాపీ వ్యాప్యః కృపాకర్తా విచిత్రాచారసమ్మతః ।"
      },
      {
        title: "ఛందోమయః ప్రధానాత్మా మూర్తామూర్తిద్వయాకృతిః ॥ 61 ॥"
      },
      {
        title: ""
      },
      {
        title: "అనేకమూర్తిరక్రోధః పరః ప్రకృతిరక్రమః ।"
      },
      {
        title: "సకలావరణోపేతస్సర్వదేవో మహేశ్వరః ॥ 62 ॥"
      },
      {
        title: ""
      },

      {
        title: "మహాప్రభావనః పూర్వవత్సవత్సపదర్శకః ।"
      },
      {
        title: "కృష్ణయాదవగోపాలో గోపాలోకనహర్షితః ॥ 63 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్మితేక్షాహర్షితబ్రహ్మా భక్తవత్సలవాక్ప్రియః ।"
      },
      {
        title: "బ్రహ్మానందాశ్రుధౌతాంఘ్రిర్లీలావైచిత్ర్యకోవిదః ॥ 64 ॥"
      },
      {
        title: ""
      },
      {
        title: "బలభద్రైకహృదయో నామాకారితగోకులః ।"
      },
      {
        title: "గోపాలబాలకో భవ్యో రజ్జుయజ్ఞోపవీతవాన్ ॥ 65 ॥"
      },
      {
        title: ""
      },
      {
        title: "వృక్షచ్ఛాయాహతాశాంతిర్గోపోత్సంగోపబర్హణః ।"
      },
      {
        title: "గోపసంవాహితపదో గోపవ్యజనవీజితః ॥ 66।"
      },
      {
        title: ""
      },
      {
        title: "గోపగానసుఖోన్నిద్రః శ్రీదామార్జితసౌహృదః ।"
      },
      {
        title: "సునందసుహృదేకాత్మా సుబలప్రాణరంజనః ॥ 67 ॥"
      },
      {
        title: ""
      },
      {
        title: "తాలీవనకృతక్రీడో బలపాతితధేనుకః ।"
      },
      {
        title: "గోపీసౌభాగ్యసంభావ్యో గోధూలిచ్ఛురితాలకః ॥ 68 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోపీవిరహసంతప్తో గోపికాకృతమజ్జనః ।"
      },
      {
        title: "ప్రలంబబాహురుత్ఫుల్లపుండరీకావతంసకః ॥ 69 ॥"
      },
      {
        title: ""
      },
      {
        title: "విలాసలలితస్మేరగర్భలీలావలోకనః ।"
      },
      {
        title: "స్రగ్భూషణానులేపాఢ్యో జనన్యుపహృతాన్నభుక్ ॥ 70 ॥"
      },
      {
        title: ""
      },
      {
        title: "వరశయ్యాశయో రాధాప్రేమసల్లాపనిర్వృతః ।"
      },
      {
        title: "యమునాతటసంచారీ విషార్తవ్రజహర్షదః ॥ 71 ॥"
      },
      {
        title: ""
      },
      {
        title: "కాలియక్రోధజనకః వృద్ధాహికులవేష్టితః ।"
      },
      {
        title: "కాలియాహిఫణారంగనటః కాలియమర్దనః ॥ 72 ॥"
      },
      {
        title: ""
      },
      {
        title: "నాగపత్నీస్తుతిప్రీతో నానావేషసమృద్ధికృత్ ।"
      },
      {
        title: "అవిష్వక్తదృగాత్మేశః స్వదృగాత్మస్తుతిప్రియః ॥ 73 ॥"
      },
      {
        title: ""
      },
      {
        title: "సర్వేశ్వరస్సర్వగుణః ప్రసిద్ధస్సర్వసాత్వతః ।"
      },
      {
        title: "అకుంఠధామా చంద్రార్కదృష్టిరాకాశనిర్మలః ॥ 74 ॥"
      },
      {
        title: ""
      },

      {
        title: "అనిర్దేశ్యగతిర్నాగవనితాపతిభైక్షదః ।"
      },
      {
        title: "స్వాంఘ్రిముద్రాంకనాగేంద్రమూర్ధా కాలియసంస్తుతః ॥ 75 ॥"
      },
      {
        title: ""
      },
      {
        title: "అభయో విశ్వతశ్చక్షుః స్తుతోత్తమగుణః ప్రభుః ।"
      },
      {
        title: "అహమాత్మా మరుత్ప్రాణః పరమాత్మా ద్యుశీర్షవాన్ ॥ 76 ॥"
      },
      {
        title: ""
      },

      {
        title: "నాగోపాయనహృష్టాత్మా హ్రదోత్సారితకాలియః ।"
      },
      {
        title: "బలభద్రసుఖాలాపో గోపాలింగననిర్వృతః ॥ 77 ॥"
      },
      {
        title: ""
      },
      {
        title: "దావాగ్నిభీతగోపాలగోప్తా దావాగ్నినాశనః ।"
      },
      {
        title: "నయనాచ్ఛాదనక్రీడాలంపటో నృపచేష్టితః ॥ 78 ॥"
      },
      {
        title: ""
      },

      {
        title: "కాకపక్షధరస్సౌమ్యో బలవాహకకేలిమాన్ ।"
      },
      {
        title: "బలఘాతితదుర్ధర్షప్రలంబో బలవత్సలః ॥ 79 ॥"
      },
      {
        title: ""
      },
      {
        title: "ముంజాటవ్యగ్నిశమనః ప్రావృట్కాలవినోదవాన్ ।"
      },
      {
        title: "శిలాన్యస్తాన్నభృద్దైత్యసంహర్తా శాద్వలాసనః ॥ 80 ॥"
      },
      {
        title: ""
      },
      {
        title: "సదాప్తగోపికోద్గీతః కర్ణికారావతంసకః ।"
      },
      {
        title: "నటవేషధరః పద్మమాలాంకో గోపికావృతః ॥ 81 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోపీమనోహరాపాంగో వేణువాదనతత్పరః ।"
      },
      {
        title: "విన్యస్తవదనాంభోజశ్చారుశబ్దకృతాననః ॥ 82 ॥"
      },
      {
        title: ""
      },
      {
        title: "బింబాధరార్పితోదారవేణుర్విశ్వవిమోహనః ।"
      },
      {
        title: "వ్రజసంవర్ణితశ్రావ్యవేణునాదః శ్రుతిప్రియః ॥ 83 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోగోపగోపీజన్మేప్సుర్బ్రహ్మేంద్రాద్యభివందితః ।"
      },
      {
        title: "గీతస్నుతిసరిత్పూరో నాదనర్తితబర్హిణః ॥ 84 ॥"
      },
      {
        title: ""
      },
      {
        title: "రాగపల్లవితస్థాణుర్గీతానమితపాదపః ।"
      },
      {
        title: "విస్మారితతృణగ్రాసమృగో మృగవిలోభితః ॥ 85 ॥"
      },
      {
        title: ""
      },
      {
        title: "వ్యాఘ్రాదిహింస్రసహజవైరహర్తా సుగాయనః ।"
      },
      {
        title: "గాఢోదీరితగోబృందప్రేమోత్కర్ణితతర్ణకః ॥ 86 ॥"
      },
      {
        title: ""
      },
      {
        title: "నిష్పందయానబ్రహ్మాదివీక్షితో విశ్వవందితః ।"
      },
      {
        title: "శాఖోత్కర్ణశకుంతౌఘశ్ఛత్రాయితబలాహకః ॥ 87 ॥"
      },
      {
        title: ""
      },
      {
        title: "ప్రసన్నః పరమానందశ్చిత్రాయితచరాచరః ।"
      },
      {
        title: "గోపికామదనో గోపీకుచకుంకుమముద్రితః ॥ 88 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోపికన్యాజలక్రీడాహృష్టో గోప్యంశుకాపహృత్ ।"
      },
      {
        title: "స్కంధారోపితగోపస్త్రీవాసాః కుందనిభస్మితః ॥ 89 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోపీనేత్రోత్పలశశీ గోపికాయాచితాంశుకః ।"
      },
      {
        title: "గోపీనమస్క్రియాదేష్టా గోప్యేకకరవందితః ॥ 90 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోప్యంజలివిశేషార్థీ గోపక్రీడావిలోభితః ।"
      },
      {
        title: "శాంతవాసస్ఫురద్గోపీకృతాంజలిరఘాపహః ॥ 91 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోపీకేలివిలాసార్థీ గోపీసంపూర్ణకామదః ।"
      },
      {
        title: "గోపస్త్రీవస్త్రదో గోపీచిత్తచోరః కుతూహలీ ॥ 92 ॥"
      },
      {
        title: ""
      },
      {
        title: "బృందావనప్రియో గోపబంధుర్యజ్వాన్నయాచితా ।"
      },
      {
        title: "యజ్ఞేశో యజ్ఞభావజ్ఞో యజ్ఞపత్న్యభివాంఛితః ॥ 93 ॥"
      },
      {
        title: ""
      },
      {
        title: "మునిపత్నీవితీర్ణాన్నతృప్తో మునివధూప్రియః ।"
      },
      {
        title: "ద్విజపత్న్యభిభావజ్ఞో ద్విజపత్నీవరప్రదః ॥ 94 ॥"
      },
      {
        title: ""
      },
      {
        title: "ప్రతిరుద్ధసతీమోక్షప్రదో ద్విజవిమోహితా ।"
      },
      {
        title: "మునిజ్ఞానప్రదో యజ్వస్తుతో వాసవయాగవిత్ ॥ 95 ॥"
      },
      {
        title: ""
      },
      {
        title: "పితృప్రోక్తక్రియారూపశక్రయాగనివారణః ।"
      },
      {
        title: "శక్రాఽమర్షకరశ్శక్రవృష్టిప్రశమనోన్ముఖః ॥ 96 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోవర్ధనధరో గోపగోబృందత్రాణతత్పరః ।"
      },
      {
        title: "గోవర్ధనగిరిచ్ఛత్రచండదండభుజార్గలః ॥ 97 ॥"
      },
      {
        title: ""
      },
      {
        title: "సప్తాహవిధృతాద్రీంద్రో మేఘవాహనగర్వహా ।"
      },
      {
        title: "భుజాగ్రోపరివిన్యస్తక్ష్మాధరక్ష్మాభృదచ్యుతః ॥ 98 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్వస్థానస్థాపితగిరిర్గోపీదధ్యక్షతార్చితః ।"
      },
      {
        title: "సుమనస్సుమనోవృష్టిహృష్టో వాసవవందితః ॥ 99 ॥"
      },
      {
        title: ""
      },
      {
        title: "కామధేనుపయఃపూరాభిషిక్తస్సురభిస్తుతః ।"
      },
      {
        title: "ధరాంఘ్రిరోషధీరోమా ధర్మగోప్తా మనోమయః ॥ 100 ॥"
      },
      {
        title: ""
      },
      {
        title: "జ్ఞానయజ్ఞప్రియశ్శాస్త్రనేత్రస్సర్వార్థసారథిః ।"
      },
      {
        title: "ఐరావతకరానీతవియద్గంగాప్లుతో విభుః ॥ 101 ॥"
      },
      {
        title: ""
      },
      {
        title: "బ్రహ్మాభిషిక్తో గోగోప్తా సర్వలోకశుభంకరః ।"
      },
      {
        title: "సర్వవేదమయో మగ్ననందాన్వేషిపితృప్రియః ॥ 102 ॥"
      },
      {
        title: ""
      },
      {
        title: "వరుణోదీరితాత్మేక్షాకౌతుకో వరుణార్చితః ।"
      },
      {
        title: "వరుణానీతజనకో గోపజ్ఞాతాత్మవైభవః ॥ 103 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్వర్లోకాలోకసంహృష్టగోపవర్గత్రివర్గదః ।"
      },
      {
        title: "బ్రహ్మహృద్గోపితో గోపద్రష్టా బ్రహ్మపదప్రదః ॥ 104 ॥"
      },
      {
        title: ""
      },
      {
        title: "శరచ్చంద్రవిహారోత్కః శ్రీపతిర్వశకో క్షమః ।"
      },
      {
        title: "భయాపహో భర్తృరుద్ధగోపికాధ్యానగోచరః ॥ 105 ॥"
      },
      {
        title: ""
      },
      {
        title: "గోపికానయనాస్వాద్యో గోపీనర్మోక్తినిర్వృతః ।"
      },
      {
        title: "గోపికామానహరణో గోపికాశతయూథపః ॥ 106 ॥"
      },
      {
        title: ""
      },
      {
        title: "వైజయంతీస్రగాకల్పో గోపికామానవర్ధనః ।"
      },
      {
        title: "గోపకాంతాసునిర్దేష్టా కాంతో మన్మథమన్మథః ॥ 107 ॥"
      },
      {
        title: ""
      },
      {
        title: "స్వాత్మాస్యదత్తతాంబూలః ఫలితోత్కృష్టయౌవనః ।"
      },
      {
        title: "వల్లవీస్తనసక్తాక్షో వల్లవీప్రేమచాలితః ॥ 108 ॥"
      },
    ],
    title: "శ్రీ కృష్ణ సహస్ర నామ స్తోత్రం",
    // img: require('../../Utils/LoadIcons/22.png'),
  },
]
export default krishnaslokas;