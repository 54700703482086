import React, { useState } from 'react'
import '../assets/css/styles.css' 
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-light bg-lightbrown">
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={toggleNav}
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}>
                            <ul className="navbar-nav ">
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink4"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        భక్తిరంజిని
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink4">
                                        < NavLink to="/Ashtotharalu" className="dropdown-item" activeClassName="active">అష్టోత్తరాలు</NavLink>
                                        < NavLink to="/Slokalu" className="dropdown-item" activeClassName="active">శ్లోకాలు</NavLink>
                                        < NavLink to="/Shotramlu" className="dropdown-item" activeClassName="active" >స్తోత్రములు</NavLink>
                                        < NavLink to="/Thidra-Yatra" className="dropdown-item" activeClassName="active">తీర్థ యాత్ర</NavLink>
                                        < NavLink to="/Athyadhimi-Kashakthi" className="dropdown-item" activeClassName="active">ఆధ్యాత్మిక శక్తి</NavLink>
                                        < NavLink to="/Upavasam" className="dropdown-item" activeClassName="active">ఉపవాసం</NavLink>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink5"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        నక్షత్ర పొంతన
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink5">
                                        < NavLink to="/Muhurthalu" className="dropdown-item" activeClassName="active">ముహూర్తాలు</NavLink>
                                        < NavLink to="/Vivaha-Nakshatralu" className="dropdown-item" activeClassName="active">వివాహ నక్షత్రాలు</NavLink>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink6"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        హిందూ ధర్మ వైభవం
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink6">
                                        < NavLink to="/Dharmasandehalu" className="dropdown-item" activeClassName="active" >ధర్మసందేహాలు</NavLink>
                                        < NavLink to="/Hindu-Darmashastram" className="dropdown-item" activeClassName="active">హిందూ ధర్మశాస్త్రం</NavLink>
                                        {/* < Link to="/Pandugapramukyata" className="dropdown-item" >పండుగ ప్రాముఖ్యత</Link> */}
                                        < NavLink to="/Masa-Vishistatha" className="dropdown-item" activeClassName="active">మాస విశిష్టత</NavLink>
                                        < NavLink to="/Srusti-Rahasyalu" className="dropdown-item" activeClassName="active" >సృష్టి రహస్యాలు</NavLink>
                                        < NavLink to="/Jeevanashaili" className="dropdown-item" activeClassName="active">జీవనశైలి</NavLink>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink7"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        ఆస్ట్రాలజీ
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink7">
                                        < NavLink to="/Sankya-Shastram" className="dropdown-item" activeClassName="active">సంఖ్య శాస్త్రం</NavLink>
                                        < NavLink to="/Grahalu" className="dropdown-item" activeClassName="active">గ్రహాలు</NavLink>
                                        < NavLink to="/Bhavishyavani" className="dropdown-item" activeClassName="active">భవిష్యవాణి</NavLink>
                                        < NavLink to="/Vasthu-Chitkalu" className="dropdown-item" activeClassName="active">వాస్తు చిట్కాలు</NavLink>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink8"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        శకున శాస్త్రం
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink8">
                                        < NavLink to="/Balli-Shastram" className="dropdown-item" activeClassName="active">బల్లి శాస్త్రం</NavLink>
                                        {/* < Link to="/pillishastram" className="dropdown-item" >పిల్లి శాస్త్రం</Link> */}
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink9"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        విలువైన సమాచారం
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink9">
                                        < NavLink to="/Villuvina-Samacharam" className="dropdown-item" activeClassName="active">విలువైన సమాచారం</NavLink>
                                        < NavLink to="/Nithya-Sathyalu" className="dropdown-item"activeClassName="active" >నిత్య సత్యాలు</NavLink>
                                        < NavLink to="/Nithi-Padhyalu" className="dropdown-item" activeClassName="active">నీతి పద్యాలు</NavLink>
                                        < NavLink to="/Samethalu" className="dropdown-item" activeClassName="active">సామెతలు</NavLink>
                                        < NavLink to="/Samskrutham" className="dropdown-item" activeClassName="active">సంస్కృతం</NavLink>
                                        < NavLink to="/Rastrala-Bashalu" className="dropdown-item" activeClassName="active">రాష్ట్రాల భాషలు</NavLink>
                                        < NavLink to="/Grahanam" className="dropdown-item" activeClassName="active">గ్రహణం</NavLink>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink10"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        ఇతరములు
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink10">
                                        < NavLink to="/Telugu-Samskrutham" className="dropdown-item" activeClassName="active">తెలుగు సంస్కృతం</NavLink>
                                        < NavLink to="/Telugu-Panchangam" className="dropdown-item" activeClassName="active">పంచాంగం</NavLink>
                                        < NavLink to="/Sankya-Pradanyata" className="dropdown-item" activeClassName="active">సంఖ్య ప్రాధాన్యత</NavLink>
                                        < NavLink to="/Namakaranam" className="dropdown-item" activeClassName="active">నామకరణం</NavLink>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/Ramayanam" 
                                        activeClassName="active"
                                    >
                                        రామాయణం
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink10"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        మహాభారతం
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink10">
                                        < NavLink to="/Adhi-Parvam" className="dropdown-item" activeClassName="active">ఆది పర్వము</NavLink>
                                        < NavLink to="/Sabha-Parvam" className="dropdown-item" activeClassName="active">సభా పర్వము</NavLink>
                                        < NavLink to="/Vana-Parvam" className="dropdown-item" activeClassName="active">వన పర్వము</NavLink>
                                        < NavLink to="/Virata-Parvam" className="dropdown-item" activeClassName="active">విరాట పర్వము</NavLink>
                                        < NavLink to="/Vudhyoga-Parvam" className="dropdown-item" activeClassName="active">ఉద్యోగ పర్వము</NavLink>
                                        < NavLink to="/Bhishma-Parvam" className="dropdown-item" activeClassName="active">భీష్మ పర్వము</NavLink>
                                        < NavLink to="/Dhrona-Parvam" className="dropdown-item" activeClassName="active">ద్రోణ పర్వము</NavLink>
                                        < NavLink to="/Karna-Parvam" className="dropdown-item" activeClassName="active">కర్ణ పర్వము</NavLink>
                                        < NavLink to="/Salya-Parvam" className="dropdown-item" activeClassName="active">శల్య పర్వము</NavLink>
                                        < NavLink to="/Swothika-Parvam" className="dropdown-item" activeClassName="active">సౌప్తిక పర్వము</NavLink>
                                        < NavLink to="/Sri-Parvam" className="dropdown-item" activeClassName="active">స్త్రీ పర్వము</NavLink>
                                        < NavLink to="/Santhi-Parvam" className="dropdown-item" activeClassName="active">శాంతి పర్వము</NavLink>
                                        < NavLink to="/Anusasanika-Parvam" className="dropdown-item" activeClassName="active">అనుశాసనిక పర్వము</NavLink>
                                        < NavLink to="/Aswamedha-Parvam" className="dropdown-item" activeClassName="active">అశ్వమేధ పర్వము</NavLink>
                                        < NavLink to="/Asramavasa-Parvam" className="dropdown-item" activeClassName="active">ఆశ్రమవాస పర్వము</NavLink>
                                        < NavLink to="/Mousala-Parvam" className="dropdown-item" activeClassName="active">మౌసల పర్వము</NavLink>
                                        < NavLink to="/Mahaprasthanika-Parvam" className="dropdown-item" activeClassName="active">మహాప్రస్థానిక పర్వము</NavLink>
                                        < NavLink to="/Swargarohana-Parvam" className="dropdown-item" activeClassName="active">స్వర్గారోహణ పర్వము</NavLink>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdownMenuLink10"
                                        role="button"
                                        onClick={toggleNav}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        activeClassName="active"
                                    >
                                        భగవద్గీత
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink10">
                                        < NavLink to="/Krishnani-Jananam" className="dropdown-item" activeClassName="active">శ్రీకృష్ణుని జీవితం</NavLink>
                                        < NavLink to="/Pandugala-Visistatha" className="dropdown-item" activeClassName="active">పండుగలు విశిష్టత</NavLink>
                                        < NavLink to="/Geetha-Saramsam" className="dropdown-item" activeClassName="active">గీతా సారాంశం</NavLink>
                                        < NavLink to="/Bhagavathgita" className="dropdown-item" activeClassName="active">భగవద్గీత</NavLink>
                                        < NavLink to="/Pandugaluu" className="dropdown-item" activeClassName="active">పండుగలు</NavLink>
                                        < NavLink to="/Sri-Krishna-Astrotharalu" className="dropdown-item" activeClassName="active">శ్రీ కృష్ణ అష్టోత్తరము</NavLink>
                                        < NavLink to="/Sri-Krishnastakam" className="dropdown-item" activeClassName="active">శ్రీ కృష్ణాష్టకం</NavLink>
                                        < NavLink to="/Bhakthi-Sukthulu" className="dropdown-item" activeClassName="active">భగవద్గీత సుక్తులు</NavLink>
                                        < NavLink to="/Vasthavalu" className="dropdown-item" activeClassName="active">వాస్తవాలు</NavLink>

                                    </div>
                                </li>
                            {/* <li className="nav-item"><NavLink className="nav-link" to='/Karthikapuranam' activeClassName="active">కార్తీకపురాణం</NavLink></li>
                            <li className="nav-item"><NavLink className="nav-link" to='/Astakalu' activeClassName="active">అష్టకాలు</NavLink></li>
                            <li className="nav-item"><NavLink className="nav-link" to='/Villuvinasamacharam' activeClassName="active">ముఖ్యమైన సమాచారం</NavLink></li> */}

                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Navbar