import React, { useState } from 'react';
import krishnaslokas from '../../assets/jsons/Telugu/krishnaslokas';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';

const Srikrishnastakam = () => {
  // Initialize with the first sloka and set the activeKey to 0
  const [selectedSloka, setSelectedSloka] = useState(krishnaslokas[0]);
  const [activeKey, setActiveKey] = useState(0);

  const handleClick = (sloka, index) => {
    setSelectedSloka(sloka);
    setActiveKey(index);
  };
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <div>
        <Header />
        <Navbar />
        <div className="container-fluid" style={{ background: '#ffe6d0' }}>
          <div className="row">
            <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
            <div
              className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
              onMouseEnter={() => {
                setLeftSticky(false);
                setRightSticky(true);
              }}
              style={{
                overflowY: leftSticky ? 'hidden' : 'auto',
                height: '80vh',
                position: leftSticky ? 'sticky' : 'static',
                top: 0,
              }}
            >
              <div>
                {krishnaslokas.map((sloka, index) => (
                  <div
                    key={index}
                    onClick={() => handleClick(sloka, index)}
                    className="card-header card mb-3"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: activeKey === index ? '#B35C0C' : '#754213',
                    }}
                  >
                    <img src={sloka.img} alt={sloka.title} style={{ width: '100%' }} />
                    <h5>{sloka.title}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
              onMouseEnter={() => {
                setLeftSticky(true);
                setRightSticky(false);
              }}
              style={{
                overflowY: rightSticky ? 'hidden' : 'auto',
                height: '80vh',
                position: rightSticky ? 'sticky' : 'static',
                top: 0,
              }}
            >
              <div>
                {selectedSloka && (
                  <div className="card-body rightPanel">
                    <h5>{selectedSloka.title}</h5>
                    {selectedSloka.slokalu.map((line, idx) => (
                      <div key={idx}>
                        <ul>{line.title}</ul>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Srikrishnastakam;
