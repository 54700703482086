import React, { useState } from 'react'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import sideGridRightImage from '../../assets/images/sideGridRight.png';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Samethalu = () => {
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  return (

    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
              transform: 'rotateY(180deg)',
            }}
          >
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="row">
              <div style={{ textAlign: 'center' }}>
                <div className="card-body rightPanel">
                  <h5>{ItaramuluData.samathalu.title}</h5>
                  {ItaramuluData.samathalu.CatID.map((item, index) => (
                    <div key={index}>{item.title}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 p-3"></div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Samethalu