export const LocalData = {
  // EventHolidayUpdated: [
  //   [
  //     {
  //       date: "2024-01-01",
  //       title: "ఆంగ్ల సంవత్సరాది",
  //     },
  //     {
  //       date: "2024-01-14",
  //       title: "భోగి",
  //     },
  //     {
  //       date: "2024-01-15",
  //       title: "మకర సంక్రాంతి",
  //     },
  //     {
  //       date: "2024-01-16",
  //       title: "కనుమ",
  //     },
  //     {
  //       date: "2024-01-26",
  //       title: "గణతంత్ర దినోత్సవం",
  //     },
  //     {
  //       date: "2024-03-08",
  //       title: "మహాశివరాత్రి",
  //     },
  //     {
  //       date: "2024-03-25",
  //       title: "హోళీ",
  //     },
  //     {
  //       date: "2024-03-29",
  //       title: "గుడ్ ఫ్రైడే",
  //     },
  //     {
  //       date: "2024-04-05",
  //       title: "బాబూ జగజ్జీవన్ రామ్ జయంతి",
  //     },
  //     {
  //       date: "2024-04-09",
  //       title: "శ్రీ క్రోధి నామ సంవత్సరం, ఉగాది",
  //     },
  //     {
  //       date: "2024-04-10",
  //       title: "రంజాన్, ఈద్ అల్ ఫితర్",
  //     },
  //     {
  //       date: "2024-04-14",
  //       title: "అంబేడ్కర్ జయంతి",
  //     },
  //     {
  //       date: "2024-04-17",
  //       title: "శ్రీరామ నవమి",
  //     },
  //     {
  //       date: "2024-05-01",
  //       title: "కార్మికుల దినోత్సవం",
  //     },
  //     {
  //       date: "2024-06-17",
  //       title: "బక్రీదు",
  //     },
  //     {
  //       date: "2024-07-17",
  //       title: "మొహర్రం",
  //     },
  //     {
  //       date: "2024-08-15",
  //       title: "భారత స్వాతంత్య్ర దినోత్సవం",
  //     },
  //     {
  //       date: "2024-08-19",
  //       title: "రక్షా బంధన్",
  //     },
  //     {
  //       date: "2024-08-26",
  //       title: "శ్రీ కృష్ణ జన్మాష్టమి",
  //     },
  //     {
  //       date: "2024-09-05",
  //       title: "ఉపాధ్యాయుల దినోత్సవం",
  //     },
  //     {
  //       date: "2024-09-07",
  //       title: "సిద్ధి వినాయక చవితి",
  //     },
  //     {
  //       date: "2024-09-16",
  //       title: "మిలాద్ ఉన్ నబీ",
  //     },
  //     {
  //       date: "2024-10-02",
  //       title: "గాంధీ జయంతి",
  //     },
  //     {
  //       date: "2024-10-11",
  //       title: "దుర్గాష్టమి",
  //     },
  //     {
  //       date: "2024-10-12",
  //       title: "మహా నవమి, విజయ దశమి",
  //     },
  //     {
  //       date: "2024-11-01",
  //       title: "దీపావళి",
  //     },
  //     {
  //       date: "2024-11-14",
  //       title: "నెహ్రూ జయంతి, బాలల దినోత్సవం",
  //     },
  //     {
  //       date: "2024-12-25",
  //       title: "క్రిస్టమస్",
  //     }
  //   ],
  //   [
  //     {
  //       date: "2025-01-01",
  //       title: "ఆంగ్ల సంవత్సరాది",
  //     },
  //     {
  //       date: "2025-01-14",
  //       title: "భోగి",
  //     },
  //     {
  //       date: "2025-01-15",
  //       title: "మకర సంక్రాంతి",
  //     },
  //     {
  //       date: "2025-01-16",
  //       title: "కనుమ",
  //     },
  //     {
  //       date: "2025-01-26",
  //       title: "గణతంత్ర దినోత్సవం",
  //     },
  //     {
  //       date: "2025-02-26",
  //       title: "మహాశివరాత్రి",
  //     },
  //     {
  //       date: "2025-03-08",
  //       title: "అంతర్జాతీయ మహిళా దినోత్సవం",
  //     },
  //     {
  //       date: "2025-03-14",
  //       title: "హోళీ",
  //     },
  //     {
  //       date: "2025-03-30",
  //       title: "ఉగాది, శ్రీ విశ్వావసు నామ సంవత్సరం",
  //     },
  //     {
  //       date: "2025-04-01",
  //       title: "రంజాన్, ఈద్ అల్ ఫితర్",
  //     },
  //     {
  //       date: "2025-04-05",
  //       title: "బాబూ జగజ్జీవన్ రామ్ జయంతి",
  //     },
  //     {
  //       date: "2025-04-06",
  //       title: "శ్రీరామ నవమి",
  //     },
  //     {
  //       date: "2025-04-14",
  //       title: "అంబేడ్కర్ జయంతి",
  //     },
  //     {
  //       date: "2025-04-18",
  //       title: "గుడ్ ఫ్రైడే",
  //     },
  //     {
  //       date: "2025-05-01",
  //       title: "కార్మికుల దినోత్సవం",
  //     },
  //     {
  //       date: "2025-06-07",
  //       title: "బక్రీద్",
  //     },
  //     {
  //       date: "2025-07-06",
  //       title: "మొహర్రం",
  //     },
  //     {
  //       date: "2025-08-09",
  //       title: "రక్షా బంధన్",
  //     },
  //     {
  //       date: "2025-08-15",
  //       title: "భారత స్వాతంత్య్ర దినోత్సవం",
  //     },
  //     {
  //       date: "2025-08-16",
  //       title: "శ్రీ కృష్ణ జన్మాష్టమి",
  //     },
  //     {
  //       date: "2025-08-27",
  //       title: "సిద్ధి వినాయక చవితి",
  //     },
  //     {
  //       date: "2025-09-04",
  //       title: "మిలాదుక్ నాభి",
  //     },
  //     {
  //       date: "2025-09-05",
  //       title: "ఉపాధ్యాయుల దినోత్సవం",
  //     },
  //     {
  //       date: "2025-09-30",
  //       title: "దుర్గాష్టమి",
  //     },
  //     {
  //       date: "2025-10-01",
  //       title: "మహా నవమి, విజయ దశమి",
  //     },
  //     {
  //       date: "2025-10-02",
  //       title: "గాంధీ జయంతి",
  //     },
  //     {
  //       date: "2025-10-20",
  //       title: "దీపావళి",
  //     },
  //     {
  //       date: "2025-11-14",
  //       title: "నెహ్రూ జయంతి, బాలల దినోత్సవం",
  //     },
  //     {
  //       date: "2025-12-25",
  //       title: "క్రిస్టమస్",
  //     },
  //   ],
  // ],
  FestivalUpdated: [
    [
      {
        day: [
          {
            Date: "సోమవారము, 01 జనవరి 2024",
            Festival: "ఆంగ్ల సంవత్సరాది",
          },
          {
            Date: "మంగళవారము, 02 జనవరి 2024",
            Festival: "శూన్య తిథి",
          },
          {
            Date: "బుధవారము, 10 జనవరి 2024",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "శుక్రవారము, 12 జనవరి 2024",
            Festival: "నేషనల్ యూత్ డే, స్వామి వివేకానంద జయంతి",
          },
          {
            Date: "శనివారము, 13 జనవరి 2024",
            Festival: "తిథిద్వయం(విదియ+తదియ)",
          },
          {
            Date: "ఆదివారము, 14 జనవరి 2024",
            Festival: "భోగి",
          },
          {
            Date: "సోమవారము, 15 జనవరి 2024",
            Festival: "మకర సంక్రాంతి, సంక్రాంతి పండుగ, ఉత్తరాయణం ప్రారంభం",
          },
          {
            Date: "మంగళవారము, 16 జనవరి 2024",
            Festival: "కనుమ పండుగ",
          },
          {
            Date: "బుధవారము, 17 జనవరి 2024",
            Festival: "ముక్కనుమ, సావిత్రి గౌరీవ్రతం, బొమ్మలనోము ప్రారంభం",
          },
          {
            Date: "మంగళవారము, 23 జనవరి 2024",
            Festival: "నేతాజీ జయంతి",
          },
          {
            Date: "శుక్రవారము, 26 జనవరి 2024",
            Festival: "రిపబ్లిక్ డే",
          },
          {
            Date: "సోమవారము, 29 జనవరి 2024",
            Festival: "సంకష్టహర చతుర్థి",
          },
          {
            Date: "మంగళవారము, 30 జనవరి 2024",
            Festival: "మహాత్మాగాంధీ వర్ధంతి",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 4 ఫిబ్రవరి 2024",
            Festival: "ప్రపంచ క్యాన్సర్ రోజు",
          },
          {
            Date: "గురువారము, 8 ఫిబ్రవరి 2024",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "శుక్రవారము, 9 ఫిబ్రవరి 2024",
            Festival: "చొల్లంగి అమావాస్య, శూన్య తిథి",
          },
          {
            Date: "మంగళవారము, 13 ఫిబ్రవరి 2024",
            Festival: "కుంభ సంక్రమణం",
          },
          {
            Date: "బుధవారము, 14 ఫిబ్రవరి 2024",
            Festival: "శ్రీ పంచమి, మదన పంచమి, ప్రేమికుల రోజు",
          },
          {
            Date: "శుక్రవారము, 16 ఫిబ్రవరి 2024",
            Festival: "రధసప్తమి, సూర్య జయంతి, తిథిద్వయం(సప్తమి+అష్టమి)",
          },
          {
            Date: "శనివారము, 17 ఫిబ్రవరి 2024",
            Festival: "భీష్మాష్టమి, నవమి తిథి",
          },
          {
            Date: "ఆదివారము, 18 ఫిబ్రవరి 2024",
            Festival: "దశమి తిథి",
          },
          {
            Date: "శనివారము, 24 ఫిబ్రవరి 2024",
            Festival: "మాఘ పౌర్ణమి",
          },
          {
            Date: "బుధవారము, 28 ఫిబ్రవరి 2024",
            Festival: "సంకష్టహర చతుర్థి",
          },
        ],
      },
      {
        day: [
          {
            Date: "శుక్రవారము, 08 మార్చి 2024",
            Festival: "మహా శివరాత్రి, కోటిపల్లి తీర్ధం",
          },
          {
            Date: "సోమవారము, 11 మార్చి 2024",
            Festival: "తిథిద్వయం(పాడ్యమి+విదియ)",
          },
          {
            Date: "బుధవారము, 13 మార్చి 2024",
            Festival: "పుత్ర గణపతి వ్రతం",
          },
          {
            Date: "గురువారము, 14 మార్చి 2024",
            Festival: "మీన సంక్రమణం, శ్రీకంఠ జయంతి",
          },
          {
            Date: "శనివారము, 23 మార్చి 2024",
            Festival: "శని త్రయోదశి",
          },
          {
            Date: "సోమవారము, 25 మార్చి 2024",
            Festival: "హోళీ పండుగ, హోళికా పూర్ణిమ",
          },
          {
            Date: "మంగళవారము, 26 మార్చి 2024",
            Festival: "శూన్య తిథి",
          },
          {
            Date: "గురువారము, 28 మార్చి 2024",
            Festival: "సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 29 మార్చి 2024",
            Festival: "గుడ్ ఫ్రైడే",
          },
          {
            Date: "ఆదివారము, 31 మార్చి 2024",
            Festival: "ఈస్టర్",
          },
        ],
      },
      {
        day: [
          {
            Date: "సోమవారము, 01 ఏప్రిల్ 2024",
            Festival: "ఏప్రిల్ ఫూల్, బ్యాంకు సెలవు",
          },
          {
            Date: "బుధవారము, 03 ఏప్రిల్ 2024",
            Festival: "తిథిద్వయం(నవమి+దశమి)",
          },
          {
            Date: "శుక్రవారము, 05 ఏప్రిల్ 2024",
            Festival: "బాబూ జగజ్జీవన్ రామ్ జయంతి",
          },
          {
            Date: "ఆదివారము, 07 ఏప్రిల్ 2024",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "మంగళవారము, 09 ఏప్రిల్ 2024",
            Festival: "శ్రీ క్రోధి నామ సంవత్సరం, ఉగాది",
          },
          {
            Date: "బుధవారము, 10 ఏప్రిల్ 2024",
            Festival: "రంజాన్, ఈద్ అల్ ఫితర్",
          },
          {
            Date: "ఆదివారము, 14 ఏప్రిల్ 2024",
            Festival: "అంబేద్కర్ జయంతి",
          },
          {
            Date: "బుధవారము, 17 ఏప్రిల్ 2024",
            Festival: "శ్రీరామనవమి, సీతారామ కళ్యాణం",
          },
          {
            Date: "మంగళవారము, 23 ఏప్రిల్ 2024",
            Festival: "హనుమాన్ జయంతి",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 01 మే 2024",
            Festival: "అంతర్జాతీయ కార్మిక దినోత్సవం",
          },
          {
            Date: "మంగళవారము, 07 మే 2024",
            Festival: "రవీంద్రనాథ్ ఠాగూర్ జయంతి",
          },
          {
            Date: "శుక్రవారము, 10 మే 2024",
            Festival: "అక్షయ తదియ, సింహాచలం వరాహ లక్ష్మీనారసింహ స్వామి చందనోత్సవం, పరశురామ జయంతి",
          },
          {
            Date: "ఆదివారము, 12 మే 2024",
            Festival: "విశ్వజ్ఞాని ఆది శంకరాచార్యుల జయంతి",
          },
          {
            Date: "గురువారము, 23 మే 2024",
            Festival: "బుద్ధ పూర్ణిమ",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 05 జూన్ 2024",
            Festival: "ప్రపంచ పర్యావరణ దినోత్సవము",
          },
          {
            Date: "ఆదివారము, 16 జూన్ 2024",
            Festival: "ఫాదర్స్ డే",
          },
          {
            Date: "సోమవారము, 17 జూన్ 2024",
            Festival: "బక్రీదు",
          }
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 07 జూలై 2024",
            Festival: "జగన్నాథ్ రథయాత్ర",
          },
          {
            Date: "బుధవారము, 17 జూలై 2024",
            Festival: "తొలి ఏకాదశీ, మొహర్రం",
          },
          {
            Date: "ఆదివారము, 21 జూలై 2024",
            Festival: "గురు పూర్ణిమ",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 04 ఆగష్టు 2024",
            Festival: "స్నేహితుల దినోత్సవం",
          },
          {
            Date: "మంగళవారము, 06 ఆగష్టు 2024",
            Festival: "మంగళ గౌరీ వ్రతం",
          },
          {
            Date: "ఆదివారము, 11 ఆగస్టు 2024",
            Festival: "తులసీదాస్ జయంతి",
          },
          {
            Date: "గురువారము, 15 ఆగస్టు 2024",
            Festival: "స్వాతంత్య్రదినోత్సవం",
          },
          {
            Date: "శుక్రవారము, 16 ఆగస్టు 2024",
            Festival: "వరలక్ష్మీ వ్రతం",
          },
          {
            Date: "సోమవారము, 19 ఆగస్టు 2024",
            Festival: "రాఖీ, రక్షా బంధన్",
          },
          {
            Date: "సోమవారము, 26 ఆగస్టు 2024",
            Festival: "శ్రీ కృష్ణ జన్మాష్టమి",
          },
        ],
      },
      {
        day: [
          {
            Date: "గురువారము, 05 సెప్టెంబర్ 2024",
            Festival: "ఉపాధ్యాయుల దినోత్సవం",
          },
          {
            Date: "శనివారము, 07 సెప్టెంబర్ 2024",
            Festival: "సిద్ధి వినాయక చవితి",
          },
          {
            Date: "ఆదివారము, 15 సెప్టెంబర్ 2024",
            Festival: "ఇంజనీర్ల దినోత్సవము",
          },
          {
            Date: "సోమవారము, 16 సెప్టెంబర్ 2024",
            Festival: "మిలాద్ ఉన్ నబీ",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 02 అక్టోబర్ 2024",
            Festival: "మహ్మత్మాగాంధీ జయంతి",
          },
          {
            Date: "శుక్రవారము, 11 అక్టోబర్ 2024",
            Festival: "దుర్గ అష్టమి",
          },
          {
            Date: "శనివారము, 12 అక్టోబర్ 2024",
            Festival: "మహా నవమి, విజయ దశమి",
          },
        ],
      },
      {
        day: [
          {
            Date: "శుక్రవారము, 01 నవంబర్ 2024",
            Festival: "దీపావళి, లక్ష్మి పూజ",
          },
          {
            Date: "గురువారము, 14 నవంబర్ 2024",
            Festival: "బాలల దినోత్సవం, నెహ్రూ జయంతి",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 01 డిసెంబర్ 2024",
            Festival: "ప్రపంచ ఎయిడ్స్ దినోత్సవం",
          },
          {
            Date: "శనివారము, 07 డిసెంబర్ 2024",
            Festival: "సుబ్రహ్మణ్య షష్ఠి",
          },
          {
            Date: "బుధవారము, 25 డిసెంబర్ 2024",
            Festival: "క్రిస్మస్",
          },
          {
            Date: "గురువారము, 26 డిసెంబర్ 2024",
            Festival: "బాక్సింగ్ డే",
          },
        ],
      },
    ],
    [
      {
        day: [
          {
            Date: "బుధవారము, 01 జనవరి 2025",
            Festival: "ఆంగ్ల సంవత్సరాది",
          },
          {
            Date: "శుక్రవారము, 10 జనవరి 2025",
            Festival: "పుష్యము పుత్రాద ఏకాదశీ",
          },
          {
            Date: "శనివారము, 11 జనవరి 2025",
            Festival: "కుర్మా ద్వాదశి, ప్రదోష్ వ్రతం",
          },
          {
            Date: "ఆదివారము, 12 జనవరి 2025",
            Festival: "నేషనల్ యూత్ డే, స్వామి వివేకానంద జయంతి",
          },
          {
            Date: "మంగళవారము, 14 జనవరి 2025",
            Festival: "భోగి",
          },
          {
            Date: "బుధవారము, 15 జనవరి 2025",
            Festival: "మకర సంక్రాంతి, ఉత్తరాయణం ప్రారంభం",
          },
          {
            Date: "గురువారము, 16 జనవరి 2025",
            Festival: "కనుమ",
          },
          {
            Date: "గురువారము, 23 జనవరి 2025",
            Festival: "నేతాజీ జయంతి",
          },
          {
            Date: "శనివారము, 25 జనవరి 2025",
            Festival: "షట్తిలా ఏకాదశీ",
          },
          {
            Date: "ఆదివారము, 26 జనవరి 2025",
            Festival: "రిపబ్లిక్ డే",
          },
          {
            Date: "సోమవారము, 27 జనవరి 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
        ],
      },
      {
        day: [
          {
            Date: "మంగళవారము, 4 ఫిబ్రవరి 2025",
            Festival: "రధసప్తమి, ప్రపంచ క్యాన్సర్ రోజు",
          },
          {
            Date: "శనివారము, 8 ఫిబ్రవరి 2025",
            Festival: "జయ ఏకాదశీ",
          },
          {
            Date: "ఆదివారము, 9 ఫిబ్రవరి 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "బుధవారము, 12 ఫిబ్రవరి 2025",
            Festival: "మాఘ పౌర్ణమి, కుంభ సంక్రమణం",
          },
          {
            Date: "శుక్రవారము, 14 ఫిబ్రవరి 2025",
            Festival: "ప్రేమికుల రోజు",
          },
          {
            Date: "శనివారము, 15 ఫిబ్రవరి 2025",
            Festival: "ద్విజప్రియ సంకష్టహర చతుర్థి",
          },
          {
            Date: "సోమవారము, 24 ఫిబ్రవరి 2025",
            Festival: "విజయ ఏకాదశీ",
          },
          {
            Date: "మంగళవారము, 25 ఫిబ్రవరి 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "బుధవారము, 26 ఫిబ్రవరి 2025",
            Festival: "మహా శివరాత్రి",
          },
        ],
      },
      {
        day: [
          {
            Date: "సోమవారము, 10 మార్చి 2025",
            Festival: "ఆమలకీ ఏకాదశీ",
          },
          {
            Date: "శుక్రవారము, 14 మార్చి 2025",
            Festival: "హోళీ, అంతర్జాతీయ మహిళా దినోత్సవం",
          },
          {
            Date: "ఆదివారము, 16 మార్చి 2025",
            Festival: "పొట్టి శ్రీరాములు జయంతి",
          },
          {
            Date: "సోమవారము, 17 మార్చి 2025",
            Festival: "బాలచంద్ర సంకష్టహర చతుర్థి",
          },
          {
            Date: "మంగళవారము, 25 మార్చి 2025",
            Festival: "పాపమొచనీ ఏకాదశీ",
          },
          {
            Date: "ఆదివారము, 30 మార్చి 2025",
            Festival: "ఉగాది, శ్రీ విశ్వావసు నామ సంవత్సరం",
          },
          {
            Date: "సోమవారము, 31 మార్చి 2025",
            Festival: "గౌరీ పూజ",
          },
        ],
      },
      {
        day: [
          {
            Date: "మంగళవారము, 01 ఏప్రిల్ 2025",
            Festival: "రంజాన్, ఈద్ అల్ ఫితర్, ఏప్రిల్ ఫూల్, బ్యాంకు సెలవు",
          },
          {
            Date: "శనివారము, 05 ఏప్రిల్ 2025",
            Festival: "బాబూ జగజ్జీవన్ రామ్ జయంతి",
          },
          {
            Date: "ఆదివారము, 06 ఏప్రిల్ 2025",
            Festival: "శ్రీరామ నవమి",
          },
          {
            Date: "మంగళవారము, 08 ఏప్రిల్ 2025",
            Festival: "వైష్ణవ కామద ఏకాదశీ",
          },
          {
            Date: "గురువారము, 10 ఏప్రిల్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "శనివారము, 12 ఏప్రిల్ 2025",
            Festival: "చైత్రము పూర్ణిమ, హనుమాన్ జయంతి",
          },
          {
            Date: "సోమవారము, 14 ఏప్రిల్ 2025",
            Festival: "అంబేద్కర్ జయంతి",
          },
          {
            Date: "బుధవారము, 16 ఏప్రిల్ 2025",
            Festival: "వికట్ సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 18 ఏప్రిల్ 2025",
            Festival: "గుడ్ ఫ్రైడే",
          },
          {
            Date: "ఆదివారము, 20 ఏప్రిల్ 2025",
            Festival: "ఈస్టర్",
          },
          {
            Date: "గురువారము, 24 ఏప్రిల్ 2025",
            Festival: "వరుతిని ఏకాదశీ",
          },
          {
            Date: "శుక్రవారము, 25 ఏప్రిల్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
        ],
      },
      {
        day: [
          {
            Date: "గురువారము, 01 మే 2025",
            Festival: "అంతర్జాతీయ కార్మిక దినోత్సవం",
          },
          {
            Date: "బుధవారము, 07 మే 2025",
            Festival: "రవీంద్రనాథ్ ఠాగూర్ జయంతి",
          },
          {
            Date: "గురువారము, 08 మే 2025",
            Festival: "మోహినీ ఏకాదశీ",
          },
          {
            Date: "శుక్రవారము, 09 మే 2025",
            Festival: "పరశురామ ద్వాదశి, ప్రదోష్ వ్రతం",
          },
          {
            Date: "సోమవారము, 12 మే 2025",
            Festival: "బుద్ధ పూర్ణిమ",
          },
          {
            Date: "శుక్రవారము, 16 మే 2025",
            Festival: "ఏకదంట సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 23 మే 2025",
            Festival: "అపర ఏకాదశీ",
          },
          {
            Date: "శనివారము, 24 మే 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
        ],
      },
      {
        day: [
          {
            Date: "గురువారము, 05 జూన్ 2025",
            Festival: "ప్రపంచ పర్యావరణ దినోత్సవము",
          },
          {
            Date: "శుక్రవారము, 06 జూన్ 2025",
            Festival: "నిర్జల ఏకాదశీ",
          },
          {
            Date: "శనివారము, 07 జూన్ 2025",
            Festival: "బక్రీదు",
          },
          {
            Date: "ఆదివారము, 08 జూన్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "శనివారము, 14 జూన్ 2025",
            Festival: "కృష్ణపింగళా సంకష్టహర చతుర్థి",
          },
          {
            Date: "బుధవారము, 18 జూన్ 2025",
            Festival: "ఫాదర్స్ డే",
          },
          {
            Date: "శనివారము, 21 జూన్ 2025",
            Festival: "యోగిని ఏకాదశీ",
          },
          {
            Date: "సోమవారము, 23 జూన్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 06 జూలై 2025",
            Festival: "మొహర్రం",
          },
          {
            Date: "సోమవారము, 07 జూలై 2025",
            Festival: "వాసుదేవ ద్వాదశి",
          },
          {
            Date: "మంగళవారము, 08 జూలై 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "గురువారము, 10 జూలై 2025",
            Festival: "గురు పూర్ణిమ",
          },
          {
            Date: "శుక్రవారము, 11 జూలై 2025",
            Festival: "మంగళ గౌరీ వ్రతం",
          },
          {
            Date: "సోమవారము, 14 జూలై 2025",
            Festival: "గజానన సంకష్టహర చతుర్థి",
          },
          {
            Date: "సోమవారము, 21 జూలై 2025",
            Festival: "కామిక ఏకాదశీ",
          },
          {
            Date: "మంగళవారము, 22 జూలై 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 03 ఆగష్టు 2025",
            Festival: "స్నేహితుల దినోత్సవం",
          },
          {
            Date: "బుధవారము, 06 ఆగష్టు 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "శుక్రవారము, 08 ఆగస్టు 2025",
            Festival: "వరలక్ష్మి వ్రతం",
          },
          {
            Date: "శనివారము, 09 ఆగష్టు 2025",
            Festival: "రక్షా బంధన్",
          },
          {
            Date: "శుక్రవారము, 15 ఆగస్టు 2025",
            Festival: "భారత స్వాతంత్య్ర దినోత్సవం",
          },
          {
            Date: "శనివారము, 16 ఆగస్టు 2025",
            Festival: "శ్రీ కృష్ణ జన్మాష్టమి",
          },
          {
            Date: "మంగళవారము, 19 ఆగస్టు 2025",
            Festival: "అజ ఏకాదశీ",
          },
          {
            Date: "బుధవారము, 20 ఆగష్టు 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "బుధవారము, 27 ఆగస్టు 2025",
            Festival: "సిద్ధి వినాయక చవితి",
          },
        ],
      },
      {
        day: [
          {
            Date: "గురువారము, 04 సెప్టెంబర్ 2025",
            Festival: "మిలాద్ ఉన్ నబీ",
          },
          {
            Date: "శుక్రవారము, 05 సెప్టెంబర్ 2025",
            Festival: "ఉపాధ్యాయుల దినోత్సవం",
          },
          {
            Date: "బుధవారము, 17 సెప్టెంబర్ 2025",
            Festival: "ఇందిరా ఏకాదశీ",
          },
          {
            Date: "శుక్రవారము, 19 సెప్టెంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "సోమవారము, 22 సెప్టెంబర్ 2025",
            Festival: "శరన్నవరాత్రులు, దేవీనవరాత్రులు ప్రారంభము",
          },
          {
            Date: "మంగళవారము, 30 సెప్టెంబర్ 2025",
            Festival: "దుర్గాష్టమి",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 01 అక్టోబర్ 2025",
            Festival: "మహా నవమి, విజయ దశమి",
          },
          {
            Date: "గురువారము, 02 అక్టోబర్ 2025",
            Festival: "మహ్మత్మాగాంధీ జయంతి",
          },
          {
            Date: "శుక్రవారము, 03 అక్టోబర్ 2025",
            Festival: "పాశాంకుశ ఏకాదశీ",
          },
          {
            Date: "శనివారము, 04 అక్టోబర్ 2025",
            Festival: "పద్మనాభ ద్వాదశి, ప్రదోష్ వ్రతం",
          },
          {
            Date: "గురువారము, 09 అక్టోబర్ 2025",
            Festival: "అట్లతద్ది",
          },
          {
            Date: "శుక్రవారము, 10 అక్టోబర్ 2025",
            Festival: "వక్రతుండా సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 17 అక్టోబర్ 2025",
            Festival: "రమా ఏకాదశీ",
          },
          {
            Date: "శనివారము, 18 అక్టోబర్ 2025",
            Festival: "శని త్రయోదశి, ప్రదోష్ వ్రతం",
          },
          {
            Date: "సోమవారము, 20 అక్టోబర్ 2025",
            Festival: "దీపావళి",
          },
        ],
      },
      {
        day: [
          {
            Date: "శనివారము, 01 నవంబర్ 2025",
            Festival: "దేవుత్తన ఏకాదశీ",
          },
          {
            Date: "ఆదివారము, 02 నవంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం, యోగేశ్వర ద్వాదశి",
          },
          {
            Date: "సోమవారము, 03 నవంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "శనివారము, 08 నవంబర్ 2025",
            Festival: "గణాధిప సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 14 నవంబర్ 2025",
            Festival: "బాలల దినోత్సవం, నెహ్రూ జయంతి",
          },
          {
            Date: "శనివారము, 15 నవంబర్ 2025",
            Festival: "ఉత్పన్న ఏకాదశీ",
          },
          {
            Date: "సోమవారము, 17 నవంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "మంగళవారము, 25 నవంబర్ 2025",
            Festival: "నాగుల చవితి",
          },
        ],
      },
      {
        day: [
          {
            Date: "సోమవారము, 01 డిసెంబర్ 2025",
            Festival: "మొక్షద ఏకాదశీ, ప్రపంచ ఎయిడ్స్ దినోత్సవం",
          },
          {
            Date: "మంగళవారము, 02 డిసెంబర్ 2025",
            Festival: "మత్స్య ద్వాదశి, ప్రదోష్ వ్రతం",
          },
          {
            Date: "ఆదివారము, 07 డిసెంబర్ 2025",
            Festival: "ఆఖురత సంకష్టహర చతుర్థి",
          },
          {
            Date: "సోమవారము, 15 డిసెంబర్ 2025",
            Festival: "సఫల ఏకాదశీ",
          },
          {
            Date: "బుధవారము, 17 డిసెంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "బుధవారము, 24 డిసెంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "గురువారము, 25 డిసెంబర్ 2025",
            Festival: "క్రిస్మస్",
          },
          {
            Date: "శుక్రవారము, 26 డిసెంబర్ 2025",
            Festival: "బాక్సింగ్ డే",
          },
          {
            Date: "మంగళవారము, 30 డిసెంబర్ 2025",
            Festival: "పుష్యము పుత్రాద ఏకాదశీ",
          },
          {
            Date: "బుధవారము, 31 డిసెంబర్ 2025",
            Festival: "వైష్ణవ పుష్యము పుత్రాద ఏకాదశీ, కుర్మా ద్వాదశి",
          },
        ],
      },
    ],
  ],
  // GrahamUpdated: [
  //   [
  //     {
  //       Amav: "11 గురువారము",
  //       Chatur: "29 సోమవారము",
  //       Ekadas: "7 ఆదివారము, 21 ఆదివారము",
  //       Good: "6 శనివారము,7 ఆదివారము,20 శనివారము,21 ఆదివారము,25 గురువారము",
  //       Pournami: "25 గురువారము",
  //       Pradosh: "09 మంగళవారము, 23 మంగళవారము",
  //       Astami: "04 గురువారము, 18 గురువారము",
  //       Navami: "05 శుక్రవారము, 19 శుక్రవారము",
  //       data: {
  //         Amavasya: "11",
  //         Chaturthi: "29",
  //         Ekadasi: "7,21",
  //         Good: "6,7,20,21,25",
  //         Pradosham: "09,23",
  //         Purnima: "25",
  //         Astami: "04,18",
  //         Navami: "05,19",
  //       },
  //     },
  //     {
  //       Amav: "09 శుక్రవారము",
  //       Chatur: "28 బుధవారము",
  //       Ekadas: "06 మంగళవారము, 20 మంగళవారము",
  //       Good: "5 సోమవారము,6 మంగళవారము,14 బుధవారము,19 సోమవారము,20 మంగళవారము,24 శనివారము",
  //       Pournami: "24 శనివారము",
  //       Pradosh: "07 బుధవారము, 21 బుధవారము",
  //       Astami: "03 శనివారము, 17 శనివారము",
  //       Navami: "04 ఆదివారము, 18 ఆదివారము",
  //       data: {
  //         Amavasya: "09",
  //         Chaturthi: "28",
  //         Ekadasi: "06,20",
  //         Good: "5,6,14,19,20,24",
  //         Pradosham: "07,21",
  //         Purnima: "24",
  //         Astami: "03,17",
  //         Navami: "04,18",
  //       },
  //     },
  //     {
  //       Amav: "10 ఆదివారము",
  //       Chatur: "28 గురువారము",
  //       Ekadas: "06 బుధవారము, 20 బుధవారము",
  //       Good: "5 మంగళవారము,6 బుధవారము,19 మంగళవారము,20 బుధవారము,25 సోమవారము",
  //       Pournami: "25 సోమవారము",
  //       Pradosh: "08 శుక్రవారము, 22 శుక్రవారము",
  //       Astami: "04 సోమవారము, 17 ఆదివారము",
  //       Navami: "05 మంగళవారము, 18 సోమవారము",
  //       data: {
  //         Amavasya: "10",
  //         Chaturthi: "28",
  //         Ekadasi: "06,20",
  //         Good: "5,6,19,20,25",
  //         Pradosham: "08,22",
  //         Purnima: "25",
  //         Astami: "04,17",
  //         Navami: "05,18",
  //       },
  //     },
  //     {
  //       Amav: "08 సోమవారము",
  //       Chatur: "27 శనివారము",
  //       Ekadas: "05 శుక్రవారము, 19 శుక్రవారము",
  //       Good: "",
  //       Pournami: "23 మంగళవారము",
  //       Pradosh: "06 శనివారము, 21 ఆదివారము",
  //       Astami: "02 మంగళవారము, 16 మంగళవారము",
  //       Navami: "03 బుధవారము, 17 బుధవారము",
  //       data: {
  //         Amavasya: "08",
  //         Chaturthi: "27",
  //         Ekadasi: "05,19",
  //         Good: "5,6,7,12,14,19,21,23,27,28,29",
  //         Pradosham: "06,21",
  //         Purnima: "23",
  //         Astami: "02,16",
  //         Navami: "03,17",
  //       },
  //     },
  //     {
  //       Amav: "08 బుధవారము",
  //       Chatur: "26 ఆదివారము",
  //       Ekadas: "04 శనివారము, 19 ఆదివారము",
  //       Good: "",
  //       Pournami: "23 గురువారము",
  //       Pradosh: "05 ఆదివారము, 20 సోమవారము",
  //       Astami: "01 బుధవారము, 15 బుధవారము, 31 శుక్రవారము",
  //       Navami: "02 గురువారము, 16 గురువారము",
  //       data: {
  //         Amavasya: "08",
  //         Chaturthi: "26",
  //         Ekadasi: "04,19",
  //         Good: "4,5,6,11,13,19,20,23,26,27,29",
  //         Pradosham: "05,20",
  //         Purnima: "23",
  //         Astami: "01,15,31",
  //         Navami: "02,16",
  //       },
  //     },
  //     {
  //       Amav: "06 గురువారము",
  //       Chatur: "25 మంగళవారము",
  //       Ekadas: "02 ఆదివారము, 17 సోమవారము",
  //       Good: "",
  //       Pournami: "22 శనివారము",
  //       Pradosh: "04 మంగళవారము, 19 బుధవారము",
  //       Astami: "14 శుక్రవారము, 29 శనివారము",
  //       Navami: "01 శనివారము, 15 శనివారము, 30 ఆదివారము",
  //       data: {
  //         Amavasya: "06",
  //         Chaturthi: "25",
  //         Ekadasi: "02,17",
  //         Good: "4,5,10,12,17,19,22,25,27",
  //         Pradosham: "04,19",
  //         Purnima: "22",
  //         Astami: "14,29",
  //         Navami: "01,15,30",
  //       },
  //     },
  //     {
  //       Amav: "05 శుక్రవారము",
  //       Chatur: "24 బుధవారము",
  //       Ekadas: "02 మంగళవారము, 17 బుధవారము, 31 బుధవారము",
  //       Good: "",
  //       Pournami: "21 ఆదివారము",
  //       Pradosh: "03 బుధవారము, 19 శుక్రవారము",
  //       Astami: "14 ఆదివారము, 28 ఆదివారము",
  //       Navami: "15 సోమవారము, 29 సోమవారము",
  //       data: {
  //         Amavasya: "05",
  //         Chaturthi: "24",
  //         Ekadasi: "02,17,31",
  //         Good: "2,3,9,12,17,21,24,25,26,31",
  //         Pradosham: "03,19",
  //         Purnima: "21",
  //         Astami: "14,28",
  //         Navami: "15,29",
  //       },
  //     },
  //     {
  //       Amav: "04 ఆదివారము",
  //       Chatur: "22 గురువారము",
  //       Ekadas: "15 గురువారము, 29 గురువారము",
  //       Good: "",
  //       Pournami: "19 సోమవారము",
  //       Pradosh: "01 గురువారము, 17 శనివారము, 31 శనివారము",
  //       Astami: "12 సోమవారము, 27 మంగళవారము",
  //       Navami: "13 మంగళవారము, 27 మంగళవారము",
  //       data: {
  //         Amavasya: "04",
  //         Chaturthi: "22",
  //         Ekadasi: "15,29",
  //         Good: "1,2,8,10,15,17,19,22,23,24,29,31",
  //         Pradosham: "01,17,31",
  //         Purnima: "19",
  //         Astami: "12,27",
  //         Navami: "13,27",
  //       },
  //     },
  //     {
  //       Amav: "02 సోమవారము",
  //       Chatur: "21 శనివారము",
  //       Ekadas: "14 శనివారము, 28 శనివారము",
  //       Good: "",
  //       Pournami: "18 బుధవారము",
  //       Pradosh: "15 ఆదివారము, 29 ఆదివారము",
  //       Astami: "11 బుధవారము, 25 బుధవారము",
  //       Navami: "12 గురువారము, 26 గురువారము",
  //       data: {
  //         Amavasya: "02",
  //         Chaturthi: "21",
  //         Ekadasi: "14,28",
  //         Good: "7,9,14,15,18,21,23,28,29",
  //         Pradosham: "15,29",
  //         Purnima: "18",
  //         Astami: "11,25",
  //         Navami: "12,26",
  //       },
  //     },
  //     {
  //       Amav: "02 బుధవారము",
  //       Chatur: "20 ఆదివారము",
  //       Ekadas: "13 ఆదివారము, 28 సోమవారము",
  //       Good: "",
  //       Pournami: "17 గురువారము",
  //       Pradosh: "15 మంగళవారము, 29 మంగళవారము",
  //       Astami: "11 శుక్రవారము, 24 గురువారము",
  //       Navami: "11 శుక్రవారము, 25 శుక్రవారము",
  //       data: {
  //         Amavasya: "02",
  //         Chaturthi: "20",
  //         Ekadasi: "13,28",
  //         Good: "1,6,9,13,15,17,21,23,28,29,30",
  //         Pradosham: "15,29",
  //         Purnima: "17",
  //         Astami: "11,24",
  //         Navami: "12,25",
  //       },
  //     },
  //     {
  //       Amav: "01 శుక్రవారము",
  //       Chatur: "19 మంగళవారము",
  //       Ekadas: "12 మంగళవారము, 26 మంగళవారము",
  //       Good: "",
  //       Pournami: "15 శుక్రవారము",
  //       Pradosh: "13 బుధవారము, 28 గురువారము",
  //       Astami: "09 శనివారము, 23 శనివారము",
  //       Navami: "10 ఆదివారము, 24 ఆదివారము",
  //       data: {
  //         Amavasya: "01",
  //         Chaturthi: "19",
  //         Ekadasi: "12,26",
  //         Good: "5,7,12,13,15,19,21,26,28,29",
  //         Pradosham: "13,28",
  //         Purnima: "15",
  //         Astami: "09,23",
  //         Navami: "10,24",
  //       },
  //     },
  //     {
  //       Amav: "01 ఆదివారము, 30 సోమవారము",
  //       Chatur: "18 బుధవారము",
  //       Ekadas: "11 బుధవారము, 26 గురువారము",
  //       Good: "",
  //       Pournami: "15 ఆదివారము",
  //       Pradosh: "13 శుక్రవారము, 28 శనివారము",
  //       Astami: "08 ఆదివారము, 23 సోమవారము",
  //       Navami: "9 సోమవారము,24 మంగళవారము",
  //       data: {
  //         Amavasya: "01,30",
  //         Chaturthi: "18",
  //         Ekadasi: "11,26",
  //         Good: "5,7,11,13,15,19,21,26,28,29,30",
  //         Pradosham: "13,28",
  //         Purnima: "15",
  //         Astami: "08,23",
  //         Navami: "09,24",
  //       },
  //     },
  //   ],
  //   [
  //     {
  //       Amav: "21 శనివారము",
  //       Chatur: "10 మంగళవారము",
  //       Ekadas: "02 సోమవారము, 18 బుధవారము",
  //       Good: "",
  //       Pournami: "06 శుక్రవారము",
  //       Pradosh: "04 బుధవారము, 19 గురువారము",
  //       Astami: "15 ఆదివారము, 29 ఆదివారము",
  //       Navam: "16 సోమవారము, 30 సోమవారము",
  //       data: {
  //         Amavasya: "21",
  //         Chaturthi: "10",
  //         Ekadasi: "02,18",
  //         Good: "13,21,28",
  //         Pradosham: "04,19",
  //         Purnima: "06",
  //         Astami: "15,29",
  //         Navami: "16,30",
  //       },
  //     },
  //     {
  //       Amav: "20 సోమవారము",
  //       Chatur: "09 గురువారము",
  //       Ekadas: "01 బుధవారము, 16 గురువారము",
  //       Good: "",
  //       Pournami: "05 ఆదివారము",
  //       Pradosh: "02 గురువారము, 18 శనివారము",
  //       Astami: "13 సోమవారము, 27 సోమవారము",
  //       Navam: "14 మంగళవారము, 28 మంగళవారము",
  //       data: {
  //         Amavasya: "20",
  //         Chaturthi: "09",
  //         Ekadasi: "01,16",
  //         Good: "12,13,20,26,27",
  //         Pradosham: "02,18",
  //         Purnima: "05",
  //         Astami: "13,27",
  //         Navami: "14,28",
  //       },
  //     },
  //     {
  //       Amav: "21 మంగళవారము",
  //       Chatur: "11 శనివారము",
  //       Ekadas: "03 శుక్రవారము, 18 శనివారము",
  //       Good: "",
  //       Pournami: "07 మంగళవారము",
  //       Pradosh: "04 శనివారము, 19 ఆదివారము",
  //       Astami: "15 బుధవారము, 29 బుధవారము",
  //       Navam: "16 గురువారము, 30 గురువారము",
  //       data: {
  //         Amavasya: "21",
  //         Chaturthi: "11",
  //         Ekadasi: "03,18",
  //         Good: "1,14,21,28",
  //         Pradosham: "04,19",
  //         Purnima: "07",
  //         Astami: "15,29",
  //         Navami: "16,30",
  //       },
  //     },
  //     {
  //       Amav: "20 గురువారము",
  //       Chatur: "09 ఆదివారము",
  //       Ekadas: "01 శనివారము, 16 ఆదివారము",
  //       Good: "",
  //       Pournami: "06 గురువారము",
  //       Pradosh: "03 సోమవారము, 17 సోమవారము",
  //       Astami: "13 గురువారము, 28 శుక్రవారము",
  //       Navam: "14 శుక్రవారము, 29 శనివారము",
  //       data: {
  //         Amavasya: "20",
  //         Chaturthi: "09",
  //         Ekadasi: "01,16",
  //         Good: "2,4,10,12,13,16,19,26",
  //         Pradosham: "03,17",
  //         Purnima: "06",
  //         Astami: "13,28",
  //         Navami: "14,29",
  //       },
  //     },
  //     {
  //       Amav: " 19 శుక్రవారము",
  //       Chatur: "09 మంగళవారము",
  //       Ekadas: "01 సోమవారము, 15 సోమవారము, 31 బుధవారము",
  //       Good: "",
  //       Pournami: "05 శుక్రవారము",
  //       Pradosh: "03 బుధవారము, 17 బుధవారము",
  //       Astami: "13 శనివారము, 27 శనివారము",
  //       Navam: "14 ఆదివారము, 28 ఆదివారము",
  //       data: {
  //         Amavasya: "19",
  //         Chaturthi: "09",
  //         Ekadasi: "01,15,31",
  //         Good: "3,12,13,19,25,26",
  //         Pradosham: "03,17",
  //         Purnima: "05",
  //         Astami: "13,27",
  //         Navami: "14,28",
  //       },
  //     },
  //     {
  //       Amav: "18 ఆదివారము",
  //       Chatur: "07 బుధవారము",
  //       Ekadas: "14 బుధవారము, 29 గురువారము",
  //       Good: "",
  //       Pournami: "04 ఆదివారము",
  //       Pradosh: "01 గురువారము, 15 గురువారము",
  //       Astami: "11 ఆదివారము, 26 సోమవారము",
  //       Navam: "12 సోమవారము, 27 మంగళవారము",
  //       data: {
  //         Amavasya: "18",
  //         Chaturthi: "07",
  //         Ekadasi: "14,29",
  //         Good: "10,11,17,24",
  //         Pradosham: "01,15",
  //         Purnima: "04",
  //         Astami: "11,26",
  //         Navami: "12,27",
  //       },
  //     },
  //     {
  //       Amav: "17 సోమవారము",
  //       Chatur: "07 శుక్రవారము",
  //       Ekadas: "13 గురువారము, 29 శనివారము",
  //       Good: "",
  //       Pournami: "03 సోమవారము",
  //       Pradosh: "01 శనివారము, 14 శుక్రవారము, 30 ఆదివారము",
  //       Astami: "10 సోమవారము, 26 బుధవారము",
  //       Navam: "11 మంగళవారము, 27 గురువారము",
  //       data: {
  //         Amavasya: "17",
  //         Chaturthi: "07",
  //         Ekadasi: "13,29",
  //         Good: "10,16,24",
  //         Pradosham: "01,14,30",
  //         Purnima: "03",
  //         Astami: "10,26",
  //         Navami: "11,27",
  //       },
  //     },
  //     {
  //       Amav: " 15 మంగళవారము",
  //       Chatur: "05 శనివారము",
  //       Ekadas: "12 శనివారము, 27 ఆదివారము",
  //       Good: "",
  //       Pournami: "01 మంగళవారము, 31 గురువారము",
  //       Pradosh: "13 ఆదివారము, 28 సోమవారము",
  //       Astami: "08 మంగళవారము, 24 గురువారము",
  //       Navam: "09 బుధవారము, 25 శుక్రవారము",
  //       data: {
  //         Amavasya: "15",
  //         Chaturthi: "05",
  //         Ekadasi: "12,27",
  //         Good: "8,9,11,12,15,19,23,31",
  //         Pradosham: "13,28",
  //         Purnima: "01,31",
  //         Astami: "08,24",
  //         Navami: "09,25",
  //       },
  //     },
  //     {
  //       Amav: "14 గురువారము",
  //       Chatur: "02 శనివారము",
  //       Ekadas: "10 ఆదివారము, 25 సోమవారము",
  //       Good: "",
  //       Pournami: "29 శుక్రవారము",
  //       Pradosh: "12 మంగళవారము, 27 బుధవారము",
  //       Astami: "07 గురువారము, 23 శనివారము",
  //       Navam: "08 శుక్రవారము, 24 ఆదివారము",
  //       data: {
  //         Amavasya: "14",
  //         Chaturthi: "02",
  //         Ekadasi: "10,25",
  //         Good: "6,7,13,21,26",
  //         Pradosham: "12,27",
  //         Purnima: "29",
  //         Astami: "07,23",
  //         Navami: "08,24",
  //       },
  //     },
  //     {
  //       Amav: "14 శనివారము",
  //       Chatur: "02 సోమవారము",
  //       Ekadas: "10 మంగళవారము, 25 బుధవారము",
  //       Good: "9 సోమవారము,10 మంగళవారము,24 మంగళవారము,25 బుధవారము,28 శనివారము",
  //       Pournami: "28 శనివారము",
  //       Pradosh: "11 బుధవారము, 26 గురువారము",
  //       Astami: "07 శనివారము, 22 ఆదివారము",
  //       Navam: "08 ఆదివారము, 23 సోమవారము",
  //       data: {
  //         Amavasya: "14",
  //         Chaturthi: "02,18",
  //         Ekadasi: "10,25",
  //         Good: "9,10,24,25,28",
  //         Pradosham: "11,26",
  //         Purnima: "28",
  //         Astami: "07,22",
  //         Navami: "08,23",
  //       },
  //     },
  //     {
  //       Amav: "12 ఆదివారము, 13 సోమవారము",
  //       Chatur: "01 బుధవారము, 30 గురువారము",
  //       Ekadas: "09 గురువారము, 23 గురువారము",
  //       Good: "7 మంగళవారము,8 బుధవారము,9 గురువారము,22 బుధవారము,23 గురువారము,27 సోమవారము",
  //       Pournami: "27 సోమవారము",
  //       Pradosh: "10 శుక్రవారము, 24 శుక్రవారము",
  //       Astami: "05 ఆదివారము, 20 సోమవారము",
  //       Navam: "06 సోమవారము, 21 మంగళవారము",
  //       data: {
  //         Amavasya: "12,13",
  //         Chaturthi: "01,30",
  //         Ekadasi: "09,23",
  //         Good: "7,8,9,22,23,27",
  //         Pradosham: "10,24",
  //         Purnima: "27",
  //         Astami: "05,20",
  //         Navami: "06,21",
  //       },
  //     },
  //     {
  //       Amav: "12 మంగళవారము",
  //       Chatur: "30 శనివారము",
  //       Ekadas: "08 శుక్రవారము, 23 శనివారము",
  //       Good: "7 గురువారము,8 శుక్రవారము,22 శుక్రవారము,23 శనివారము,26 మంగళవారము",
  //       Pournami: "26 మంగళవారము",
  //       Pradosh: "10 ఆదివారము, 24 ఆదివారము",
  //       Astami: "05 మంగళవారము, 20 బుధవారము",
  //       Navam: "06 బుధవారము, 21 గురువారము",
  //       data: {
  //         Amavasya: "12",
  //         Chaturthi: "30",
  //         Ekadasi: "08,23",
  //         Good: "7,8,22,23,26",
  //         Pradosham: "10,24",
  //         Purnima: "26",
  //         Astami: "05,20",
  //         Navami: "06,21",
  //       },
  //     },
  //   ],
  // ],
  // "IsMrecEnabled": true,
  // "PromoApps": {
  //   "NormalTypeMoreApps": {
  //     "CountrySpecficArr": [
  //       {
  //         "CountryCode": "IN",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS1.png?alt=media&token=6250aad8-cb1e-4810-8336-39abbc3972f8",
  //             "Name": "normal1",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //             "Name": "normal2",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //             "Name": "normal3",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": true
  //       },
  //       {
  //         "CountryCode": "US",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS1.png?alt=media&token=6250aad8-cb1e-4810-8336-39abbc3972f8",
  //             "Name": "DEFLT1",
  //             "enable": true
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //             "Name": "DEFLT2",
  //             "enable": true
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //             "Name": "DEFLT3",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": false
  //       },
  //       {
  //         "CountryCode": "GB",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS1.png?alt=media&token=6250aad8-cb1e-4810-8336-39abbc3972f8",
  //             "Name": "DEFLT1",
  //             "enable": true
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //             "Name": "DEFLT2",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //             "Name": "DEFLT3",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": false
  //       },
  //     ],
  //     "DefaultCountryArr": [
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //         "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS1.png?alt=media&token=6250aad8-cb1e-4810-8336-39abbc3972f8",
  //         "Name": "defalutNormal1",
  //         "enable": true
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //         "Name": "defalutNormal2",
  //         "enable": true
  //       },
  //       {
  //         "AndroidLink": "",
  //         "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //         "Name": "DEFLT3",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //         "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //         "Name": "DEFLT4",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //         "Name": "DEFLT5",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //         "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //         "Name": "DEFLT6",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=aloe.vera.uses.benefits.aloeverabenefits",
  //         "IOSLink": "https://apps.apple.com/us/app/aloe-vera-benefits/id1446413369",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FAlovera.png?alt=media&token=46fdd80d-5411-41b8-bbca-3f4eee73e79e",
  //         "Name": "DEFLT7",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //         "Name": "DEFLT8",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker",
  //         "IOSLink": "https://apps.apple.com/tt/app/khata-book-my-spending-tracker/id1517793704",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FExpense_Manager.png?alt=media&token=8dfaaa82-9cb8-48f1-aef8-e92858a54b35",
  //         "Name": "DEFLT9",
  //         "enable": false
  //       },
  //     ],
  //     "enable": false
  //   },
  //   "BannerTypeMoreApps": {
  //     "CountrySpecficArr": [
  //       {
  //         "CountryCode": "IN",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FItaramulu_btn.png?alt=media&token=1b8b4f63-c0e6-4cab-9519-2e09ea085a93",
  //             "Name": "BannerIN1",
  //             "enable": true
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FFinancialCalcBanner.png?alt=media&token=0b77df50-6048-47fd-ba80-2605cd0763c4https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FFinancialCalcBanner.png?alt=media&token=7d84a36d-cd1b-4350-9101-543b7e08f176",
  //             "Name": "BannerIN2",
  //             "enable": true
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FDasara.png?alt=media&token=29a45ef2-c219-4b52-88a7-16474941eab4",
  //             "Name": "DEFLT3",
  //             "enable": true
  //           },{
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FBudgetExpenseBanner.png?alt=media&token=9772b7c5-6366-4166-8469-7e7184fcb514",
  //             "Name": "BannerIN2",
  //             "enable": true
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": true
  //       },
  //       {
  //         "CountryCode": "US",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS1.png?alt=media&token=6250aad8-cb1e-4810-8336-39abbc3972f8",
  //             "Name": "DEFLT1",
  //             "enable": true
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //             "Name": "DEFLT2",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //             "Name": "DEFLT3",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": false
  //       },
  //       {
  //         "CountryCode": "GB",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS1.png?alt=media&token=6250aad8-cb1e-4810-8336-39abbc3972f8",
  //             "Name": "DEFLT1",
  //             "enable": true
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //             "Name": "DEFLT2",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //             "Name": "DEFLT3",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           },
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": false
  //       }
  //     ],
  //     "DefaultCountryArr": [
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //         "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //         "Icon": require("../../utils/imgs/Festivals/VinayakachavithiIcon.png"),
  //         "Name": "BannerDEFLT1",
  //         "enable": true
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //         "Name": "DEFLT2",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "",
  //         "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //         "Name": "DEFLT3",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //         "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //         "Name": "DEFLT4",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //         "Name": "DEFLT5",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //         "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //         "Name": "DEFLT6",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=aloe.vera.uses.benefits.aloeverabenefits",
  //         "IOSLink": "https://apps.apple.com/us/app/aloe-vera-benefits/id1446413369",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FAlovera.png?alt=media&token=46fdd80d-5411-41b8-bbca-3f4eee73e79e",
  //         "Name": "DEFLT7",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //         "Name": "DEFLT8",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker",
  //         "IOSLink": "https://apps.apple.com/tt/app/khata-book-my-spending-tracker/id1517793704",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FExpense_Manager.png?alt=media&token=8dfaaa82-9cb8-48f1-aef8-e92858a54b35",
  //         "Name": "DEFLT9",
  //         "enable": false
  //       },

  //     ],
  //     "enable": false
  //   },
  //   "MrecTypeMoreApps": {
  //     "CountrySpecficArr": [
  //       {
  //         "CountryCode": "IN",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FFinancialCalcMREC.png?alt=media&token=7dfbad37-7716-456b-ba27-c879d6596c01",
  //             "Name": "MRECMOREAPPSDEFLT1",
  //             "enable": true
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //             "Name": "DEFLT2",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //             "Name": "DEFLT3",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": false
  //       },
  //       {
  //         "CountryCode": "US",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS1.png?alt=media&token=6250aad8-cb1e-4810-8336-39abbc3972f8",
  //             "Name": "DEFLT1",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //             "Name": "DEFLT2",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //             "Name": "DEFLT3",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": false
  //       },
  //       {
  //         "CountryCode": "GB",
  //         "IconArray": [
  //           {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //             "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS1.png?alt=media&token=6250aad8-cb1e-4810-8336-39abbc3972f8",
  //             "Name": "DEFLT1",
  //             "enable": true
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //             "Name": "DEFLT2",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //             "Name": "DEFLT3",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //             "Name": "DEFLT4",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //             "Name": "DEFLT5",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //             "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //             "Name": "DEFLT6",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "",
  //             "IOSLink": "",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS7.png?alt=media&token=5e21e544-7bfc-4072-813b-e460c979d8ab",
  //             "Name": "DEFLT7",
  //             "enable": false
  //           }, {
  //             "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //             "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //             "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //             "Name": "DEFLT8",
  //             "enable": false
  //           }
  //         ],
  //         "enable": true
  //       }
  //     ],
  //     "DefaultCountryArr": [
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=kidspuzzles.toddlergame.kidsjigsaw",
  //         "IOSLink": "https://apps.apple.com/app/kids-jigsaw-toddler-puzzles/id1454921811",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FFinancialCalcMREC.png?alt=media&token=7dfbad37-7716-456b-ba27-c879d6596c01",
  //         "Name": "DEFLT1",
  //         "enable": true
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=animalsjigsaw.junglegame.kidspuzzles",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-animal-jigsaw-puzzles/id1461444399",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS2.png?alt=media&token=fcaa438c-7fbf-4679-92d3-6b8a44e595ec",
  //         "Name": "DEFLT2",
  //         "enable": true
  //       },
  //       {
  //         "AndroidLink": "",
  //         "IOSLink": "https://apps.apple.com/app/kids-puzzle-zoo-jigsaw-family/id1458864442",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS3.png?alt=media&token=a49ac17d-3290-4172-9967-17fa8a190a7d",
  //         "Name": "DEFLT3",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //         "IOSLink": "https://apps.apple.com/app/kids-educational-game-to-learn/id1465718033",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS4.png?alt=media&token=98994131-2492-4717-80d1-722496375e28",
  //         "Name": "DEFLT4",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=preschoolpuzzle.popularpack.splitsforkids",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-games-my-kids-world/id1472709075",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS5.png?alt=media&token=d8703f61-0a22-42e8-9fae-3e3fe129f856",
  //         "Name": "DEFLT5",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=puzzlegame.forestanimal.pandafriends",
  //         "IOSLink": "https://apps.apple.com/app/panda-friends-animal-puzzles/id1468251502",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS6.png?alt=media&token=b2762711-e8cb-4014-965b-df9509609f5f",
  //         "Name": "DEFLT6",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=aloe.vera.uses.benefits.aloeverabenefits",
  //         "IOSLink": "https://apps.apple.com/us/app/aloe-vera-benefits/id1446413369",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FAlovera.png?alt=media&token=46fdd80d-5411-41b8-bbca-3f4eee73e79e",
  //         "Name": "DEFLT7",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=com.buddypuzzles",
  //         "IOSLink": "https://apps.apple.com/us/app/kids-learning-smart-toddler-4/id1475734414",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FJS8.png?alt=media&token=171b9e9d-090f-40a8-a09b-db854e1ae7b0",
  //         "Name": "DEFLT8",
  //         "enable": false
  //       },
  //       {
  //         "AndroidLink": "https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker",
  //         "IOSLink": "https://apps.apple.com/tt/app/khata-book-my-spending-tracker/id1517793704",
  //         "Icon": "https://firebasestorage.googleapis.com/v0/b/telugu-calendar-e8d37.appspot.com/o/MoreAppsIcons%2FExpense_Manager.png?alt=media&token=8dfaaa82-9cb8-48f1-aef8-e92858a54b35",
  //         "Name": "DEFLT9",
  //         "enable": false
  //       },

  //     ],
  //     "enable": false
  //   },
  //   "MoreAppsEnable": false,
  //   "Version": 1
  // },
  "Version": 8
}