import React, { useState } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import MahaAnu from '../../assets/jsons/mahabharatham/13MahaAnu';

const Anusasanikaparvam = () => {
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);
  const [selectedSubtitleIndex, setSelectedSubtitleIndex] = useState(null);

  const handleTitleClick = (index) => {
    setSelectedTitleIndex(index);
    setSelectedSubtitleIndex(null);
  };

  const handleSubtitleClick = (index) => {
    // setSelectedSubtitleIndex(index);
    setSelectedSubtitleIndex(prevIndex => (prevIndex === index ? null : index));

  };
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#FFE6D0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="maha-adhi-container">
              <div className="titles">
                {MahaAnu.map((item, index) => (
                  <div
                    key={index}
                    id={`heading${index}`}
                    onClick={() => handleTitleClick(index)}
                    className="card-header card mb-3"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: selectedTitleIndex === index ? '#B35C0C' : '#754213',
                    }}
                  >
                    <h5>
                      <div className="btn btn"
                        aria-expanded={selectedTitleIndex === index}
                        aria-controls={`collapse${index}`}
                        style={{ color: '#FFFFFF' }}>
                        {item.title}
                      </div>
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            style={{
              overflowY: rightSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            {selectedTitleIndex !== null && (
              <div className="subtitles">
                {MahaAnu[selectedTitleIndex].subtitle.map((subtitle, subtitleIndex) => (
                  <div key={subtitleIndex} className="card-body rightPanel">
                    <div onClick={() => handleSubtitleClick(subtitleIndex)}>
                      <h5>{subtitle.partname}</h5>
                    </div>
                    {selectedSubtitleIndex === subtitleIndex && (
                      <div className="content">
                        {MahaAnu[selectedTitleIndex].subtitle[selectedSubtitleIndex].description.map((desc, descIndex) => (
                          <React.Fragment key={descIndex}>
                            <ul>
                              {desc.des}
                            </ul>
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Anusasanikaparvam;
