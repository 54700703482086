import React, { useState } from 'react'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import sideGridRightImage from '../../assets/images/sideGridRight.png';
import ItaramuluData from '../../assets/jsons/ItaramuluData';


const Hindudarmashastram = () => {
  const [activeKey, setActiveKey] = useState(0);

  const handleToggle = (index) => {
    setActiveKey(activeKey === index ? null : index);
  };
  const { data, CatID } = ItaramuluData.upanishadlu;

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div id="accordion">
              {data.map((section, index) => (
                <div id={`heading${index}`} onClick={() => handleToggle(index)} className="card-header card mb-3"
                  style={{ color: "#FFFFFF", backgroundColor: activeKey === index ? '#B35C0C' : '#754213' }}
                >
                  <h5 className="mb-0">
                    <button
                      className="btn"
                      aria-expanded={activeKey === index}
                      aria-controls={`collapse${index}`}
                      style={{ color: '#FFFFFF' }}
                    >
                      {section.title}
                    </button>
                  </h5>
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            style={{
              overflowY: rightSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            {activeKey !== null && (
              <div
                id={`collapse${activeKey}`}
                className="collapse show"
                aria-labelledby={`heading${activeKey}`}
                data-parent="#accordion"
              >
                <div className="card-body rightPanel">
                  <h5>{data[activeKey].title}</h5>
                  {CatID.map((content, idx) => (
                    <ul key={idx}>{content.title}  </ul>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Hindudarmashastram