import React, { useState } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import sideGridRightImage from '../../assets/images/sideGridRight.png';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Vivahanakshatralu = () => {
  const [gender, setGender] = useState('male');
  const [selectedNakshatra, setSelectedNakshatra] = useState('');
  const [matchingNakshatras, setMatchingNakshatras] = useState([]);
  const [showMatchingPanel, setShowMatchingPanel] = useState(false); // New state variable

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleNakshatraChange = (event) => {
    setSelectedNakshatra(event.target.value);
  };

  const handleFindMatches = () => {
    const nakshatraData = ItaramuluData.matchingnakshatra.find(
      (item) => item.Nakshatraname === selectedNakshatra
    );

    if (nakshatraData) {
      setMatchingNakshatras(nakshatraData.MatchingNakshtra);
      setShowMatchingPanel(true); // Show the right panel when matches are found
    } else {
      setMatchingNakshatras([]);
      setShowMatchingPanel(false); // Hide the right panel if no matches are found
    }
  };
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  
  return (
    <>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
          style={{
            overflowY: leftSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: leftSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
            <div className="row">
              <div style={{ backgroundColor: "#754213", color: '#FFFFFF' }}>
                <br/>
                <h5 style={{ textAlign: 'center' }}>వివాహ మ్యాచింగ్ నక్షత్రాలు</h5>
                <div className="d-flex justify-content-evenly p-3">
                  <div>
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      checked={gender === 'male'}
                      onChange={handleGenderChange}
                    />
                    <label htmlFor="male">వరుడు</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      checked={gender === 'female'}
                      onChange={handleGenderChange}
                    />
                    <label htmlFor="female">వధువు</label>
                  </div>
                </div>
                <div>
                  <h6 htmlFor="nakshatra" style={{ textAlign: 'center' }}>నక్షత్రం ఎంచుకోండి:</h6>
                  <select id="nakshatra" value={selectedNakshatra} onChange={handleNakshatraChange} style={{ width: '633px', height: '40px'}}>
                    <option value="">నక్షత్రం ఎంచుకోండి</option>
                    <option value="అశ్విని">అశ్విని</option>
                    <option value="భరణి">భరణి</option>
                    <option value="కృత్తిక">కృత్తిక</option>
                    <option value="రోహిణి">రోహిణి</option>
                    <option value="మృగశిర">మృగశిర</option>
                    <option value="ఆరుద్ర">ఆరుద్ర</option>
                    <option value="పునర్వసు">పునర్వసు</option>
                    <option value="పుష్యమి">పుష్యమి</option>
                    <option value="ఆశ్లేష">ఆశ్లేష</option>
                    <option value="మఖ">మఖ</option>
                    <option value="పుబ్బ">పుబ్బ</option>
                    <option value="ఉత్తర">ఉత్తర</option>
                    <option value="హస్త">హస్త</option>
                    <option value="చిత్త">చిత్త</option>
                    <option value="స్వాతి">స్వాతి</option>
                    <option value="విశాఖ">విశాఖ</option>
                    <option value="అనూరాధ">అనూరాధ</option>
                    <option value="జ్యేష్ఠ">జ్యేష్ఠ</option>
                    <option value="మూల">మూల</option>
                    <option value="పూర్వాషాఢ">పూర్వాషాఢ</option>
                    <option value="ఉత్తరాషాఢ">ఉత్తరాషాఢ</option>
                    <option value="శ్రవణం">శ్రవణం</option>
                    <option value="ధనిష్ఠ">ధనిష్ఠ</option>
                    <option value="శతభిషం">శతభిషం</option>
                    <option value="పూర్వాభాద్ర">పూర్వాభాద్ర</option>
                    <option value="ఉత్తరాభాద్ర">ఉత్తరాభాద్ర</option>
                    <option value="రేవతి">రేవతి</option>
                  </select>
                </div>
                <br/>
                <button onClick={handleFindMatches} className='button2'>మ్యాచింగ్</button>
                <div>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(true);
            setRightSticky(false);
          }}
          style={{
            overflowY: rightSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: rightSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
            {showMatchingPanel && ( // Conditionally render the right panel

              <div className="card-body rightPanel">
                <h5>మ్యాచింగ్ నక్షత్రాలు</h5>
                <ul>
                  {matchingNakshatras.map((nakshatra, index) => (
                    <ul key={index}>{nakshatra.title}</ul>
                  ))}
                  <br></br>
                </ul>
              </div>
            )}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Vivahanakshatralu;