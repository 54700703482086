const IsconFest = 
    [
      {
        Date: "ఆదివారం, 07 జనవరి 2024",
        Festival: "సఫల ఏకాదశి",
      },
      {
        Date: "మంగళవారం, 9 జనవరి 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "సోమవారం, 15 జనవరి 2024",
        Festival: "మకర సంక్రాంతి",
      },
      {
        Date: "ఆదివారం, 21 జనవరి 2024",
        Festival: "పుష్యము పుత్రదా ఏకాదశి",
      },
      {
        Date: "సోమవారం, 22 జనవరి 2024",
        Festival: "కూర్మ ద్వాదశి",
      },
      {
        Date: "సోమవారం, 29 జనవరి 2024",
        Festival: "లంబోదర సంకష్టహర చతుర్థి",
      },
      {
        Date: "మంగళవారం, 6 ఫిబ్రవరి 2024",
        Festival: "షట్తిల ఏకాదశి",
      },
      {
        Date: "మంగళవారం, 13 ఫిబ్రవరి 2024",
        Festival: "కుంభ సంక్రమణం",
      },
      {
        Date: "శుక్రవారం, 16 ఫిబ్రవరి 2024",
        Festival: "రధసప్తమి",
      },
      {
        Date: "మంగళవారం, 20 ఫిబ్రవరి 2024",
        Festival: "జయ ఏకాదశి, భీష్మ ద్వాదశి",
      },
      {
        Date: "బుధవారం, 21 ఫిబ్రవరి 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "శనివారం, 24 ఫిబ్రవరి 2024",
        Festival: "మాఘ పౌర్ణమి",
      },
      {
        Date: "బుధవారం, 28 ఫిబ్రవరి 2024",
        Festival: "ద్విజప్రియ సంకష్టహర చతుర్థి",
      },
      {
        Date: "బుధవారం, 06 మార్చి 2024",
        Festival: "విజయ ఏకాదశి",
      },
      {
        Date: "శుక్రవారం, 08 మార్చి 2024",
        Festival: "మహా శివరాత్రి, ప్రదోష వ్రతం",
      },
      {
        Date: "బుధవారం, 20 మార్చి 2024",
        Festival: "అమలకీ ఏకాదశి",
      },
      {
        Date: "గురువారం, 21 మార్చి 2024",
        Festival: "నరసింహ ద్వాదశి",
      },
      {
        Date: "సోమవారం, 25 మార్చి 2024",
        Festival: "హోలీ",
      },
      {
        Date: "గురువారం, 28 మార్చి 2024",
        Festival: "బాలచంద్ర సంకష్టహర చతుర్థి",
      },
      {
        Date: "శుక్రవారం, 05 ఏప్రిల్ 2024",
        Festival: "పాపమోచని ఏకాదశి",
      },
      {
        Date: "శనివారం, 06 ఏప్రిల్ 2024",
        Festival: "శని త్రయోదశి, ప్రదోష వ్రతం",
      },
      {
        Date: "మంగళవారం, 09 ఏప్రిల్ 2024",
        Festival: "ఉగాది",
      },
      {
        Date: "గురువారం, 11 ఏప్రిల్ 2024",
        Festival: "గౌరీ పూజ, డోలా గౌరీ వ్రతం",
      },
      {
        Date: "బుధవారం, 17 ఏప్రిల్ 2024",
        Festival: "శ్రీరామ నవమి",
      },
      {
        Date: "శుక్రవారం, 19 ఏప్రిల్ 2024",
        Festival: "కామద ఏకాదశి",
      },
      {
        Date: "మంగళవారం, 23 ఏప్రిల్ 2024",
        Festival: "చైత్ర పూర్ణిమ",
      },
      {
        Date: "శనివారం, 27 ఏప్రిల్ 2024",
        Festival: "వికట సంకష్టహర చతుర్థి",
      },
      {
        Date: "శనివారం, 4 మే 2024",
        Festival: "వరూధిని ఏకాదశి",
      },
      {
        Date: "ఆదివారం, 05 మే 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "శుక్రవారం, 10 మే 2024",
        Festival: "అక్షయ తృతీయ",
      },
      {
        Date: "ఆదివారం, 19 మే 2024",
        Festival: "మోహినీ ఏకాదశి, పరశురామ ద్వాదశి",
      },
      {
        Date: "గురువారం, 23 మే 2024",
        Festival: "బుద్ధ పూర్ణిమ",
      },
      {
        Date: "ఆదివారం, 26 మే 2024",
        Festival: "ఏకదంత సంకష్టహర చతుర్థి",
      },
      {
        Date: "శనివారం, 1 జూన్ 2024",
        Festival: "హనుమాన్ జయంతి",
      },
      {
        Date: "ఆదివారం, 2 జూన్ 2024",
        Festival: "అపర ఏకాదశి",
      },
      {
        Date: "మంగళవారం, 4 జూన్ 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "మంగళవారం, 18 జూన్ 2024",
        Festival: "నిర్జల ఏకాదశి, రామలక్ష్మణ ద్వాదశి",
      },
      {
        Date: "మంగళవారం, 25 జూన్ 2024",
        Festival: "కృష్ణపింగళ సంకష్టహర చతుర్థి",
      },
      {
        Date: "మంగళవారం, 2 జూలై 2024",
        Festival: "యోగినీ ఏకాదశి",
      },
      {
        Date: "బుధవారం, 3 జూలై 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "బుధవారం, 17 జూలై 2024",
        Festival: "తొలి ఏకాదశి, దేవశయని ఏకాదశి",
      },
      {
        Date: "గురువారం, 18 జూలై 2024",
        Festival: "వాసుదేవ్ ద్వాదశి",
      },
      {
        Date: "ఆదివారం, 21 జూలై 2024",
        Festival: "గురు పూర్ణిమ",
      },
      {
        Date: "బుధవారం, 24 జూలై 2024",
        Festival: "గజానన సంకష్టహర చతుర్థి",
      },
      {
        Date: "బుధవారం, 31 జూలై 2024",
        Festival: "కామికా ఏకాదశి",
      },
      {
        Date: "గురువారం, 1 ఆగస్టు 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "శుక్రవారం, 16 ఆగస్టు 2024",
        Festival: "శ్రావణ పుత్రాద ఏకాదశి, దామోదర ద్వాదశి",
      },
      {
        Date: "శనివారం, 17 ఆగస్టు 2024",
        Festival: "శని త్రయోదశి, ప్రదోష వ్రతం",
      },
      {
        Date: "గురువారం, 22 ఆగస్టు 2024",
        Festival: "హేరంబ సంకష్టహర చతుర్థి",
      },
      {
        Date: "సోమవారం, 26 ఆగస్టు 2024",
        Festival: "శ్రీ కృష్ణ జన్మాష్టమి",
      },
      {
        Date: "గురువారం, 29 ఆగస్టు 2024",
        Festival: "అజ ఏకాదశి",
      },
      {
        Date: "శనివారం, 31 ఆగస్టు 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "శనివారం, 7 సెప్టెంబర్ 2024",
        Festival: "సిద్ధి వినాయక చవితి",
      },
      {
        Date: "శనివారం, 14 సెప్టెంబర్ 2024",
        Festival: "పరివర్తిని ఏకాదశి",
      },
      {
        Date: "శనివారం, 21 సెప్టెంబర్ 2024",
        Festival: "విఘ్నరాజ్ సంకష్టహర చతుర్థి",
      },
      {
        Date: "శనివారం, 28 సెప్టెంబర్ 2024",
        Festival: "ఇందిరా ఏకాదశి",
      },
      {
        Date: "ఆదివారం, 29 సెప్టెంబర్ 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "శుక్రవారం, 11 అక్టోబర్ 2024",
        Festival: "దుర్గా అష్టమి",
      },
      {
        Date: "శనివారం, 12 అక్టోబర్ 2024",
        Festival: "విజయదశమి, మహా నవమి",
      },
      {
        Date: "సోమవారం, 14 అక్టోబర్ 2024",
        Festival: "పాశాంకుశ ఏకాదశి, పద్మనాభ ద్వాదశి",
      },
      {
        Date: "మంగళవారం, 15 అక్టోబర్ 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "ఆదివారం, 20 అక్టోబర్ 2024",
        Festival: "వక్రతుండ సంకష్టహర చతుర్థి",
      },
      {
        Date: "సోమవారం, 28 అక్టోబర్ 2024",
        Festival: "రమా ఏకాదశి",
      },
      {
        Date: "గురువారం, 31 అక్టోబర్ 2024",
        Festival: "నరక చతుర్దశి",
      },
      {
        Date: "శుక్రవారం, 1 నవంబర్ 2024",
        Festival: "దీపావళి, లక్ష్మి పూజ",
      },
      {
        Date: "మంగళవారం, 12 నవంబర్ 2024",
        Festival: "దేవుత్తన ఏకాదశి, యోగేశ్వర్ ద్వాదశి",
      },
      {
        Date: "బుధవారం, 13 నవంబర్ 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "సోమవారం, 18 నవంబర్ 2024",
        Festival: "గణాధిప సంకష్టహర చతుర్థి",
      },
      {
        Date: "మంగళవారం, 26 నవంబర్ 2024",
        Festival: "ఉత్పన్న ఏకాదశి",
      },
      {
        Date: "బుధవారం, 11 డిసెంబర్ 2024",
        Festival: "గీతా జయంతి, మోక్షద ఏకాదశి",
      },
      {
        Date: "గురువారం, 12 డిసెంబర్ 2024",
        Festival: "మత్స్య ద్వాదశి",
      },
      {
        Date: "శుక్రవారం, 13 డిసెంబర్ 2024",
        Festival: "ప్రదోష వ్రతం",
      },
      {
        Date: "బుధవారం, 18 డిసెంబర్ 2024",
        Festival: "అకురత సంకష్టహర చతుర్థి",
      },
      {
        Date: "గురువారం, 26 డిసెంబర్ 2024",
        Festival: "సఫల ఏకాదశి",
      }
    ]
  
export default IsconFest